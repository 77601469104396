import React, { Component } from "react";
import { Field, FieldProps } from "formik";
import {
  TextField,
  TextFieldProps,
  Box,
  Typography,
  styled,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { colors } from "../../../blocks/utilities/src/Colors";

type CustomPasswordProps = TextFieldProps & {
  label?: string;
  name: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  formik?: boolean;
};

interface CustomPasswordState {
  showPassword: boolean;
}

class CustomPassword extends Component<
  CustomPasswordProps,
  CustomPasswordState
> {
  constructor(props: CustomPasswordProps) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }

  handleTogglePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  render() {
    const {
      label,
      name,
      value,
      variant = "outlined",
      onChange,
      formik,
      ...props
    } = this.props;
    const { showPassword } = this.state;

    if (formik) {
      return (
        <Field name={name}>
          {({ field, meta, form }: FieldProps) => (
            <InputStyleWrapper>
              {label && (
                <Typography variant="body1" className="label">
                  {label}
                </Typography>
              )}
              <TextField
                {...field}
                {...props}
                type={showPassword ? "text" : "password"}
                onChange={(event) => onChange || form.setFieldValue(field.name, event.target.value?.trimStart())}
                variant={variant}
                error={Boolean(meta.touched && meta.error)}
                helperText={meta.touched && meta.error ? meta.error : ""}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={this.handleTogglePassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </InputStyleWrapper>
          )}
        </Field>
      );
    }

    return (
      <InputStyleWrapper>
        {label && (
          <Typography variant="body1" className="label">
            {label}
          </Typography>
        )}
        <TextField
          {...props}
          name={name}
          value={value}
          variant={variant}
          onChange={onChange}
        />
      </InputStyleWrapper>
    );
  }
}

const InputStyleWrapper = styled(Box)({
  "& .label": {
    fontWeight: 700,
    lineHeight: "21px",
    fontSize: "14px",
    color: colors().darkText,
    marginBottom: "4px",
  },
  "& .MuiFormControl-root": {
    minHeight: "64px",
    width: "100%",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "8px",
    backgroundColor: "#FFF",
  },
  "& .MuiOutlinedInput-root:not(.Mui-focused):not(.Mui-error) .MuiOutlinedInput-notchedOutline":
    {
      borderColor: colors().tableBorder,
    },
  "& .MuiInputBase-input": {
    color: colors().darkText,
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    padding: "10px 12px",
    height: "unset",
  },
  "& .MuiFormHelperText-root": {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    marginLeft: "0",
    marginTop: "0",
  },
  "& .MuiSvgIcon-root": {
    color: "#94A3B8",
  },
});

export default CustomPassword;
