// Customizable Area Start
import React from "react";
import AiQueryHubController, {
  Props,
  ShareMethod,
  configJSON,
} from "./AiQueryHubController.web";
import {
  AppBar,
  Box,
  Button,
  Chip,
  createTheme,
  CssBaseline,
  DialogActions,
  DialogContent,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Radio,
  RadioGroup,
  styled,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import { Form, Formik } from "formik";
import {
  doubleLeftIcon,
  logoWhite,
  download,
  regenerate,
  share,
  checked,
  unchecked,
  searchIcon,
} from "./assets";
import AiQueryChat from "./AiQueryChat.web";
import Results from "./Results.web";
import Json from "./Json.web";
import AiQueryHubFilesTab from "./AiQueryHubFilesTab.web";
import AiQueryHubHistoryTab from "./AiQueryHubHistoryTab.web";
import CustomDialogWithAction from "../../../components/src/common/CustomDialogWithAction.web";
import CustomInput from "../../../components/src/common/CustomInput.web";
import CustomSelect from "../../../components/src/common/CustomSelect.web";
import CommonButton from "../../../components/src/common/CommonButton.web";
import { colors } from "../../utilities/src/Colors";

const theme = createTheme({
  palette: {
    primary: {
      main: "#684EF3",
    },
    secondary: {
      main: "#684EF3",
    },
    text: {
      primary: "#14101E",
    },
    background: {
      default: "#F8F8F8",
    },
  },
  typography: {
    fontFamily: '"DM sans", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "26px",
      lineHeight: 1.25,
      fontWeight: 800,
      color: "#27292A",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    subtitle2: {
      lineHeight: 1.25,
      fontSize: "24px",
      "@media (max-width: 600px)": {
        fontSize: "20px",
      },
    },
    body2: {
      fontSize: "14px",
      color: "#30353B",
      fontWeight: 400,
      "@media (max-width: 600px)": {
        fontSize: "12px",
      },
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: "6px",
      },
    },
  },
});

const drawerWidth = 440;
// Customizable Area End

export default class AiQueryHub extends AiQueryHubController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  menuOptions = [
    {
      text: "Download",
      iconSrc: download,
      onClick: this.toggleDownloadSettings,
    },
    {
      text: "Share",
      iconSrc: share,
      onClick: this.toggleShare,
    },
    {
      text: "Regenerate",
      iconSrc: regenerate,
      onClick: () => console.log("Regenerate clicked"),
    },
  ];

  renderMainContent = () => {
    const { activeTab } = this.state;
    const isActionTable = this.state.historyDropdown === "actions"? true : false
    switch (activeTab) {
      case 0:
        return <Results navigation={this.props.navigation} />;
      case 1:
        return <Json navigation={this.props.navigation} />;
      case 2:
        return <AiQueryHubFilesTab navigation={this.props.navigation} classes={this.props.classes} />;
      default:
        return <AiQueryHubHistoryTab isActionTable={isActionTable}/>;
    }
  };

  renderSubMenu = () => {
    const { activeTab } = this.state;

    switch (activeTab) {
      case 0:
      case 1:
        return (
          <>
            <Box className="version">
              <Typography variant="body2" component="span">
                {configJSON.versionLabel}
                &nbsp; 2/2 &nbsp;
              </Typography>

              <IconButton>
                <KeyboardArrowLeftIcon />
              </IconButton>
              <IconButton disabled>
                <KeyboardArrowRightIcon />
              </IconButton>
            </Box>

            <Divider
              className="divider"
              orientation="vertical"
              variant="middle"
              flexItem
            />

            <IconButton
              aria-describedby="id"
              data-test-id="more-menu"
              className="more__icon"
              onClick={this.openMoreMenu}
            >
              <MoreVertIcon />
            </IconButton>

            <StyledPopover
              data-test-id="popover-menu"
              open={Boolean(this.state.anchorEl)}
              anchorEl={this.state.anchorEl}
              onClose={this.closeMoreMenu}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                className: "popover__paper",
              }}
            >
              <List className="list">
                {this.menuOptions.map((option, index) => (
                  <ListItem
                    data-test-id={`option-${option.text}`}
                    className="list__item"
                    button
                    key={index}
                    onClick={() => {
                      option.onClick();
                      this.closeMoreMenu();
                    }}
                  >
                    <ListItemIcon className="list__icon">
                      <img src={option.iconSrc} />
                    </ListItemIcon>
                    <ListItemText
                      className="list__label"
                      primary={option.text}
                    />
                  </ListItem>
                ))}
              </List>
            </StyledPopover>
          </>
        );
      case 3:
        return (
          <CustomSelect
            data-test-id="historyDropdown"
            name="selection"
            options={[
              { label: "Actions", value: "actions" },
              { label: "Queries", value: "queries" },
            ]}
            value={this.state.historyDropdown}
            onChange={this.handleHistoryDropdown}
            className="history__selection"
            MenuProps={{
              PaperProps: {
                style: {
                  marginTop: "44px",
                  borderRadius: "8px",
                  border: "solid 1px #E2E8F0",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)"
                }
              }
            }}
          />
        );
    }
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <BoxWrapper>
          <AppBarStyled
            style={{
              width: this.state.openChatBot
                ? `calc(100% - ${drawerWidth}px)`
                : "100%",
              marginRight: this.state.openChatBot ? drawerWidth : 0,
            }}
          >
            <Box className="header">
              <Tabs
                value={this.state.activeTab}
                onChange={this.handleTabChange}
                className="tab__wrapper"
                TabIndicatorProps={{ style: { display: "none" } }}
                data-test-id="tab"
              >
                <Tab label="Results" className="tab" />
                <Tab label="JSON" className="tab" />
                <Tab label="Files" className="tab" />
                <Tab label="History" className="tab" data-test-id="history" />
              </Tabs>

              <Box className="sub__menu">
                {this.renderSubMenu()}
              </Box>
            </Box>
            {!this.state.openChatBot && (
              <Button
                className="neeve__btn"
                data-test-id="open-drawer"
                onClick={this.handleDrawerOpen}
              >
                <img src={doubleLeftIcon} alt="open" className="open__icon" />
                <img src={logoWhite} alt="logo" className="logo" />
                Neeve
              </Button>
            )}
          </AppBarStyled>

          <ContentWrapper
            style={{ marginRight: this.state.openChatBot ? drawerWidth : 0 }}
          >
            {this.renderMainContent()}
          </ContentWrapper>

          <DrawerStyled
            anchor="right"
            variant="persistent"
            open={this.state.openChatBot}
            PaperProps={{
              style: {
                position: this.state.openChatBot ? "absolute" : "inherit",
                display: this.state.openChatBot ? "block" : "none",
              },
            }}
          >
            <AiQueryChat
              data-test-id="chat-wrapper"
              navigation={this.props.navigation}
              handleDrawerClose={this.handleDrawerClose}
              toggleNewQueryDialog={this.toggleNewQueryDialog}
            />
          </DrawerStyled>

          <CustomDialogWithAction
            data-test-id="download-settings-dialog"
            open={this.state.openDowloadSettings}
            handleClose={this.toggleDownloadSettings}
            title={configJSON.downloadingSettings}
            dialogContent={
              <>
                <DialogContent
                  style={{ width: "495px", paddingBottom: 0 }}
                  dividers
                >
                  <Formik
                    initialValues={{
                      fileName: "Report_financial overview ",
                      resultVersion: "v1",
                      fileFormat: "pdf",
                    }}
                    validationSchema={this.downloadValidationSchema}
                    onSubmit={(values) => console.log("Values: ", values)}
                  >
                    {() => (
                      <Form id="download-form">
                        <CustomInput
                          formik
                          name="fileName"
                          label="File name"
                          color="secondary"
                        />

                        <CustomSelect
                          formik
                          name="resultVersion"
                          label="Result version"
                          options={[
                            { label: "Version 1", value: "v1" },
                            { label: "Version 2", value: "v2" },
                          ]}
                        />

                        <CustomSelect
                          formik
                          name="fileFormat"
                          label="File format"
                          options={[
                            { label: "PDF", value: "pdf" },
                            { label: "DOCS", value: "docs" },
                          ]}
                        />
                      </Form>
                    )}
                  </Formik>
                </DialogContent>
                <DialogActions>
                  {/* <Button type="submit" form="download-form">
                    Download
                  </Button> */}

                  <CommonButton
                    label="Download"
                    type="submit"
                    onClick={() => {}}
                    dataTestId=""
                    data-test-id="download-submit"
                  />
                </DialogActions>
              </>
            }
          />

          <CustomDialogWithAction
            data-test-id="share-dialog"
            open={this.state.openShare}
            handleClose={this.toggleShare}
            title={configJSON.shareDialogTitle}
            dialogContent={
              <>
                <DialogContent
                  style={{ width: "495px", paddingBottom: 0 }}
                  dividers
                >
                  <Formik
                    initialValues={this.defaultShareValues}
                    validationSchema={this.shareValidationSchema}
                    onSubmit={(values) => console.log("Values: ", values)}
                  >
                    {({ values, setFieldValue }) => (
                      <Form id="share-form">
                        <StyledFormControl margin="dense">
                          <FormLabel className="form__label">
                            {configJSON.chooseSourceTitle}
                          </FormLabel>
                          <RadioGroup
                            row
                            name="contactMethod"
                            value={values.shareMethod}
                            onChange={(e) =>
                              setFieldValue("shareMethod", e.target.value)
                            }
                            className="radiogroup"
                          >
                            <FormControlLabel
                              value={ShareMethod.Chat}
                              control={
                                <Radio
                                  checkedIcon={<img src={checked} />}
                                  icon={<img src={unchecked} />}
                                />
                              }
                              label={configJSON.shareWithChat}
                            />
                            <FormControlLabel
                              value={ShareMethod.Email}
                              control={
                                <Radio
                                  checkedIcon={<img src={checked} />}
                                  icon={<img src={unchecked} />}
                                />
                              }
                              label={configJSON.shareWithEmail}
                            />
                          </RadioGroup>
                        </StyledFormControl>

                        {values.shareMethod === ShareMethod.Chat && (
                          <>
                            <StyledAutoComplete
                              multiple
                              options={[]}
                              value={values.chatOption}
                              onChange={(_, newValue) => {
                                setFieldValue("chatOption", newValue);
                              }}
                              renderTags={() => null}
                              renderInput={(params) => (
                                <CustomInput
                                  {...params}
                                  label="Search a chat"
                                  name="chatOption"
                                  formik
                                  placeholder="Search chat to share by name or member"
                                  onChange={(event: any) => event.target.value}
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <InputAdornment position="end">
                                        <img src={searchIcon} />
                                      </InputAdornment>
                                    ),
                                    endAdornment: <></>,
                                  }}
                                />
                              )}
                            />
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              gridGap="8px"
                              mt={2}
                              mb={2}
                            >
                                {["Top designers", "LT Workspace"].map((option) => (
                                  <StyledChip
                                    variant="outlined"
                                    size="small"
                                    label={option}
                                    className="chip"
                                    onDelete={() => {}}
                                    deleteIcon={<ClearIcon />}
                                  />
                                ))}
                            </Box>
                          </>
                        )}

                        {values.shareMethod === ShareMethod.Email && (
                          <CustomInput
                            formik
                            name="email"
                            label="Email"
                            color="secondary"
                            placeholder="Enter an email to share"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <DoneIcon
                                    fontSize="small"
                                    color="secondary"
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}

                        <CustomSelect
                          formik
                          name="resultVersion"
                          label="Result version"
                          options={[
                            { label: "Version 1", value: "v1" },
                            { label: "Version 2", value: "v2" },
                          ]}
                        />

                        <CustomSelect
                          formik
                          name="fileFormat"
                          label="File format"
                          options={[
                            { label: "PDF", value: "pdf" },
                            { label: "DOCS", value: "docs" },
                          ]}
                        />
                      </Form>
                    )}
                  </Formik>
                </DialogContent>
                <DialogActions>
                  {/* <Button type="submit" form="share-form">
                    Download
                  </Button> */}

                  <CommonButton
                    dataTestId=""
                    data-test-id="share-submit"
                    label="Share"
                    type="submit"
                    onClick={() => {}}
                  />
                </DialogActions>
              </>
            }
          />

          <CustomDialogWithAction
            data-test-id="new-query-dialog"
            open={this.state.openNewQueryDialog}
            handleClose={() => {}}
            hideCloseButton
            disableEscapeKeyDown
            disableBackdropClick
            title="Are you sure you want to start new query?"
            dialogContent={
              <>
                <DialogContent dividers style={{ paddingBottom: 0 }}>
                  <StyledWrapper>
                    <Typography className="content">
                      Please confirm you want to start new query. You will be
                      able to find previous queries at History tab within option
                      Queries selected.
                    </Typography>
                    <Typography className="content">
                      You will be able to resume and work on it later.
                    </Typography>
                  </StyledWrapper>
                </DialogContent>
                <DialogActions>
                  <CommonButton
                    dataTestId=""
                    onClick={this.toggleNewQueryDialog}
                    variant="secondary"
                    label="Cancel"
                  />
                  <CommonButton
                    dataTestId=""
                    onClick={() => {}}
                    variant="primary"
                    label="Start new query"
                  />
                </DialogActions>
              </>
            }
          />
        </BoxWrapper>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const BoxWrapper = styled("div")({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  height: "1px",
  "& .tab__wrapper": {
    borderRadius: "12px",
    border: "none",
    padding: "4px",
    backgroundColor: "#FFF",
    alignItems: "center",
    maxHeight: "36px",
    minHeight: "unset",
    "& .tab": {
      minWidth: "unset",
      padding: "3px 12px",
      textTransform: "none",
      minHeight: "unset",
      color: "#5E6671",
      border: "solid 1px #D9D1EF",
      borderColor: "#FFF",
      borderRadius: "6px",
      maxHeight: "28px",
      "&.Mui-selected": {
        backgroundColor: "#EAE6FF",
        border: "solid 1px #D9D1EF",
        color: "#684EF3",
      },
    },
  },
  "& .history__selection": {
    "& .MuiFormControl-root": {
      minHeight: "unset",
      width: "154px",
      maxHeight: "36px",
      "& .custom-select": {
        "& .MuiSelect-select": {
          padding: "8px 44px 8px 12px",
          maxHeight: "36px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          border: "solid 1px #CDD7E6"
        },
        "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "solid 1px #684EF3"
        }
      },
    },
  },
});

const StyledWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  "& .content": {
    lineHeight: "24px",
    fontWeight: 400,
    color: colors().chineseBlack,
    fontSize: "16px",
  },
});

const ContentWrapper = styled("main")({
  flex: 1,
  overflowY: "auto",
  backgroundColor: "#FFF"
  // transition: "margin 0.3s ease-in-out",
});

const StyledAutoComplete = styled(Autocomplete)({
  "& .MuiFormControl-root": {
    minHeight: "unset",
    "& .MuiInputBase-root": {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
      paddingLeft: "2px !important",
    },
  },
});

const StyledChip = styled(Chip)({
  padding: "2px 0",
  border: "solid 1px #94A3B8",
  "& .MuiChip-label": {
    fontSize: "12px",
    color: colors().darkText,
    lineHeight: "18px",
  },
  "& .MuiChip-deleteIcon": {
    color: colors().darkText,
    marginRight: "4px",
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  height: "77px",
  "& .form__label": {
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 700,
    color: colors().darkText,
    marginBottom: "6px",
  },
  "& .radiogroup": {
    "& > .MuiFormControlLabel-root": {
      flex: 1,
      "& > .MuiFormControlLabel-label": {
        fontSize: "14px",
        color: colors().darkText,
      },
    },
  },
});

const AppBarStyled = styled(AppBar)({
  // transition: "margin 0.3s ease-in-out",
  position: "relative",
  background: "#E2E8F0",
  boxShadow: "none",
  display: "flex",
  flexDirection: "row",
  overflow: "hidden",
  "& .header": {
    display: "flex",
    padding: "16px 24px !important",
    maxHeight: "68px",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    boxSizing: "border-box",
    flex: 1,
    "& .sub__menu": {
      display: "flex",
      alignItems: "center",
      gap: "16px",
      "& .version": {
        display: "flex",
        alignItems: "center",
      },
      "& .more__icon": {
        borderRadius: "6px",
        height: "32px",
        width: "32px",
        color: "#14101E",
        "&.active": {
          backgroundColor: "#EAE6FF",
          border: "solid 1px #D9D1EF",
        },
      },
      "& .divider": {
        margin: "5px auto",
        marginLeft: 0,
      },
    },
  },
  "& .neeve__btn": {
    background: "#684EF3",
    color: "#FFF",
    display: "flex",
    alignItems: "center",
    padding: "22px 20px",
    borderRadius: 0,
    fontSize: "18px",
    lineHeight: "27px",
    textTransform: "none",
    "& .open__icon": {
      marginRight: "12px",
    },
    "& .logo": {
      marginRight: "6px",
    },
  },
});

const StyledPopover = styled(Popover)({
  "& .popover__paper": {
    borderRadius: "8px",
    padding: "4px",
    marginTop: "4px",
    border: "solid 1px #E2E8F0",
    boxShadow: "0px 4px 6px -2px #684EF333",
  },
  "& .list": {
    paddingTop: 0,
    paddingBottom: 0,
    "& .list__item": {
      maxHeight: "36px",
      minWidth: "170px",
      "& .list__icon": {
        marginRight: "10px",
        minWidth: "unset",
      },
      "& .list__label": {
        color: "#30353B",
        fontSize: "14px",
        lineHeight: "21px",
      },
    },
  },
});

const DrawerStyled = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiPaper-root": {
    transition: "none !important",
  },
  "& .MuiDrawer-paper": {
    width: drawerWidth,
  },
});
// Customizable Area End