import React from "react";
import { Avatar } from "@material-ui/core";

interface Props {
  src: string | null | undefined;
  alt: string | null | undefined;
  style?: any;
  className?: string;
}

const CommonAvtar = (props: Props) => {
  const { src, style, alt, className } = props;

  return (
    <Avatar
      src={src || ""}
      alt={alt || ""}
      style={{ ...webStyles, ...style }}
      className={className}
    >
      {alt && alt.charAt(0)}
    </Avatar>
  );
};

const webStyles = {
  fontSize: "12px",
  lineHeight: "14px",
};
export default CommonAvtar;
