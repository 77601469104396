import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("../config");
const baseURL = require("../../../../framework/src/config.js").baseURL;
import {
  makeApiMessage,
  navigateToScreen,
} from "../../../../blocks/utilities/src/commonfunctions";
import { getStorageData } from "../../../../framework/src/Utilities";
import moment from "moment";
import {
  AuditHistoryList,
  PaginationMeta,
} from "../../../../blocks/utilities/src/interface";

export interface FileInteraction {
  filename: string;
  url: string;
}
export interface AuditHistoryTableObject {
  actionType: string;
  type: string;
  url: string;
  filename: string;
  lastUpdate: string;
  actionByFullName: string | null;
  description: string;
  profile_photo: string | null;
  actionByFirstName: string | null;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentWorkspaceName: string;
  currentPage: number;
  perPage: number;
  totalPages: number;
  auditHistoryLists: AuditHistoryTableObject[];
  totalResults: number;
  currentFile: FileInteraction | undefined;
  isViewDialogOpen: boolean;
  isFetchingData: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class AuditTrailHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllAuditHistoryApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      currentWorkspaceName: "",
      currentPage: 1,
      perPage: 10,
      totalPages: 2,
      auditHistoryLists: [],
      totalResults: 2,
      currentFile: undefined,
      isViewDialogOpen: false,
      isFetchingData: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
        if (webApiRequestCallId === this.getAllAuditHistoryApiCallId) {
          this.handleAuditHistoryApiResonse(webResponseJson);
        }
      } else if (webResponseJson && webResponseJson.errors) {
        if (webApiRequestCallId === this.getAllAuditHistoryApiCallId) {
          this.setState({ isFetchingData: false });
        }
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getAllAuditHistory();
    // Customizable Area End
  }

  // Customizable Area Start
  goToWorkspaceList = () => {
    const message = navigateToScreen("WorkspaceList", this.props);
    this.send(message);
  };

  goToWorkspaceListById = () => {
    this.props.navigation.goBack();
  };

  getAllAuditHistory = async () => {
    const { currentPage, perPage } = this.state;
    const apiUrl = configJSON.getAuditHistoryApiEndPoint;
    const workspaceId = this.props.navigation.getParam("id");
    const requestMessage = await makeApiMessage({
      url:
        apiUrl +
        `?workspace_id=${workspaceId}&page=${currentPage}&per_page=${perPage}`,
      method: "GET",
    });
    this.setState({ isFetchingData: true });
    this.getAllAuditHistoryApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleAuditHistoryApiResonse = async (response: {
    activities: AuditHistoryList[];
    meta: PaginationMeta;
  }) => {
    const currentName = await getStorageData("workspace_name");
    const dataTable = response.activities.map((item) => {
      const listName = item?.file_details[0]?.content_type?.split("/") ?? [""];
      const extension = listName[listName.length - 1];
      const fullName =
        item?.account_details?.data.attributes?.first_name ||
        "" ||
        "" + " " + (item?.account_details?.data.attributes?.last_name || "");
      return {
        actionType: item.action_type,
        actionByFullName: fullName,
        actionByFirstName: item?.account_details?.data.attributes?.first_name,
        profile_photo: item?.account_details?.data?.attributes?.profile_photo,
        description: item.description,
        type: extension.toUpperCase(),
        url: `${baseURL}${item?.file_details[0]?.url ?? ""}`,
        lastUpdate: moment(item.updated_at).format("MM/DD/YYYY, hh:mm"),
        filename: item?.file_details[0]?.filename ?? "",
      };
    });
    this.setState({
      auditHistoryLists: dataTable,
      currentWorkspaceName: currentName,
      totalPages: response.meta.total_pages,
      totalResults: response.meta.total_count,
      perPage: Number(response.meta.per_page),
      currentPage: response.meta.current_page,
      isFetchingData: false
    });
  };

  handleChangePage = (_event: React.ChangeEvent<unknown>, newPage: number) => {
    this.setState({ currentPage: newPage }, () => this.getAllAuditHistory());
  };

  handleClickFile = (row: AuditHistoryTableObject) => {
    if (row.url !== "") {
      this.setState({
        currentFile: {
          filename: row.filename,
          url: row.url,
        },
        isViewDialogOpen: true,
      });
    }
  };

  closeDialog = () => this.setState({ isViewDialogOpen: false });
  // Customizable Area End
}
