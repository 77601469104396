import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

// Customizable Area Start
export const configJSON = require("./config");

export interface HistoryTabActions {
  dateTime: string;
  action: string;
}

export interface HistoryTabQuery {
  dateTime: string;
  created_query: string;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: {
    navigate: Function;
    getParam: Function;
  };
  id?: string;
  isActionTable: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentPage: number;
  anchorEl: HTMLElement | null;
  selectedRow: string | null;
  renderActionTable: boolean;
  rows: any;
  openDeleteDialogue: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class AiQueryHubHistoryTabController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      currentPage: 1,
      anchorEl: null,
      selectedRow: null,
      renderActionTable: true,
      rows: [
        [
          "09/13/24 9:12",
          "Can you provide an overview of insurance responsibilities for landlords?",
        ],
        [
          "09/13/24 9:12",
          "Please analyze my bank statements for the past year.",
        ],
        [
          "08/13/24 9:12",
          "I need a breakdown of my monthly expenses from the last six months.",
        ],
        [
          "06/13/24 9:12",
          "Analyze my investment portfolio and suggest adjustments.",
        ],
        [
          "06/13/24 9:12",
          "Compare my credit card statements to identify unusual patterns.",
        ],
        [
          "05/13/24 9:12",
          "Evaluate my income sources and provide recommendations for diversification.",
        ],
        [
          "04/13/24 9:12",
          "Analyze my budgeting effectiveness and suggest improvements.",
        ],
        [
          "03/13/24 9:12",
          "Examine my loan agreements and calculate total interest paid.",
        ],
        [
          "02/13/24 9:12",
          "Provide a summary of my tax returns and highlight deductions.",
        ],
      ],
      openDeleteDialogue: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    this.setState({ currentPage: newPage });
  };

  handleMenuOpen = (event: React.MouseEvent<HTMLElement>, row: string) => {
    this.setState({ anchorEl: event.currentTarget, selectedRow: row });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, selectedRow: null });
  };

  toggleDeleteDialogue = () => {
    this.setState({ openDeleteDialogue: !this.state.openDeleteDialogue });
  };

  handleDialogue = () => {
    this.toggleDeleteDialogue();
    this.handleMenuClose();
  };

  // Customizable Area End
}