import React from "react";
// Customizable Area Start
import FileViewModalController, {
  Props,
  configJSON,
} from "./FileViewModalController.web";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
  Box,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import {
  closesvgIcon,
  downloadIcon,
  leftArrowIcon,
  rightArrowIcon,
} from "../assets";
import { colors } from "../../../../blocks/utilities/src/Colors";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { handleDownloadFile } from "../../../../blocks/utilities/src/commonfunctions";
// Customizable Area End

// Customizable Area Start
const string = configJSON.fileViewModalString;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// Customizable Area End

export class FileViewModal extends FileViewModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPages() {
    const { numPages, scale } = this.state;
    return Array.from(new Array(numPages), (_, index) => {
      const pageNumber = index + 1;
      return (
        <div
          key={`page_${pageNumber}`}
          ref={this.pageRefs[index]}
          data-page-number={pageNumber}
          className="pdf-page-wrapper"
        >
          <Page
            pageNumber={pageNumber}
            scale={scale}
            loading={<CircularProgress />}
          />
        </div>
      );
    });
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { open, pdfUrl, pdfName } = this.props;
    const { currentPage, numPages, scale } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <ViewFileDialogWrapper
        maxWidth="lg"
        open={open}
        onClose={this.handleModalClose}
      >
        <Box>
          <Box className="dialog-header">
            <DialogTitle className="file-name">{pdfName}</DialogTitle>
            <img
              className="close-icon"
              src={closesvgIcon}
              alt="close"
              onClick={this.handleModalClose}
              data-test-id="close-icon-btn"
            />
          </Box>
          <Box className="pdf-controller">
            <Box className="pdf-pagination-control">
              <Typography className="pdf-page-text">{string.page}</Typography>
              <Box className="pdf-pagination-inner-control">
                <TextField
                  value={currentPage}
                  type="number"
                  className="page-text-field"
                  data-test-id="page-change-field"
                  inputProps={{
                    min: 1,
                    max: numPages,
                  }}
                  disabled={this.handleSetdisabled()}
                  onChange={(event) => this.onPdfPageChange(event)}
                />
                <Box className="pdf-page-number">/</Box>
                <Typography className="pdf-page-number">{numPages}</Typography>
              </Box>
            </Box>
            <Box className="zoom-control-main">
              <img
                className="zoom-contol-icon"
                data-test-id="zoom-out-buttom"
                src={leftArrowIcon}
                alt="left"
                onClick={this.handleZoomOut}
              />
              <Box
                className="zoom-control-Box"
                data-test-id="zoom-reset-control"
                onClick={() => this.handleResetScale()}
              >
                <Typography>{(scale * 100).toFixed(0)}%</Typography>
              </Box>
              <img
                className="zoom-contol-icon"
                src={rightArrowIcon}
                data-test-id="zoom-in-buttom"
                alt="right"
                onClick={this.handleZoomIn}
              />
            </Box>
            <Box>
              <img
                className="zoom-contol-icon"
                data-test-id="download-pdf-button"
                src={downloadIcon}
                alt="download"
                onClick={() => handleDownloadFile(pdfUrl, pdfName)}
              />
            </Box>
          </Box>
        </Box>
        <DialogContent>
          <Document
            file={pdfUrl}
            onLoadSuccess={this.onDocumentLoadSuccess}
            loading={<CircularProgress />}
          >
            {this.renderPages()}
          </Document>
        </DialogContent>
      </ViewFileDialogWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ViewFileDialogWrapper = styled(Dialog)({
  "& .MuiDialog-paperWidthLg": {
    maxWidth: "1000px",
    width: "100%",
    borderRadius: "24px",
    boxShadow: "0px 24px 48px -12px #684EF333",
  },
  "& .MuiDialogTitle-root": {
    padding: 0,
  },
  "& .dialog-header": {
    padding: "26px 32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${colors().tableBorder}`,
  },
  "& .file-name": {
    fontSize: "20px",
    lineHeight: "28px",
    fontWeight: 500,
    fontFamily: "DM Sans",
    color: colors().darkText,
  },
  "& .pdf-controller": {
    padding: "18px 36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .pdf-pagination-control": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .pdf-pagination-inner-control": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .pdf-page-text": {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    fontFamily: "DM Sans",
    color: colors().darkText,
  },
  "& .pdf-page-number": {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    fontFamily: "DM Sans",
    color: colors().grayText,
  },
  "& .page-text-field": {
    padding: "4px",
    width: "28px",
    height: "28px",
    borderRadius: "6px",
    border: `1px solid ${colors().tableBorder}`,
  },
  "& .MuiInputBase-input": {
    textAlign: "center",
  },
  "& .MuiInput-underline:before": {
    display: "none",
  },
  "& .MuiInput-underline:after": {
    display: "none",
  },
  "& .zoom-contol-icon": {
    cursor: "pointer",
  },
  "& .zoom-control-main": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .zoom-control-Box": {
    width: "72px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "6px",
    border: `1px solid ${colors().tableBorder}`,
    cursor: "pointer",
    "& p": {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 700,
      fontFamily: "DM Sans",
    },
  },
  "& .MuiDialogContent-root": {
    padding: "24px",
    background: colors().tableBorder,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .react-pdf__Document": {
    width: "100%",
  },
  "& .react-pdf__Page": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .react-pdf__Page__canvas": {
    width: "100% !important",
  },
});
export default FileViewModal;
// Customizable Area End
