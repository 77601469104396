// Customizable Area Start
import React from "react";
import UserProfileDialogController from "./UserProfileDialogController";
import { Box, Button, Dialog, DialogContent, Menu, MenuItem, TextField, Typography } from "@material-ui/core";
import Dropzone from 'react-dropzone';
import { upload } from "./assets";
import CommonButton from "../../../components/src/common/CommonButton.web";
import { Form, Formik } from "formik";

export class UserProfileDialog extends UserProfileDialogController {
    renderTextFieldFirstName = (setFieldValue: Function, error: string) => {
        return (
            <TextField
                data-test-id='firstNameInt'
                value={this.state.firstName}
                style={webStyles.inputFeild}
                onChange={(event) => {
                    setFieldValue("firstName",event.target.value.trimStart())
                    this.onChangefirstnameAction(event.target.value.trimStart())}}
                placeholder="Enter your first name"
                InputProps={{
                    disableUnderline: true,
                    style: webStyles.input
                }}
                FormHelperTextProps={{
                    style: {color: "#DC2626"}
                }}
                helperText={error}
            />
        );
    }

    renderTextFieldLastName = (setFieldValue: Function, error: string) => {
        return (
            <TextField
                data-test-id='lastNameInt'
                value={this.state.lastName}
                style={webStyles.inputFeild}
                onChange={(event) => {
                    setFieldValue("lastName",event.target.value.trimStart());
                    this.onChangelastnameAction(event.target.value.trimStart())}}
                placeholder="Enter your last name"
                InputProps={{
                    disableUnderline: true,
                    style: webStyles.input
                }}
                helperText={error}
                FormHelperTextProps={{
                    style: {color: "#DC2626"}
                }}
            />
        );
    }

    renderTextFieldCompanyName = (setFieldValue: Function, error: string) => {
        return (
            <TextField
                data-test-id='companyInt'
                value={this.state.company}
                style={webStyles.inputFeild}
                onChange={(event) => {
                    setFieldValue("company",event.target.value.trimStart());
                    this.onChangecomanynameAction(event.target.value.trimStart())}}
                placeholder="Enter your company"
                InputProps={{
                    disableUnderline: true,
                    style: webStyles.input
                }}
                helperText={error}
                FormHelperTextProps={{
                    style: {color: "#DC2626"}
                }}
            />
        );
    }

    renderTextFieldPosition = (setFieldValue: Function, error: string) => {
        return (
            <TextField
                data-test-id='positionInt'
                value={this.state.position}
                style={webStyles.inputFeild}
                onChange={(event) => {
                    setFieldValue("position",event.target.value.trimStart())
                    this.onChangepositionAction(event.target.value.trimStart())}}
                placeholder="Enter your position"
                InputProps={{
                    disableUnderline: true,
                    style: webStyles.input
                }}
                helperText={error}
                FormHelperTextProps={{
                    style: {color: "#DC2626"}
                }}
            />
        );
    }

    renderLayerEditPicture = () => {
        return (
            <div className="edit-photo-popup-profile">
                <Dropzone multiple={false} noDrag noKeyboard onDrop={(acceptedFile) => {
                    this.closeMenu()
                    this.onDrop(acceptedFile)
                }}>
                    {({ getInputProps, getRootProps }) => (
                        <div>
                            <Button
                                data-test-id={`open-menu`}
                                onClick={this.openMenu}
                                style={webStyles.editPhoto}
                            >
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.99867 21.1569C4.07838 21.1665 4.15896 21.1665 4.23867 21.1569L8.23867 20.1569C8.41612 20.1147 8.57865 20.0248 8.70867 19.8969L20.9987 7.56689C21.3712 7.19216 21.5803 6.68526 21.5803 6.15689C21.5803 5.62851 21.3712 5.12161 20.9987 4.74689L19.4187 3.15689C19.2329 2.97093 19.0123 2.82341 18.7696 2.72277C18.5268 2.62212 18.2665 2.57031 18.0037 2.57031C17.7408 2.57031 17.4806 2.62212 17.2378 2.72277C16.995 2.82341 16.7744 2.97093 16.5887 3.15689L4.29867 15.4469C4.16946 15.5775 4.07642 15.7395 4.02867 15.9169L3.02867 19.9169C2.99273 20.0463 2.98322 20.1816 3.0007 20.3147C3.01818 20.4479 3.0623 20.5761 3.13043 20.6919C3.19856 20.8076 3.2893 20.9084 3.39724 20.9883C3.50517 21.0682 3.62809 21.1255 3.75867 21.1569C3.83838 21.1665 3.91896 21.1665 3.99867 21.1569ZM17.9987 4.56689L19.5887 6.15689L17.9987 7.74689L16.4187 6.15689L17.9987 4.56689ZM5.90867 16.6669L14.9987 7.56689L16.5887 9.15689L7.48867 18.2569L5.37867 18.7769L5.90867 16.6669Z" fill="white"/>
                                </svg>
                                <div style={webStyles.textEditPhoto}>Edit picture</div>
                            </Button>
                            <Menu
                                id="simple-menu"
                                anchorEl={this.state.anchorEl}
                                keepMounted
                                open={Boolean(this.state.anchorEl)}
                                onClose={this.closeMenu}
                                PaperProps={{
                                    style: webStyles.menuStyle
                                }}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                            >
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps({ multiple: false, accept: 'image/*' })} />
                                    <MenuItem data-test-id="replace-file">
                                        <svg style={webStyles.editPhotoIcon} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.25 11.4072H9.75V6.90723H12L9 3.15723L6 6.90723H8.25V11.4072Z" fill="#14101E"/>
                                            <path d="M15 13.6572H3V8.40723H1.5V13.6572C1.5 14.4845 2.17275 15.1572 3 15.1572H15C15.8273 15.1572 16.5 14.4845 16.5 13.6572V8.40723H15V13.6572Z" fill="#14101E"/>
                                        </svg>
                                        Upload picture
                                    </MenuItem>
                                </div>
                                <MenuItem data-test-id="remove-file" onClick={() => this.onRemoveFile(0)}>
                                    <svg style={webStyles.editPhotoIcon} width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.75 15.1572C3.75 15.5551 3.90804 15.9366 4.18934 16.2179C4.47064 16.4992 4.85218 16.6572 5.25 16.6572H12.75C13.1478 16.6572 13.5294 16.4992 13.8107 16.2179C14.092 15.9366 14.25 15.5551 14.25 15.1572V6.15723H15.75V4.65723H12.75V3.15723C12.75 2.7594 12.592 2.37787 12.3107 2.09657C12.0294 1.81526 11.6478 1.65723 11.25 1.65723H6.75C6.35218 1.65723 5.97064 1.81526 5.68934 2.09657C5.40804 2.37787 5.25 2.7594 5.25 3.15723V4.65723H2.25V6.15723H3.75V15.1572ZM6.75 3.15723H11.25V4.65723H6.75V3.15723ZM12.75 6.15723V15.1572H5.25V6.15723H12.75Z" fill="#DC2626"/>
                                        <path d="M6.75 7.65723H8.25V13.6572H6.75V7.65723ZM9.75 7.65723H11.25V13.6572H9.75V7.65723Z" fill="#DC2626"/>
                                    </svg>
                                    Remove file
                                </MenuItem>
                            </Menu>
                        </div>
                    )}
                </Dropzone>
            </div>
        )
    }
    
    renderViewAvatar = () => {
        if (this.state.files.length > 0 && typeof this.state.files[0] === "string") {
            return (
                <div style={webStyles.uploadedContainer}>
                    <img
                        src={this.state.files[0]}
                        style={webStyles.uploadedimg}
                    />
                    {this.renderLayerEditPicture()}
                </div>
            )
        } else {
            return (
                <div>
                    <Dropzone onDrop={this.onDrop} noDrag>
                        {({ getRootProps, getInputProps }) => (
                            <section className="container">
                                <div {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps({ multiple: false, accept: 'image/*' })} />
                                    {this.state.files.length === 0 ? (
                                        <div style={webStyles.uploadContainer}>
                                            <img src={upload} style={webStyles.img} />
                                            <span style={webStyles.dragAndDropText}>Upload picture</span>
                                            <div data-test-id="uploadFile" style={webStyles.browseButton}>Up to 1 MB</div>
                                        </div>
                                    ) : (
                                        <div style={webStyles.uploadedContainer}>
                                            <img
                                                src={URL.createObjectURL(this.state.files[0]) || ""}
                                                style={webStyles.uploadedimg}
                                            />
                                            {this.renderLayerEditPicture()}
                                        </div>
                                    )}
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    {
                        this.state.isExceeded && 
                            <Box sx={{ marginTop:"5px", marginLeft:"28%" }}>
                                <Typography style={webStyles.exceedText}>The image exceeded the maximum size.</Typography>
                            </Box>
                    }
                </div>
            )
        }
    }

    render() {
        const { isDialogOpen, isEdit } = this.props
        return (
            <Dialog
                data-test-id="dialog"
                aria-labelledby="customized-dialog-title"
                open={isDialogOpen}
                fullWidth
                PaperProps={{
                    style: webStyles.viewDialog
                }}
            >
                <DialogContent
                    className="dialog-address-content"
                    style={{ padding: 0 }}
                >
                 <Formik
                    initialValues={{
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        company: this.state.company,
                        position: this.state.position,
                    }}
                    validationSchema={this.editProfileValidationSchema}
                    onSubmit={() => {}}
                    data-test-id="verify-otp-formik"
                    enableReinitialize
                >
                    {({values, setFieldValue, errors}) => (
                    <Form data-test-id="verify-otp-form" onSubmit={(e)=> e.preventDefault()}>

                    <div style={webStyles.textTitle}>{isEdit ? "Edit personal information" : "Create your profile"}</div>

                    <div style={webStyles.viewInput}>
                    <span style={{...webStyles.titleTextInput}}>Profile photo <span style={webStyles.optionalTextInput}>(optional)</span></span>
                        {this.renderViewAvatar()}
                    </div>

                    <div style={webStyles.viewInput}>
                        <span style={webStyles.titleTextInput}>First name</span>
                        {this.renderTextFieldFirstName(setFieldValue, errors.firstName as string)}
                    </div>
                    <div style={webStyles.viewInput}>
                        <span style={webStyles.titleTextInput}>Last name</span>
                        {this.renderTextFieldLastName(setFieldValue, errors.lastName as string)}
                    </div>
                    <div style={webStyles.viewInput}>
                        <span style={webStyles.titleTextInput}>Company</span>
                        {this.renderTextFieldCompanyName(setFieldValue, errors.company as string)}
                    </div>
                    <div style={webStyles.viewInput}>
                        <span style={webStyles.titleTextInput}>Position</span>
                        {this.renderTextFieldPosition(setFieldValue, errors.position as string)}
                    </div>

                    <div style={webStyles.buttonWrapper}>
                    {
                        this.props.isEdit && 
                        <div style={webStyles.cancelButton}>
                            <CommonButton
                                dataTestId="cancelButton"
                                label="Cancel"
                                type="button"
                                variant="secondary"
                                onClick={() => this.handleCancel()}
                            />
                        </div>
                    }
                   
                    <div
                        data-test-id="continueButton"
                        style={{...webStyles.okButton, ...{ backgroundColor: this.validationButton() ? "#684EF3" : "#94A3B8" }}}
                        onClick={this.onContinue}
                    >
                        { this.props.isEdit ? "Save" : "Confirm"}
                    </div>
                    </div>
                    </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        );
    }
}

const webStyles = {
    input: {
        height: "100%",
        padding: 0,
        borderRadius: '8px',
        border:"1px solid #E2E8F0",
        paddingLeft:"10px"
    },
    exceedText: {
        fontFamily: 'DM Sans',
        fontSize: '12px',
        color: '#DC2626',
        fontWeight: 400
    },
    menuStyle: {
        borderRadius: "8px",
    },
    editPhotoIcon: {
        width: "18px",
        height: "18px",
        marginRight: "8px"
    },
    viewDialog: {
        borderRadius: '24px',
        border: '0px solid #D0D2DA',
        maxHeight: "calc(100% - 80px)",
        width: "33%",
        maxWidth: "unset",
        backgroundColor: "#fff"
    } as React.CSSProperties,
    textTitle: {
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "33.6px",
        padding: "24px 32px",
        borderBottom: '1px solid #F6F5F9',
        backgroundColor: "#fff",
        position: "sticky"
    } as React.CSSProperties,
    browseButton: {
        color: "#684EF3",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px"
    },
    uploadContainer: {
        backgroundColor: "#EAE6FF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: "2px dashed #9B93F6",
        borderRadius: "136px",
        height:'156px',
        width:"156px",
        marginLeft:"35%",
        gap: "8px",
    } as React.CSSProperties,
    editPhoto: {
        backgroundColor: "transparent",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: "2px solid #9B93F6",
        borderRadius: "136px",
        height:'156px',
        width:"156px",
        gap: "4px",
    } as React.CSSProperties,
    textEditPhoto: {
        color: "#fff",
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "21px",
    },
    uploadedContainer: {
        backgroundColor: "#EAE6FF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: "2px solid #9B93F6",
        borderRadius: "136px",
        height:'156px',
        width:"156px",
        marginLeft:"35%",
        gap: "8px",
        position: "relative"
    } as React.CSSProperties,
    dragAndDropText: {
        color: "#684EF3",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "21px",
        cursor: "pointer"
    },
    img: {
        width: "20px",
        height: "16px",
        top:'4px',
        left:"2px",
        cursor: "pointer"
    },
    uploadedimg: {
        width: "156px",
        height: "156px",
        cursor: "pointer",
        borderRadius:"136px"
    },
    viewInput: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        padding: "0 32px",
        marginBottom: "20px",
        "& .MuiFormHelperText-root": {
            color: "red !important"
        }
    } as React.CSSProperties,
    titleTextInput: {
        fontFamily: "DM Sans",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "21px",
        color: "#171B21"
    },
    optionalTextInput: {
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "21px",
        color: "#5E6671"
    },
    inputFeild: {
       borderRadius:"8px",
       with:"100%",
       height:"44px",
       "& .MuiFormHelperText-root": {
        color: "red"
       }
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        gap: "16px",
        padding: "0 32px 16px"
    } as React.CSSProperties,
    okButton: {
        borderRadius: "8px",
        color: "#fff",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        paddingTop: "10px",
        paddingBottom: "10px",
        textAlign: "center",
        width: "100%",
        cursor: "pointer"
    } as React.CSSProperties,
    cancelButton: {
        width: "50%"
    } as React.CSSProperties,
}

export default UserProfileDialog;
// Customizable Area End 