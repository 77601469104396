import React from "react";

// Customizable Area Start
import { Container, TextField, Button, Typography, Box, IconButton, InputAdornment, Snackbar, SnackbarContent } from '@material-ui/core';
import {  StyleSheet } from "react-native";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { logoText, backgroundImage,imgVisbility,imgVisbilityOff } from './assets';
const theme = createTheme();
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <div style={{
        background: `url(${backgroundImage}) no-repeat center center`,
        backgroundSize: 'cover',
        width: '100%',
        height: '1000px',
        paddingBottom: '52px'
      }}>
      <div style={{ paddingTop: '48px', paddingLeft: '80px' }}>
        <img style={{ width: '141.05px', height: '28px', gap: '2.8px ' }} src={logoText} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <ThemeProvider theme={theme}>
          <Container maxWidth="sm" style={{ margin: '62px 0 100px 0' }}>
            <Box style={{  height: '620px', width: '464px' }} display="flex" flexDirection="column" alignItems="center"  >
              <Typography data-test-id="welcomeText" variant="h4" gutterBottom style={{ color: 'rgba(20, 16, 30, 1)',fontFamily: 'DM Sans', fontWeight: 500, marginBottom: '40px' }}>Welcome back!</Typography>
              <Box style={{ width: '100%' }}>
                  <Typography style={{ fontFamily: 'DM Sans', fontWeight: 700, fontSize: '14px', color: 'rgba(48, 53, 59, 1)' }}>Email</Typography>
                  <TextField
                    style={{
                      borderRadius: '8px',
                      border: '1px  #E2E8F0',
                      backgroundColor: 'rgba(255, 255, 255, 1)',

                    }}
                    data-test-id="txtInputEmail"
                    placeholder="Enter your email"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    variant="outlined" fullWidth  required
                    error={this.state.emailError}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">{this.state.emailError && this.state.email ? null :(<CheckIcon  style={{color:"#684EF3", width: "45px"}} />) }
                        </InputAdornment>
                      ),
                    }}
                  />
                 {this.state.emailError && this.state.email ? (
          <Box sx={{ marginTop:"5px",color: 'red', fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 400 }}>
          
            <Typography style={{ fontFamily: 'DM Sans', fontSize: '12px'}}>Please enter a valid email address</Typography>
          </Box>
        ) : null}

                </Box>

                <Box style={{ width: '100%' }}>
  <Typography style={{ marginTop:'32px', fontFamily: 'DM Sans', fontWeight: 700, fontSize: '14px', color: 'rgba(48, 53, 59, 1)' }}>Password</Typography>
  <TextField
  style={{
    borderRadius: '8px',
    border: '1px solid #E2E8F0',
    backgroundColor: 'rgba(255, 255, 255, 1)',  
  }}
  name='password'
  placeholder="Enter your password"
  data-test-id="txtInputPassword"
  value={this.state.password}
  type={this.state.enablePasswordField ? 'password' : 'text'}
  onChange={this.handleChange}
  variant="outlined" 
  fullWidth 
  required
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <IconButton data-test-id="icnShowPassword" onClick={this.handleClickShowPassword}>
          <img 
            src={this.state.enablePasswordField ? imgVisbilityOff : imgVisbility} 
            alt="toggle visibility" 
            style={{ width: 24, height: 24 }} // adjust size as needed
          />
        </IconButton>
      </InputAdornment>
    ),
  }}
/>

  {this.state.passwordError && this.state.password?
    <Box sx={{ color: 'red', fontSize: '12px', fontFamily: 'DM Sans', fontWeight: 400 }}> <Typography style={{ fontFamily: 'DM Sans !important', fontSize: '12px'}}>Incorrect Password</Typography></Box> :
    null}
  <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '18px' }}>
    <Typography 
      data-test-id="btnForgotPassword"
      style={{ fontFamily: 'DM Sans', fontWeight: 400, fontSize: '16px', color: '#684EF3', cursor: 'pointer' }} 
      onClick={this.goToForgotPassword}
    >
      Forgot password?
    </Typography>
  </Box>
</Box>

                  <div style={{width:"100%",marginTop:"50px"}}>
                  <Button
                    data-test-id="btnEmailLogIn"
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ color: 'rgba(255, 255, 255, 1)',  backgroundColor: this.state.isButtonDisabled ? '#94A3B8' : '#684EF3', fontSize: '16px', fontWeight: 400, borderRadius: '8px', gap: '8px', padding: '16px' ,textTransform: 'none' }}
                    onClick={this.handleLogin}
                    disabled={this.state.isButtonDisabled}
                  >
                    Log in
                  </Button>
                  <div style={{ display: 'flex', alignItems: 'center', margin: '16px 0' }}>
                    <hr style={{ flex: 1, border: '1px solid #D6DEEA' }} />
                    <Typography style={{  padding: '0 8px' , fontFamily: 'DM Sans', fontWeight: 700, fontSize: '14px', color: 'rgba(48, 53, 59, 1)' }}>Or</Typography>
                    <hr style={{ flex: 1, border: '1px solid #D6DEEA' }} />
                  </div>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    disabled={!this.state.isSsoButtonDisabled}
                    onClick={this.handleNavigateToSSo}
                    style={{ color: '#684EF3', borderColor: '#684EF3', fontSize: '16px', fontWeight: 400, borderRadius: '8px', gap: '8px', padding: '16px',textTransform: 'none'  }}
                  >
                    Log in with SSO
                  </Button>
                  </div>
                  <Box style={{ marginTop: '180px' }}>
                  <Typography variant="body2" style={{ fontFamily: 'Dm Sans !important', fontSize: '16px', fontWeight: 400 }} > Don't have an account?
                    <span data-test-id="btnSignUp" style={{ left:"10px", textDecoration: 'none', color: 'rgba(104, 78, 243, 1)',cursor:"pointer" }} onClick={this.goToSignUp} >  Sign up</span>
                  </Typography>
                </Box>
              </Box>
              <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"center" }}
          open={this.state.isLoginSuccess}
          onClose={this.handleClose}
          key={"top" + "center"}
        >
          <SnackbarContent
            message="Login SuccessFull"
            style={{ backgroundColor: '#33BC55',borderRadius:"8px" }}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Snackbar>
            </Container>
          </ThemeProvider>
        </div>
      </div>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
