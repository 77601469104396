// Customizable Area Start
import React, { ReactNode } from "react";
import MemberTabController, { configJSON, listStatus } from "./MemberTabController.web";
import { Avatar, Button, FormControl, IconButton, InputAdornment, Menu, MenuItem, Snackbar, SnackbarContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import { SelectComponent } from "./WorkspaceList.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@material-ui/icons/Clear';
import moment from "moment";
import AddMemberDialog from "./AddMemberDialog.web";
import ConfirmationDialog from "./ConfirmationDialog.web";
import { WorkspaceMember } from "./WorkspaceListController.web";
import ViewProfileDrawer from "../../../blocks/user-profile-basic/src/ViewProfileDrawer.web";
import CommonAvtar from "../../../components/src/common/CommonAvtar.web";

export class MemberTab extends MemberTabController {
    renderInputSearch = () => {
        return (
            <TextField
                className={this.props.classes?.inputSearch}
                data-test-id="inputSearch"
                value={this.state.inputSearch}
                onChange={this.onChangeInputSearch}
                placeholder={configJSON.search}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 16.147C9.77498 16.1466 11.4988 15.5524 12.897 14.459L17.293 18.855L18.707 17.441L14.311 13.045C15.405 11.6466 15.9996 9.92241 16 8.14697C16 3.73597 12.411 0.146973 8 0.146973C3.589 0.146973 0 3.73597 0 8.14697C0 12.558 3.589 16.147 8 16.147ZM8 2.14697C11.309 2.14697 14 4.83797 14 8.14697C14 11.456 11.309 14.147 8 14.147C4.691 14.147 2 11.456 2 8.14697C2 4.83797 4.691 2.14697 8 2.14697Z" fill="#94A3B8" />
                            </svg>
                        </InputAdornment>
                    ),
                    disableUnderline: true,
                    className: this.props.classes?.inputSearchStyle
                }}
            />
        )
    }

    renderPicker = (
        currentValue: string,
        onClearValue: () => void,
        listItem: Array<any>,
        onChangeItem: (value: string) => void,
        placeholder: string
    ) => {
        return (
            <FormControl variant="filled">
                <SelectComponent
                    id="demo-simple-select-filled"
                    data-test-id={`selectelement_${placeholder}`}
                    value={currentValue}
                    className={this.props.classes?.customdropdown}
                    IconComponent={() => <div />}
                    endAdornment={(
                        <InputAdornment position="end" className={this.props.classes?.viewIconDropdown}>
                            {
                                currentValue !== "" && (
                                    <IconButton onClick={onClearValue}>
                                        <ClearIcon style={styles.iconExpand} />
                                    </IconButton>
                                )
                            }
                            <ExpandMoreIcon style={styles.iconExpand} />
                        </InputAdornment>
                    )}
                    displayEmpty={true}
                    renderValue={value => (currentValue !== "" ? value : placeholder) as ReactNode}
                    style={{ color: this.getColorText(currentValue === "") }}
                    MenuProps={{
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                    }}
                >
                    {listItem.map((item) =>
                        <MenuItem
                            className={this.props.classes?.dropdownitem}
                            key={item.team_name}
                            value={item.team_name}
                            data-test-id={`item_${item.team_name}`}
                            onClick={() => onChangeItem(item.team_name)}
                        >
                            {item.team_name}
                        </MenuItem>
                    )}
                </SelectComponent>
            </FormControl>
        )
    }

    getColorText = (isPlaceholder: boolean) => {
        if (isPlaceholder) return "#94A3B8"
        else return "#30353B"
    }

    getStatusColor = (status: string) => {
        if (status === "Accepted") {
            return {
                color: "#059669",
                backgroundColor: "#D1FAE5"
            }
        } else if (status === "Pending") {
            return {
                color: "#D97706",
                backgroundColor: "#FEF3C7"
            }
        } else {
            return {
                color: "#DC2626",
                backgroundColor: "#FEE2E2"
            }
        }
    }

    renderStatusCell = (status: string) => {
        return (
            <div style={{ ...styles.statusCell, ...{
                color: this.getStatusColor(status).color,
                backgroundColor: this.getStatusColor(status).backgroundColor
            }}}>
                {status}
            </div>
        )
    }

    renderIconTable = (user: WorkspaceMember) => {
        if (this.state.isAdmin) {
            return (
                <div>
                    <Button
                        data-test-id={`open-menu-${user.id}`}
                        onClick={(event) => this.openMenu(event, user)}
                    >
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.0007 10.4803C11.084 10.4803 10.334 11.2303 10.334 12.147C10.334 13.0637 11.084 13.8137 12.0007 13.8137C12.9173 13.8137 13.6673 13.0637 13.6673 12.147C13.6673 11.2303 12.9173 10.4803 12.0007 10.4803ZM12.0007 5.48035C11.084 5.48035 10.334 6.23035 10.334 7.14701C10.334 8.06368 11.084 8.81368 12.0007 8.81368C12.9173 8.81368 13.6673 8.06368 13.6673 7.14701C13.6673 6.23035 12.9173 5.48035 12.0007 5.48035ZM12.0007 15.4803C11.084 15.4803 10.334 16.2303 10.334 17.147C10.334 18.0637 11.084 18.8137 12.0007 18.8137C12.9173 18.8137 13.6673 18.0637 13.6673 17.147C13.6673 16.2303 12.9173 15.4803 12.0007 15.4803Z" fill="#14101E"/>
                        </svg>
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={this.state.anchorEl}
                        keepMounted
                        open={Boolean(this.state.anchorEl)}
                        onClose={this.closeMenu}
                        PaperProps={{
                            style: styles.menuStyle
                        }}
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                    >
                        <MenuItem data-test-id="view-profile" onClick={this.onViewProfile}>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={styles.iconMenu}>
                                <path d="M12 3.81396C11.1759 3.81396 10.3703 4.05834 9.68512 4.51617C8.99992 4.97401 8.46587 5.62476 8.1505 6.38612C7.83514 7.14748 7.75262 7.98525 7.91339 8.79351C8.07417 9.60176 8.471 10.3442 9.05372 10.9269C9.63644 11.5096 10.3789 11.9065 11.1871 12.0672C11.9954 12.228 12.8332 12.1455 13.5945 11.8301C14.3559 11.5148 15.0066 10.9807 15.4645 10.2955C15.9223 9.6103 16.1667 8.80472 16.1667 7.98063C16.1667 6.87556 15.7277 5.81575 14.9463 5.03435C14.1649 4.25295 13.1051 3.81396 12 3.81396ZM12 10.4806C11.5055 10.4806 11.0222 10.334 10.6111 10.0593C10.2 9.7846 9.87952 9.39416 9.6903 8.93734C9.50108 8.48052 9.45157 7.97786 9.54804 7.49291C9.6445 7.00795 9.8826 6.5625 10.2322 6.21286C10.5819 5.86323 11.0273 5.62513 11.5123 5.52867C11.9972 5.43221 12.4999 5.48171 12.9567 5.67093C13.4135 5.86015 13.804 6.18058 14.0787 6.59171C14.3534 7.00283 14.5 7.48618 14.5 7.98063C14.5 8.64367 14.2366 9.27956 13.7678 9.7484C13.2989 10.2172 12.663 10.4806 12 10.4806ZM19.5 19.6473V18.814C19.5 17.2669 18.8854 15.7831 17.7915 14.6892C16.6975 13.5952 15.2138 12.9806 13.6667 12.9806H10.3333C8.78624 12.9806 7.30251 13.5952 6.20854 14.6892C5.11458 15.7831 4.5 17.2669 4.5 18.814V19.6473H6.16667V18.814C6.16667 17.7089 6.60565 16.6491 7.38705 15.8677C8.16846 15.0863 9.22826 14.6473 10.3333 14.6473H13.6667C14.7717 14.6473 15.8315 15.0863 16.6129 15.8677C17.3943 16.6491 17.8333 17.7089 17.8333 18.814V19.6473H19.5Z" fill="#5E6671"/>
                            </svg>
                            {configJSON.viewProfileText}
                        </MenuItem>
                        <MenuItem data-test-id="removeMember" onClick={this.onOpenRemoveDialog}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={styles.iconMenu}>
                                <path d="M3.75 15C3.75 15.3978 3.90804 15.7794 4.18934 16.0607C4.47064 16.342 4.85218 16.5 5.25 16.5H12.75C13.1478 16.5 13.5294 16.342 13.8107 16.0607C14.092 15.7794 14.25 15.3978 14.25 15V6H15.75V4.5H12.75V3C12.75 2.60218 12.592 2.22064 12.3107 1.93934C12.0294 1.65804 11.6478 1.5 11.25 1.5H6.75C6.35218 1.5 5.97064 1.65804 5.68934 1.93934C5.40804 2.22064 5.25 2.60218 5.25 3V4.5H2.25V6H3.75V15ZM6.75 3H11.25V4.5H6.75V3ZM12.75 6V15H5.25V6H12.75Z" fill="#DC2626"/>
                                <path d="M6.75 7.5H8.25V13.5H6.75V7.5ZM9.75 7.5H11.25V13.5H9.75V7.5Z" fill="#DC2626"/>
                            </svg>
                            {configJSON.okButtonRemoveMemberText}
                        </MenuItem>
                    </Menu>
                </div>
            )
        } else {
            return (
                <div
                    data-test-id={`view-user-profile-${user.account_details?.data.id}`}
                    onClick={() => this.handleViewOtherMemberProfile(user)}
                >
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 3.81396C11.1759 3.81396 10.3703 4.05834 9.68512 4.51617C8.99992 4.97401 8.46587 5.62476 8.1505 6.38612C7.83514 7.14748 7.75262 7.98525 7.91339 8.79351C8.07417 9.60176 8.471 10.3442 9.05372 10.9269C9.63644 11.5096 10.3789 11.9065 11.1871 12.0672C11.9954 12.228 12.8332 12.1455 13.5945 11.8301C14.3559 11.5148 15.0066 10.9807 15.4645 10.2955C15.9223 9.6103 16.1667 8.80472 16.1667 7.98063C16.1667 6.87556 15.7277 5.81575 14.9463 5.03435C14.1649 4.25295 13.1051 3.81396 12 3.81396ZM12 10.4806C11.5055 10.4806 11.0222 10.334 10.6111 10.0593C10.2 9.7846 9.87952 9.39416 9.6903 8.93734C9.50108 8.48052 9.45157 7.97786 9.54804 7.49291C9.6445 7.00795 9.8826 6.5625 10.2322 6.21286C10.5819 5.86323 11.0273 5.62513 11.5123 5.52867C11.9972 5.43221 12.4999 5.48171 12.9567 5.67093C13.4135 5.86015 13.804 6.18058 14.0787 6.59171C14.3534 7.00283 14.5 7.48618 14.5 7.98063C14.5 8.64367 14.2366 9.27956 13.7678 9.7484C13.2989 10.2172 12.663 10.4806 12 10.4806ZM19.5 19.6473V18.814C19.5 17.2669 18.8854 15.7831 17.7915 14.6892C16.6975 13.5952 15.2138 12.9806 13.6667 12.9806H10.3333C8.78624 12.9806 7.30251 13.5952 6.20854 14.6892C5.11458 15.7831 4.5 17.2669 4.5 18.814V19.6473H6.16667V18.814C6.16667 17.7089 6.60565 16.6491 7.38705 15.8677C8.16846 15.0863 9.22826 14.6473 10.3333 14.6473H13.6667C14.7717 14.6473 15.8315 15.0863 16.6129 15.8677C17.3943 16.6491 17.8333 17.7089 17.8333 18.814V19.6473H19.5Z" fill="#5E6671"/>
                    </svg>
                </div>
            )
        }
    }

    render() {
        return (
            <div style={styles.container}>
                <div style={styles.headerViewStyle}>
                    <div style={styles.headerViewFilterStyle}>
                        {this.renderInputSearch()}
                        {this.renderPicker(
                            this.state.teamFilter,
                            this.onClearTeamFilter,
                            this.state.listTeam,
                            this.onChangeTeamFilter,
                            configJSON.team
                        )}
                        {this.renderPicker(
                            this.state.statusFilter,
                            this.onClearStatusFilter,
                            listStatus,
                            this.onChangeStatusFilter,
                            configJSON.status
                        )}
                    </div>
                    {
                        this.state.isAdmin 
                        ? ( 
                            <div style={styles.buttonAddMember} data-test-id="addButton" onClick={this.onOpenAddModal}>
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 11.147H13V5.14697H11V11.147H5V13.147H11V19.147H13V13.147H19V11.147Z" fill="white"/>
                                </svg>
                                {configJSON.addMembers}
                            </div>
                        ) : <div />
                    }
                </div>
                <TableContainer className={this.props.classes?.viewTable}>
                    <Table>
                        <TableHead>
                            <TableRow className={this.props.classes?.tableRowHeader}>
                                <TableCell style={styles.headerText}>
                                    {`${this.state.listUser?.length} ${configJSON.memberTabTitle}`}
                                </TableCell>
                                <TableCell style={styles.headerText}>
                                    {configJSON.addingDate}
                                </TableCell>
                                <TableCell style={styles.headerText}>
                                    {configJSON.email}
                                </TableCell>
                                <TableCell style={styles.headerText}>
                                    {configJSON.team}
                                </TableCell>
                                <TableCell style={styles.headerText}>
                                    {configJSON.status}
                                </TableCell>
                                <TableCell style={styles.headerText}>
                                    {configJSON.permission}
                                </TableCell>
                                <TableCell style={styles.headerText}>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={this.props.classes?.tableBody}>
                            {this.state.listUser.map((user, index) => {
                                const first_name = user.account_details?.data.attributes?.first_name;
                                const last_name = user.account_details?.data.attributes?.last_name;
                                const profile_photo = user.account_details?.data.attributes?.profile_photo;
                                const email = user.account_details?.data.attributes?.email;
                                return (
                                    <TableRow
                                        key={user.id}
                                        className={this.props.classes?.tableRow}
                                        style={{ borderBottomWidth: index === (this.props.listUser?.length ?? 0 - 1) ? 0 : 1 }}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                            <div style={styles.viewNameTable}>
                                                <CommonAvtar src={profile_photo} alt={first_name} style={styles.ava} />
                                                {`${first_name || "---"} ${last_name || "---"}`}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                            {moment(user.adding_date).format("MMM DD, YYYY")}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                            {email}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                            <div style={styles.infoCell}>{user?.team_name ?? ""}</div>
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                            {this.renderStatusCell(user?.status ?? "Pending")}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                            <div style={styles.infoCell}>{user?.permission ?? ""}</div>
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.cellIcon}
                                        >
                                            {this.renderIconTable(user)}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <AddMemberDialog 
                    id="AddMemberDialog"
                    isDialogOpen={this.state.isAddModalOpen}
                    onCloseDialog={this.onCloseDialog}
                    classes={this.props.classes}
                    workspaceId={this.props.workspaceId}
                    actionType="add"
                />
                <ConfirmationDialog
                    id="ConfirmationDialog"
                    isDialogOpen={this.state.isOpenRemoveDialog}
                    onCloseDialog={this.onCloseRemoveDialog}
                    title={configJSON.deleteHeaderText}
                    description={configJSON.removeMemberDescriptionText}
                    titleCancelButton={configJSON.textClose}
                    titleOkButton={configJSON.okButtonRemoveMemberText}
                    onOkCloseDialog={this.onOkCloseDialog}
                    checkConfirmationTitle="I confirm I want to delete member"
                />
                {this.state.isOpenViewDialog && (
                    <ViewProfileDrawer
                        id="ViewProfileDrawer"
                        isDialogOpen={this.state.isOpenViewDialog}
                        onCloseDialog={this.onCloseViewDialog}
                        classes={this.props?.classes}
                        account_id={this.state.currentChosenMember?.account_details.data.id}
                    />
                    )}
                 <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"center" }}
          open={this.state.isSnackbarOpen}
          onClose={this.handleClose}
          key={"top" + "center"}
        >
          <SnackbarContent
            message={this.state.snackbarContent}
            style={{ backgroundColor: '#33BC55',borderRadius:"8px" }}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Snackbar>
       
            </div>
        );
    }
}

const styles = {
    container: {
        padding: "20px 36px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        overflowY: "auto",
        overflowX: "hidden"
    } as React.CSSProperties,
    menuStyle: {
        borderRadius: "8px",
    },
    iconMenu: {
        width: "18px",
        height: "18px",
        marginRight: "8px"
    },
    headerViewStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    } as React.CSSProperties,
    headerViewFilterStyle: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
    } as React.CSSProperties,
    buttonAddMember: {
        cursor: "pointer",
        display: 'flex',
        alignItems: "center",
        gap: "8px",
        borderRadius: "8px",
        padding: "10px 16px",
        backgroundColor: "#684EF3",
        color: "#fff",
        fontFamily: 'DM Sans',
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
    } as React.CSSProperties,
    iconExpand: {
        height: "16px",
        width: "16px",
        color: "#30353B",
        cursor: "pointer"
    },
    headerText: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "21px",
        color: "#475569"
    },
    contentText: {
        color: "#0F172A",
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        padding: "10px",
        display: "table-cell",
        verticalAlign: "middle"
    } as React.CSSProperties,
    ava: {
        width: "44px",
        height: "44px",
        borderRadius: "44px",
        marginRight: "16px"
    },
    viewNameTable: {
        display: "flex",
        alignItems: "center"
    } as React.CSSProperties,
    viewAlert: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        padding: "8px 8px 8px 2px",
        borderRadius: "16px",
        color: "#DC2626",
        backgroundColor: "#FEE2E2"
    },
    cellIcon: {
        display: "flex",
        alignItems: "flex-end",
        borderBottom: "0px solid #fff"
    } as React.CSSProperties,
    infoCell: {
        padding: "2px 8px",
        color: "#334155",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        width: "fit-content",
        backgroundColor: "#F6F5F9",
        borderRadius: "16px",
    } as React.CSSProperties,
    statusCell: {
        padding: "2px 8px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        width: "fit-content",
        borderRadius: "16px",
    } as React.CSSProperties,
};

export default MemberTab;
// Customizable Area End
