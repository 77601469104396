// Customizable Area Start
import React from "react";
import { Box, FormControlLabel, Typography, withStyles } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import { WorkspaceNoti } from "../../../packages/blocks/user-profile-basic/src/UserProfileBasicController.web";
import { StyledSwitch } from "./common/StyledSwitch.web";

interface myProps {
    item: WorkspaceNoti
}

const checkBoxStyles = () => ({
    root: {
      '&$checked': {
        color: '#684EF3',
      },
    },
    checked: {},
})

export const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);
export const ItemNotiWorkspaceSetting = ({ item }: myProps) => {
    const [showSetting, setShowSetting] = React.useState(true);

    const handleToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowSetting(event.target.checked)
    };

    return (
        <div style={webStyles.containerItem}>
            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Box>
                    <Typography style={webStyles.titleField}>{item.name}</Typography>
                    <Typography style={webStyles.descriptionField}> Get notified about LT group workspace activity</Typography>
                </Box>

                <Box>
                    <StyledSwitch checked={showSetting} onChange={handleToggle} />
                </Box>
            </Box>
            {
                showSetting && (
                    <Box style={webStyles.viewCheckbox}>
                        <Box style={{ display: "flex", justifyContent: "center", flexDirection: 'column' }}>
                            <FormControlLabel
                                value="end"
                                control={<CustomCheckbox  />}
                                label="Chat messages"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="end"
                                control={<CustomCheckbox  />}
                                label="File Uploads"
                                labelPlacement="end"
                            />
                        </Box>
                        <Box style={{ display: "flex", justifyContent: "center", flexDirection: 'column' }}>
                            <FormControlLabel
                                value="end"
                                control={<CustomCheckbox  />}
                                label="Mentions"
                                labelPlacement="end"
                            />
                             <FormControlLabel
                                value="end"
                                control={<CustomCheckbox  />}
                                label="Property Changes"
                                labelPlacement="end"
                            />
                        </Box>
                        <Box style={{ display: "flex", flexDirection: 'column' }}>
                            <FormControlLabel
                                value="end"
                                control={<CustomCheckbox  />}
                                label="Invites"
                                labelPlacement="end"
                            />
                        </Box>
                    </Box>
                )
            }
        </div>
    )
}

const webStyles = {
    containerItem: {
        paddingLeft: "16px",
        width: "calc(100% - 16px)",
        display: "flex",
        flexDirection: "column",
    } as React.CSSProperties,
    titleField: {
        color: "#30353B",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 700,
    },
    descriptionField: {
        color: "#5E6671",
        fontFamily: "DM Sans",
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 400,
    },
    viewCheckbox: {
        display: 'flex',
        justifyContent: "space-between",
        width: "80%",
        marginTop: "16px",
    } as React.CSSProperties,
}
// Customizable Area End
