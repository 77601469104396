import React, { ReactNode } from "react";
import { Box, styled, Typography } from "@material-ui/core";
import Dropzone from "react-dropzone";
import { uploadSvgIcon } from "./assets";
import { colors } from "../../../blocks/utilities/src/Colors";

interface Props {
  onDrag: (files: File[]) => void;
  dragBoxPara: ReactNode | String;
}

const CommonDropZone = (props: Props) => {
  const { onDrag, dragBoxPara } = props;
  return (
    <StyledDropZone>
      <Dropzone onDrop={onDrag}>
        {({ getRootProps, getInputProps }) => (
          <section className="container">
            <Box {...getRootProps({ className: "dropzone" })} data-test-id="dropzone">
              <input {...getInputProps()} />
              <Box className="uploadContainer">
                <img src={uploadSvgIcon} className="img" />
                <Typography className="dragAndDropText">
                  {dragBoxPara}
                </Typography>
                <Box data-test-id="uploadFile" className="browseButton">
                  Browse
                </Box>
              </Box>
            </Box>
          </section>
        )}
      </Dropzone>
    </StyledDropZone>
  );
};

const StyledDropZone = styled(Box)({
  "& .uploadContainer": {
    backgroundColor: colors().darkPurple,
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: `2px dashed ${colors().bluePurple}`,
    borderRadius: "16px",
    gap: "8px",
    "& .dragAndDropText": {
      color: colors().darkText,
      fontFamily: "DM Sans",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "21px",
      margin: "8px 0",
    },
    "& .browseButton": {
      color: "#684EF3",
      fontFamily: "DM Sans",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "24px",
      cursor: "pointer",
    },
  },
});

export default CommonDropZone;
