import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { createRef } from "react";
import * as Yup from "yup";
import { businessAttachment } from "./assets";
import {
  makeApiMessage,
} from "../../utilities/src/commonfunctions";
import { handleResponseMessage } from "../../utilities/src/handle-response-message";
import { ChatItem, CreateChatApiResponse, FetchConversationResponse, GroupChatErrorResponse, SearchSuccessResponse } from "./components/types";
import { removeStorageData } from "../../../framework/src/Utilities";
import { ChatType } from "../../utilities/src/constant";

interface Member {
  userId: string;
  userName: string;
  email: string;
  profilePic: string;
}

interface ICreateChatFormValues {
  members: Member[];
  groupName: string;
}
interface IMessage {
  messageId: number;
  sender: string;
  text: string;
  timestamp: string;
  attachment?: string[];
  replyTo?: IMessage;
}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  chatList: ChatItem[];
  searchQuery: string;
  openCreateChat: boolean;
  openChatSearch: boolean;
  isEditOpen: boolean;
  messages: IMessage[];
  anchorEl: HTMLButtonElement | null;
  selectedFiles: File[];
  searchOptions: {
    userId: string;
    userName: string;
    email: string;
    profilePic: string;
  }[];
  isSearchLoading: boolean;
  openProfileDrawer: boolean;
  isCreateChatCreating: boolean;
  isSnackbarOpen: boolean;
  isGetListingLoading: boolean;
  snackbarContent: string;
  snackbarSeverity: "success" | "error" | "warning";
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatApiCallId: string = "";
  searchUserByKeywordApiCallId: string = "";
  fileInputRef: React.RefObject<HTMLInputElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      chatList: [],
      searchQuery: "",
      openCreateChat: false,
      openChatSearch: false,
      isEditOpen: true,
      messages: [
        {
          messageId: 1,
          sender: "Cody Fisher",
          text: "Let me know if there's anything that needs tweaking.",
          timestamp: "2025-02-23T12:35:00.000Z",
        },
        {
          messageId: 2,
          sender: "You",
          text:
            "Thanks, Cody. I'll dive into the details and get back to you if I have any questions.",
          timestamp: "2025-02-23T12:36:00.000Z",
          replyTo: {
            messageId: 1,
            sender: "Cody Fisher",
            text: "Let me know if there's anything that needs tweaking.",
            timestamp: "2025-02-23T12:35:00.000Z",
          },
        },
        {
          messageId: 3,
          sender: "Cody Fisher",
          text: "Here are the documents.",
          timestamp: "2025-02-24T10:00:00.000Z",
          attachment: [
            businessAttachment,
            businessAttachment,
            businessAttachment,
            businessAttachment,
            businessAttachment,
          ],
        },
        {
          messageId: 4,
          sender: "You",
          text: "Got it, thanks!",
          timestamp: "2025-02-24T10:05:00.000Z",
          attachment: [
            businessAttachment,
            businessAttachment,
            businessAttachment,
            businessAttachment,
            businessAttachment,
          ],
        },
      ],
      anchorEl: null,
      selectedFiles: [],
      searchOptions: [],
      isSearchLoading: false,
      openProfileDrawer: false,
      isCreateChatCreating: false,
      isSnackbarOpen: false,
      isGetListingLoading: true,
      snackbarContent: "",
      snackbarSeverity: "success",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.fileInputRef = createRef<HTMLInputElement>();
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    const apiCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (
      responseJson?.errors?.[0].token === "Token has Expired" ||
      responseJson?.errors?.[0].token === "Invalid token"
    ) {
      removeStorageData("authToken");
      removeStorageData("account_id");
      this.props.navigation.navigate("EmailAccountLoginBlock");
    }

    if (apiCallId && responseJson) {
      if (apiCallId === this.searchUserByKeywordApiCallId) {
        handleResponseMessage({
          responseJson: responseJson,
          errorJson: errorResponse,
          onSuccess: () => {
            const results = responseJson as SearchSuccessResponse;

            if(results.meta.count) {
              const preparedList = 
                results.data.map(user => ({
                  userId: user.id,
                  userName: user.attributes.first_name?.concat(" ", user.attributes.last_name || "").trim() || "",
                  email: user.attributes.email,
                  profilePic: user.attributes.profile_photo || ""           
                }))
              this.setState({ searchOptions: preparedList, isSearchLoading: false })
            } else {
              this.setState({
                searchOptions: [],
                isSearchLoading: false,
              });
            }
          },
          onFail: () => {
            this.setState({
              searchOptions: [],
              isSearchLoading: false,
            });
          },
        });
      }
      if (apiCallId === this.createChatApiCallId) {
        handleResponseMessage({
          responseJson: responseJson,
          errorJson: errorResponse,
          onSuccess: () => {

            this.handleCreateChatSuccessResponse(responseJson as CreateChatApiResponse);

          },
          onFail: () => {
            this.setState({
              openCreateChat: false,
              isSnackbarOpen: true,
              snackbarContent: (responseJson as GroupChatErrorResponse).errors[0].message,
              snackbarSeverity: "error",
              isCreateChatCreating: false,
            });
          },
        });
      }
      if(apiCallId === this.getChatListApiCallId) {
        handleResponseMessage({
          responseJson: responseJson,
          errorJson: errorResponse,
          onSuccess: () => {

            const results: ChatItem[]  = (responseJson as FetchConversationResponse).conversations.map((chat) => {
              return {
                chatSid: chat.conversation_sid,
                chatType: chat.chat_type,
                profilePic: chat.chat_type === ChatType.Private ? chat.receipient_data.data[0].attributes.accounts.profile_photo : "",
                title: chat.chat_type === ChatType.Private ? (chat.receipient_data.data[0].attributes.accounts.first_name.concat(" ", chat.receipient_data.data[0].attributes.accounts.last_name).trim()) : chat.group_name,
                lastMessage: chat.last_message,
                lastMessageTime: chat.last_message_time
              } as ChatItem
            })

            this.setState({ isGetListingLoading: false, chatList: results }, () => console.log(this.state.chatList))
          },
          onFail: () => {
            this.setState({ isGetListingLoading: false })
          },
        });
      }
    }
  }

  handleCreateChatSuccessResponse = (responseJson: CreateChatApiResponse) => {
    let message = "";
    let toasterType: "success" | "error" = "success";
    if ("conversations" in responseJson) {
      if ("meta" in responseJson.conversations[0]) {
        message = responseJson.conversations[0].meta.message;
        toasterType = "error";
      } else {
        message = "Chat has been created"
        this.getChatListing();
        // response.conversations[0].participant.data.forEach((p) => {
        //   console.log(`Participant ID: ${p.id}, Account ID: ${p.attributes.account_id}`);
        // });
      }
    } else if ("data" in responseJson) {

      message = responseJson.data.message;
      // response.data.participant.data.forEach((p) => {
      //   console.log(`Participant: ${p.attributes.accounts?.first_name} ${p.attributes.accounts?.last_name}`);
      // });

      this.getChatListing();
    }
    this.setState({
      openCreateChat: false,
      isSnackbarOpen: true,
      snackbarContent: message,
      snackbarSeverity: toasterType,
      isCreateChatCreating: false,
    });

  }

  async componentDidMount() {
    this.getChatListing();
  }

  createChatValidationSchema = Yup.object().shape({
    members: Yup.array()
      .min(1, "At least one member is required.")
      .required("At least one member is required."),
    groupName: Yup.string().when("members", {
      is: (members) => members.length > 1,
      then: Yup.string().required(
        "Group name is required when more than one member is selected."
      ),
    }),
  });

  createChatInitialValues = {
    members: [] as Member[],
    groupName: "",
  } as ICreateChatFormValues;


  getChatPreviewName = (name: string): string => {
    const nameArr = name.trim().split(/\s+/);
    return nameArr.length > 1
      ? `${nameArr[0][0]}${nameArr[1][0]}`
      : nameArr[0][0];
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({ searchQuery: value }, () => this.getChatListing());
  };

  resetSearch = () => this.setState({ searchQuery: "" }, () => this.getChatListing());

  toggleCreateChat = () =>
    this.setState((prevState) => ({
      openCreateChat: !prevState.openCreateChat,
    }));

  toggleChatSearch = () =>
    this.setState((prevState) => ({
      openChatSearch: !prevState.openChatSearch,
    }));

  closeEditReply = () =>
    this.setState({ isEditOpen: false, selectedFiles: [] });

  openMessageMoreMonu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeMoreMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      this.setState((prevState) => ({
        selectedFiles: [
          ...prevState.selectedFiles,
          ...Array.from(event.target.files as FileList),
        ],
      }));
    }
  };

  searchUserByKeyword = async (query: string) => {
    this.setState({ isSearchLoading: true });

    const requestMessage = await makeApiMessage({
      url: (configJSON.searchUserApiEndPoint as string).concat(query),
      method: "GET",
    });

    this.searchUserByKeywordApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  toggleProfileDrawer = () => {
    this.setState({ openProfileDrawer: !this.state.openProfileDrawer });
  }

  getChatListing = async (page = 1, perPage = 100) => {
    this.setState({ isGetListingLoading: true });

    const queryParams = new URLSearchParams({
      search: this.state.searchQuery,
      page: page.toString(),
      perpage: perPage.toString(),
    });

    const apiurl = `${configJSON.chatApiEndPoint}?${queryParams.toString()}`;

    const requestMessage = await makeApiMessage({
      url: apiurl,
      method: "GET",
    });

    this.getChatListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createChat = async (values: ICreateChatFormValues) => {
    this.setState({ isCreateChatCreating: true });

    const isGroupChat = values.members.length > 1;

    const payload = {
      account_ids: values.members.map(member => member.userId),
      ...(isGroupChat && values.groupName ? { group_name: values.groupName } : {})
    };

    const requestMessage = await makeApiMessage({
      url: isGroupChat ? configJSON.groupChatEndPoint : configJSON.privateChatEndPoint,
      method: "POST",
      body: JSON.stringify(payload),
    });

    this.createChatApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  closeSnackBar = () => {
    this.setState({
      isSnackbarOpen: false,
      snackbarContent: "",
      snackbarSeverity: "success"
    })
  }
  // Customizable Area End
}
