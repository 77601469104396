import { SelectOption } from "./interface";

export enum UploadOptions {
  NoAction = "NO_ACTION",
  AIProcessing = "AI_PROCESSING",
  GreekLegalDocument = "GREEK_LEGAL_DOCUMENT",
}

export enum ChatType {
  Private,
  Group,
  Workspace
}

export const COMMON_DISPLY_TYPE = {
  ONLY_ME: "Only_me",
  EVERYONE: "Everyone"
}

export const uploadFileOptions: SelectOption[] = [
  {
    label: "Upload file with no action required",
    value: UploadOptions.NoAction,
  },
  {
    label: "Upload file with AI processing",
    value: UploadOptions.AIProcessing,
  },
  {
    label: "Upload Greek Document with AI processing",
    value: UploadOptions.GreekLegalDocument,
  },
];

export const emailRegex = /^[a-zA-Z0-9]+([._-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
