import React from "react";

// Customizable Area Start
import {
  Avatar,
  Badge,
  Box,
  DialogActions,
  DialogContent,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Tabs,
  Typography,
  styled,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import GroupIcon from "@material-ui/icons/Group";
import MuiTab from "@material-ui/core/Tab";
import {
  editMoreMenuIcon,
  deleteMoreMenuIcon,
  leaveChatIcon,
  viewProfileIcon,
  sendMessageIcon,
  businessAttachment,
} from "./assets";

// Customizable Area End

import ChatInfoController, { Props } from "./ChatInfoController";
import CommonButton from "../../../components/src/common/CommonButton.web";
import TableFileView from "../../../components/src/common/TableFileView.web";
import { getFileExtension } from "../../utilities/src/commonfunctions";
import moment from "moment";
import ConfirmationDialog from "../../accountgroups/src/ConfirmationDialog.web";
import CustomDialogWithAction from "../../../components/src/common/CustomDialogWithAction.web";
import { Form, Formik } from "formik";
import CustomInput from "../../../components/src/common/CustomInput.web";

export default class ChatInfo extends ChatInfoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderChatInfo = () => {
    return (
      <>
        <Box
          className="chat-wrapper"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" className="chat-info-title">
            Chat Info
          </Typography>
          <IconButton onClick={this.toggleProfileDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>
      </>
    );
  };

  renderChatProfileDetails = () => {
    const { isChatGroup } = this.state;
    return (
      <>
        <Box
          className="profile-details-wrapper"
          data-test-id="profile-details-wrapper"
        >
          <Badge
            overlap="circular"
            color="primary"
            style={{ color: "#684EF3" }}
            variant="dot"
          >
            <Avatar
              className="profile-pic"
              src="https://randomuser.me/api/portraits/men/1.jpg"
              alt="profile-pic"
            />
          </Badge>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              className="profile-pic-name-wrapper"
            >
              <Typography className="profile-name">Cody Fisher</Typography>
              {isChatGroup ? (
                <Typography className="group-detail">
                  <GroupIcon style={{ color: "#94A3B8" }} /> 9 members
                </Typography>
              ) : (
                <>
                  <Typography className="profile-detail">
                    Sales Agent at LT Global Group
                  </Typography>
                  <span style={{ marginTop: "10px", width: "fit-content" }}>
                    <CommonButton
                      onClick={() => {}}
                      label={"Profile info"}
                      dataTestId="profile-info"
                    />
                  </span>
                </>
              )}
            </Box>
            {isChatGroup && (
              <Box>
                <IconButton
                  data-test-id="group-more-menu"
                  onClick={this.handleMenuOpen}
                >
                  <MoreVertRoundedIcon style={{ color: "#30353B" }} />
                </IconButton>
              </Box>
            )}
          </Box>
          <StyledMenu
            data-test-id={`group-action-menu`}
            anchorEl={this.state.groupAnchorEl}
            className="styled-menu"
            keepMounted
            elevation={0}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            getContentAnchorEl={null}
            open={Boolean(this.state.groupAnchorEl)}
            onClose={this.handleMenuClose}
          >
            <MenuItem
              data-test-id="edit-group-name"
              className="menu-item"
              onClick={this.toggleEditGroupNameDialogue}
            >
              <span className="action-icon">
                <img src={editMoreMenuIcon} />
              </span>
              Edit chat name
            </MenuItem>
            <MenuItem
              data-test-id="delete-chat"
              onClick={this.toggleDeleteGroupDialogue}
            >
              <span className="action-icon">
                <img src={deleteMoreMenuIcon} />
              </span>
              <span className="delete-chat-action">Delete chat</span>
            </MenuItem>
            <MenuItem
              data-test-id="leave-chat"
              onClick={this.toggleLeaveChatDialogue}
            >
              <span className="action-icon">
                <img src={leaveChatIcon} />
              </span>
              <span className="leave-chat">Leave chat</span>
            </MenuItem>
          </StyledMenu>
        </Box>
      </>
    );
  };

  renderTabs = (tabs: string[]) => {
    const { activeTabIndex } = this.state;
    return (
      <>
        <Box
          className="member-media-file-wrapper"
          data-test-id="member-media-file-wrapper"
        >
          <StyledTab
            data-test-id="member-media-file-tabs"
            value={activeTabIndex}
            onChange={this.handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            className="tab"
            variant="fullWidth"
            TabIndicatorProps={{
              style: { display: "none" },
            }}
          >
            {tabs.map((tabLabel, index) => {
              return (
                <MuiTab
                  className={
                    activeTabIndex === index ? "activeTab" : "normalTab"
                  }
                  label={tabLabel}
                />
              );
            })}
          </StyledTab>
        </Box>
      </>
    );
  };

  renderMemberTab = () => {
    return (
      <>
        <Box className="member-wrapper" data-test-id="member-wrapper">
          {["Jane Cooper", "Leslie Alexander", "Soah Letterier"].map(
            (member, index) => {
              return (
                <>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    style={{
                      backgroundColor: index === 0 ? "#F6F5F9" : "#FFFFFF",
                      borderBottom: index === 0 ? "1px solid #E2E8F0" : "none",
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      className="member-detail"
                    >
                      <Avatar
                        className="member-profile-pic"
                        src="https://randomuser.me/api/portraits/men/1.jpg"
                        alt="profile-pic"
                      />
                      <Typography className="member-name">{member}</Typography>
                    </Box>
                    {index === 0 ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        p={2}
                      >
                        <Typography className="admin-user">Admin</Typography>
                      </Box>
                    ) : (
                      <>
                        <Box>
                          <IconButton
                            data-test-id="group-member-more-menu"
                            onClick={this.handleMemberMenuOpen}
                          >
                            <MoreVertRoundedIcon style={{ color: "#30353B" }} />
                          </IconButton>
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              );
            }
          )}
          {this.renderMemeberMenu()}
        </Box>
      </>
    );
  };

  renderMemeberMenu = () => {
    return (
      <>
        <StyledMenu
          data-test-id={`member-action-menu`}
          anchorEl={this.state.memberMenuAnchorEl}
          keepMounted
          elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          getContentAnchorEl={null}
          open={Boolean(this.state.memberMenuAnchorEl)}
          onClose={this.handleMemberMenuClose}
        >
          <MenuItem>
            <span className="action-icon">
              <img src={sendMessageIcon} />
            </span>
            Send a message
          </MenuItem>
          <MenuItem>
            <span className="action-icon">
              <img src={viewProfileIcon} />
            </span>
            <span>View user profile</span>
          </MenuItem>
        </StyledMenu>
      </>
    );
  };

  renderMediaTab = () => {
    return (
      <>
        <Box className="media-wrapper" data-test-id="media-wrapper">
          <Box className="attachment__image__wrapper">
            {Array(6)
              .fill("")
              .map((attachment, index) => (
                <Box key={index} className="attachment__image">
                  <img src={businessAttachment} className="image" />
                </Box>
              ))}
          </Box>
        </Box>
      </>
    );
  };

  renderFileTab = () => {
    const files = [
      {
        filename: "10-page-sample.pdf",
        content_type: "application/pdf",
        file_size: 0.1,
        url: "https://emergegen1-604372-ruby.b604372.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBamNGIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--862205def3d6dd28ab8c41d363c6f3cbdff054be/10-page-sample.pdf",
        created_at: "2025-03-03T11:42:45.662Z",
        updated_at: "2025-03-03T11:42:45.673Z",
      },
      {
        filename: "arrow1.png",
        content_type: "image/png",
        file_size: 0.0,
        url: "https://emergegen1-604372-ruby.b604372.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaVlGIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--f744e384a73689f32ab61be511ad721b28607716/arrow_right.png",
        created_at: "2025-03-03T10:12:13.066Z",
        updated_at: "2025-03-03T10:12:13.077Z",
      },
    ];
    return (
      <>
        {files.map((file) => {
          return (
            <>
              <Box className="file-wrapper" data-test-id="file-wrapper">
                <TableFileView
                  extension={getFileExtension(file.filename)}
                  fileName={file.filename}
                  fileTime={moment(new Date(file.updated_at)).fromNow()}
                />
                <IconButton>
                  <MoreVertRoundedIcon style={{ color: "#30353B" }} />
                </IconButton>
              </Box>
            </>
          );
        })}
      </>
    );
  };

  renderProfileDrawer = (tabs: string[]) => {
    const { activeTabIndex } = this.state;
    return (
      <>
        <DrawerStyled
          data-test-id="chat-info-drawer"
          anchor="right"
          open={this.props.openProfileDrawer}
          onClose={this.toggleProfileDrawer}
          variant="temporary"
          style={{ maxWidth: "360px" }}
        >
          <MainWrapper>
            {/* Drawer title */}
            {this.renderChatInfo()}

            {/* profile details */}
            {this.renderChatProfileDetails()}

            {/* profile files media members */}
            {this.renderTabs(tabs)}

            {tabs[activeTabIndex] === "Members" && this.renderMemberTab()}
            {tabs[activeTabIndex] === "Media" && this.renderMediaTab()}
            {tabs[activeTabIndex] === "Files" && this.renderFileTab()}
          </MainWrapper>
        </DrawerStyled>
      </>
    );
  };

  renderEditNameDialogue = () => {
    return (
      <>
        <CustomDialogWithAction
          data-test-id="edit-group-name-dialog"
          open={this.state.openEditGroupNameDialogue}
          handleClose={this.toggleEditGroupNameDialogue}
          hideCloseButton={true}
          title={"Edit chat name"}
          dialogContent={
            <>
              <Formik
                initialValues={{
                  email: "",
                  confirmEmail: "",
                }}
                validationSchema={this.editGroupNameValidationSchema}
                onSubmit={this.toggleEditGroupNameDialogue}
                data-test-id="edit-group-name-formik"
              >
                {({ isValid, dirty }) => (
                  <Form data-test-id="edit-group-name-form">
                    <DialogContent
                      style={{
                        width: "495px",
                        paddingBottom: 0,
                        boxSizing: "border-box",
                        display: "flex",
                        flexDirection: "column",
                        gap: "14px",
                      }}
                      dividers
                    >
                      <CustomInput
                        formik
                        name="name"
                        label="Chat name"
                        placeholder="Enter chat name"
                      />
                      <br></br>
                    </DialogContent>
                    <DialogActions style={{ paddingTop: "8px" }}>
                      <Box
                        style={{ display: "flex", width: "100%", gap: "16px" }}
                      >
                        <Box style={{ width: "50%" }}>
                          <CommonButton
                            label="Cancel"
                            onClick={this.toggleEditGroupNameDialogue}
                            dataTestId="cancel"
                            data-test-id="cancel"
                            variant="secondary"
                          />
                        </Box>

                        <Box style={{ width: "100%" }}>
                          <CommonButton
                            label="update"
                            type="submit"
                            onClick={this.toggleEditGroupNameDialogue}
                            dataTestId="update"
                            data-test-id="update"
                            variant="primary"
                            isDisabled={!isValid || !dirty}
                          />
                        </Box>
                      </Box>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </>
          }
        />
      </>
    );
  };

  renderDeleteGroupDialogue = () => {
    return (
      <>
        <ConfirmationDialog
          id="LeaveChatDialog"
          isDialogOpen={this.state.openDeleteGroupDialogue}
          onCloseDialog={this.toggleDeleteGroupDialogue}
          title={"Are you sure you want to delete?"}
          description={
            "Please confirm you want to delete <b>Top Designers</b> chat. All data and shared files within this chat will be deleted."
          }
          titleCancelButton={"Cancel"}
          titleOkButton={"Delete chat"}
          onOkCloseDialog={this.toggleDeleteGroupDialogue}
          checkConfirmationTitle="I confirm I want to delete my account and data"
        />
      </>
    );
  };

  renderLeaveChatDialogue = () => {
    return (
      <>
        <ConfirmationDialog
          id="LeaveChatDialog"
          isDialogOpen={this.state.openLeaveChatDialogue}
          onCloseDialog={this.toggleLeaveChatDialogue}
          title={"Are you sure you want to leave?"}
          description={
            "Please confirm you want to leave <b>Top Designers</b> chat. To rejoin this chat you will need to contact the chat admin."
          }
          titleCancelButton={"Back to chat"}
          titleOkButton={"Leave chat"}
          onOkCloseDialog={this.toggleLeaveChatDialogue}
        />
      </>
    );
  };

  // Customizable Area End

  render() {
    // Customizable Area Start
    const tabs = this.state.isChatGroup
      ? ["Members", "Media", "Files"]
      : ["Media", "Files"];
    return (
      <>
        {this.renderProfileDrawer(tabs)}

        {this.state.openDeleteGroupDialogue && this.renderDeleteGroupDialogue()}
        {this.state.openLeaveChatDialogue && this.renderLeaveChatDialogue()}
        {this.state.openEditGroupNameDialogue && this.renderEditNameDialogue()}
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  width: "360px",
  "& .profile-pic": {
    width: "72px",
    height: "72px",
    border: `3px solid #F6F5F9`,
  },
  "& .chat-wrapper": {
    padding: "16",
    "& .chat-info-title": {
      color: "#14101E",
      fontSize: "24px",
      fontWeight: 400,
    },
  },
  "& .profile-details-wrapper": {
    padding: "16",
    backgroundColor: "#E2E8F0",
    display: "flex",
    gap: "16px",
    "& .profile-pic-name-wrapper": {
      gap: "4px",
    },
    "& .profile-name": {
      fontSize: "18px",
      color: "#14101E",
    },
    "& .profile-detail": {
      fontSize: "14px",
      color: "#5E6671",
      fontWeight: 400,
    },
    "& .group-detail": {
      fontSize: "16px",
      color: "#30353B",
      fontWeight: 500,
      display: "flex",
      gap: "3px",
      alignItems: "center",
    },
  },
  "& .member-media-file-wrapper": {
    padding: "16px",
    "& .tab": {
      backgroundColor: "#fff",
      padding: "6px",
      border: "1px solid #D9D1EF",
      borderRadius: "12px",
      minHeight: "unset",
      width: "100%",
    },
    "& .activeTab": {
      backgroundColor: "#EDEAFF !important",
      fontFamily: "DM Sans",
      fontSize: "14px",
      fontWeight: 500,
      color: "#684EF3 !important",
      borderRadius: "6px",
      textTransform: "capitalize",
    },
    "& .normalTab": {
      backgroundColor: "#fff",
      fontFamily: "DM Sans",
      fontSize: "14px",
      fontWeight: 400,
      color: "#5E6671",
      borderRadius: "6px",
      textTransform: "capitalize",
    },
  },
  "& .member-wrapper": {
    overflow: "auto",
    "& .member-detail": {
      padding: "16px",
      gap: "16px",
    },
    "& .member-profile-pic": {
      width: "44px",
      height: "44px",
      border: `3px solid #F6F5F9`,
    },
    "& .member-name": {
      fontSize: "14px",
      fontWeight: 700,
      color: "#14101E",
    },
    "& .admin-user": {
      fontSize: "12px",
      fontWeight: 400,
      color: "#5E6671",
    },
  },
  "& .media-wrapper": {
    padding: "16px",
    "& .attachment__image__wrapper": {
      display: "flex",
      flexWrap: "wrap",
      gap: "4px",
      maxWidth: "428px",
      "& .attachment__image": {
        border: "solid 2px #E2E8F0",
        borderRadius: "8px",
        aspectRatio: 1,
        overflow: "hidden",
        display: "inline-block",
        maxWidth: "104px",
        flexGrow: 1,
        "& .image": {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      },
    },
  },
  "& .file-wrapper": {
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiPaper-root": {
    border: "1px solid #E2E8F0",
    boxShadow: "0px 4px 6px -2px #684EF333",
    borderRadius: "8px",
  },
  "& .MuiMenuItem-root": {
    display: "flex",
    alignItems: "center",
    padding: "9px 20px",
    color: "#30353B",
    fontSize: "14px",
    fontWeight: 400,
  },
  "& .action-icon": {
    width: "18px",
    height: "18px",
    paddingRight: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .delete-chat, .leave-chat-option": {
    color: "#DC2626",
  },
});

const StyledTab = styled(Tabs)({
  "& .MuiTab-root": {
    minWidth: "auto",
    minHeight: "28px",
  },
});

const DrawerStyled = styled(Drawer)({
  "& .MuiPaper-root": {
    transition: "none !important",
  },
  "& .MuiDrawer-paper": {
    height: "calc(100vh - 80px)", // Restricts to content wrapper height
    boxShadow: "none",
    transition: "none !important",
    top: "auto",
    bottom: 0,
  },
  "& .MuiBackdrop-root": {
    height: "calc(100vh - 80px)",
    top: "auto",
    bottom: 0,
  },
});
// Customizable Area End
