Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AuditTrail";
exports.labelBodyText = "AuditTrail Body";

exports.btnExampleTitle = "CLICK ME";
exports.getAuditData='bx_block_audittrail/audits';
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.clearDateBtn = "Clear Date";
exports.notFoundMsg = "No Data Found";
exports.showMoreText = "Show More";
exports.okText = "Ok";
exports.closeText = "Close";
exports.fileAlerMesg = "File download in csv format";
exports.downloadApi ="bx_block_audittrail/audits/download_csv"

exports.getAuditHistoryApiEndPoint = "bx_block_workspace/workspace_activities/interaction_list";
exports.auditTrailHistoryString = {
  workspaces: "Workspaces",
  interationsHistory: "Interations history",
  noDataFound: "No Data found",
  dateTime: "Date/Time",
  action: "Action"
};

exports.fileViewModalString = {
  page: "Page"
}
// Customizable Area End