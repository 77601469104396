import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from "yup";

// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openProfileDrawer: boolean;
  toggleDrawer: Function;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isChatGroup: boolean;
  activeTabIndex: number;
  groupAnchorEl: HTMLElement | null;
  memberMenuAnchorEl: HTMLElement | null;
  openLeaveChatDialogue: boolean;
  openDeleteGroupDialogue: boolean;
  openEditGroupNameDialogue: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      isChatGroup: true,
      activeTabIndex: 0,
      groupAnchorEl: null,
      memberMenuAnchorEl: null,
      openLeaveChatDialogue: false,
      openDeleteGroupDialogue: false,
      openEditGroupNameDialogue: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    
  }

  async componentDidMount() {
  }

  toggleProfileDrawer = () => {
    this.props.toggleDrawer()
  }

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ activeTabIndex:  newValue});
  };

  handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ groupAnchorEl: event.currentTarget});
  };
  
  handleMenuClose = () => {
    this.setState({ groupAnchorEl: null });
  };

  handleMemberMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ memberMenuAnchorEl: event.currentTarget});
  };
  
  handleMemberMenuClose = () => {
    this.setState({ memberMenuAnchorEl: null });
  };

  toggleLeaveChatDialogue = () => {
    this.setState({ openLeaveChatDialogue: !this.state.openLeaveChatDialogue, groupAnchorEl: null });
  }

  toggleDeleteGroupDialogue = () => {
    this.setState({ openDeleteGroupDialogue: !this.state.openDeleteGroupDialogue, groupAnchorEl: null });
  }

  toggleEditGroupNameDialogue = () => {
    this.setState({ openEditGroupNameDialogue: !this.state.openEditGroupNameDialogue, groupAnchorEl: null });
  }

  editGroupNameValidationSchema = Yup.object({
    name: Yup.string()
                .required('Chat name is required').nullable(),
          
  })
  // Customizable Area End
}
