// Customizable Area Start
import React from "react";
import { 
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    styled,
    Menu,
    MenuItem,
    Typography
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import HistoryTabController, { configJSON } from "./HistoryTabController.web";
import CommonAvtar from "../../../components/src/common/CommonAvtar.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import ViewProfileDrawer from "../../../blocks/user-profile-basic/src/ViewProfileDrawer.web";

export class HistoryTab extends HistoryTabController {
    actionMenu = () => {
        const { actionMenuAnchor } = this.state;
        const open = Boolean(actionMenuAnchor);
        return (
          <StyledMenu
            data-test-id="action-menu"
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={actionMenuAnchor}
            open={open}
            onClose={this.onCloseActionMenu}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            getContentAnchorEl={null}
          >
            <MenuItem
              data-test-id="view-file-menu"
              onClick={() => this.handleViewProfile()}
            >
              <Typography className="menu-item">{"View Profile"}</Typography>
            </MenuItem>
          </StyledMenu>
        );
      };

    renderViewProfileDrawerbar = () => {
        return (
            <ViewProfileDrawer
                id="ViewProfileDrawer"
                isDialogOpen={this.state.isOpenViewDialog}
                onCloseDialog={this.onCloseViewDialog}
                classes={this.props?.classes}
                account_id={this.state.selectedUserId}
            />
        )
    };

    render() {
        const { actionMenuAnchor } = this.state;
        const open = Boolean(actionMenuAnchor);
        return (
            <div>
                <TableContainer className="historyQueryHub" style={styles.viewTable}>
                    <Table data-test-id="historyRowBodyTestTest">
                        <TableHead>
                            <TableRow style={styles.tableRowHeader} data-test-id="tableTestHeader">
                                <TableCell style={styles.headerText}>
                                    {configJSON.cl_dateandtime}
                                </TableCell>
                                <TableCell style={styles.headerText}>
                                    {configJSON.cl_actionDesc}
                                </TableCell>
                                <TableCell style={styles.headerText}>
                                    {configJSON.cl_action}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody data-test-id="historyRowBodyTest">
                            {this.state.historyList?.map((historyData, index) => {
                                const fullName = (historyData.account_details?.data.attributes
                                ?.first_name || "") || "" + " " + (historyData.account_details?.data.attributes
                                ?.last_name || "")
                                return (
                                    <TableRow
                                        key={index}
                                        style={styles.contentText}
                                    >
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                            {historyData.updated_at}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                             <div style={{display: "flex", alignItems: 'center'}}>
                                                <CommonAvtar
                                                    src={
                                                        historyData.account_details?.data.attributes
                                                        ?.profile_photo
                                                    }
                                                    alt={historyData.account_details?.data.attributes
                                                        ?.first_name}
                                                    style={{ marginRight: 5 }}
                                                />
                                                <span style={{color: '#684EF3',maxWidth:"300px"}}>{fullName + " " + historyData.action_type}</span>
                                                <span style={{marginLeft: 3}}>{historyData.description}</span>
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            style={styles.contentText}
                                        >
                                            <IconButton
                                                data-test-id={`anchor-${historyData.id}`}
                                                aria-label="more"
                                                id="long-button"
                                                aria-expanded={open ? "true" : undefined}
                                                aria-haspopup="true"
                                                onClick={(event) =>
                                                    this.handleOpenActionMenu(
                                                        event,
                                                        historyData.account_details?.data.id as string
                                                    )
                                                }
                                                >
												<MoreVertIcon />
											</IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {open &&  this.actionMenu()}
                {this.state.isOpenViewDialog && this.renderViewProfileDrawerbar()}
            </div>
        );
    }
}

const styles = {
    headerViewFilterStyle: {
        display: "flex",
        alignItems: "center",
        gap: "12px",
    } as React.CSSProperties,
    container: {
        overflowY: "auto",
        display: "flex",
        width: '100%',
    } as React.CSSProperties,
    
    headerViewStyle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    } as React.CSSProperties,
    contentText: {
        color: "#0F172A",
        padding: "10px 0px 10px 16px",
        verticalAlign: "middle",
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        textAlign: 'left',

    } as React.CSSProperties,
    headerText: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "21px",
        color: "#475569"
    },
    
    viewTable: {
        backgroundColor: "#fff",
        height: 'calc(100vh - 195px)',
        borderRadius: "16px"
    },
    infoCell: {
        padding: "2px 8px",
        color: "#334155",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        width: "fit-content",
        backgroundColor: "#F6F5F9",
    } as React.CSSProperties,

    tableRowHeader: {
        border: "1px solid #ECEDF0",
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        verticalAlign: "top",
    }as React.CSSProperties,
    statusCell: {
        padding: "2px 8px",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        width: "fit-content",
    } as React.CSSProperties,
};

const StyledMenu = styled(Menu)({
  "& .MuiPopover-paper": {
    border: `1px solid ${colors().tableBorder}`,
    boxShadow: "0px 4px 6px -2px #684EF333, 0px 12px 16px -4px #684EF333",
    maxWidth: "148px",
    minWidth: "148px",
    marginTop: 4,
  },
  "& .MuiListItem-button": {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    paddingTop: "9px",
    paddingBottom: "9px",
    "&:hover": {
      backgroundColor: "#F4F3FB",
    },
    "& .menu-icon": {
      fontSize: "18px",
    },
    "& .menu-item": {
      fontFamily: "DM Sans",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "21px",
      color: colors().darkText,
    },
    "& .delete-menu-item": {
      color: colors().maximumRed,
    },
    "& .delete-menu-icon": {
      color: colors().maximumRed,
    },
  },
});

export default HistoryTab;
// Customizable Area End
