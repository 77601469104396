import React from "react";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, styled } from "@material-ui/core";
import { colors } from "../../../blocks/utilities/src/Colors";

type Props = DialogProps & {
  handleClose: () => void;
  dialogContent: React.ReactNode;
  hideCloseButton?: boolean;
};

const CustomDialogWithAction: React.FC<Props> = ({
  handleClose,
  title,
  dialogContent,
  hideCloseButton = false,
  maxWidth = "sm",
  ...rest
}) => {
  return (
    <StyledDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      maxWidth={maxWidth}
      {...rest}
    >
      {handleClose && !hideCloseButton && (
        <IconButton
          aria-label="close"
          className="close__btn"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
      <MuiDialogTitle id="customized-dialog-title">{title}</MuiDialogTitle>
      {dialogContent}
    </StyledDialog>
  );
};

export default CustomDialogWithAction;

const StyledDialog = styled(Dialog)({
  "& .MuiPaper-root": {
    boxShadow: "none",
    borderRadius: "24px",
    "& .MuiDialogTitle-root": {
      padding: "24px 58px 24px 32px",
      "& > h2": {
        color: "#14101E",
        fontWeight: 400,
        fontSize: "24px",
        lineHeight: "32px"
      }
    },
    "& .MuiDialogContent-root": {
      padding: "24px 32px",
      "&.MuiDialogContent-dividers": {
        borderColor: "#E2E8F0",
        borderBottom: "none",
      },
    },
    "& .MuiDialogActions-root": {
      padding: "26px 32px 34px",
      
    },
    "& .MuiDialogActions-spacing > :not(:first-child)": {
      marginLeft: "16px"
    },
    "&.MuiDialog-paperWidthSm": {
      maxWidth: "496px",
    },
  },
  "& .close__btn": {
    position: "absolute",
    top: "22px",
    right: "16px",
    color: colors().darkText,
  },
});
