import React from "react";
// Customizable Area Start
import AiQueryHubHistoryTabController, {
  Props,
  configJSON,
} from "./AiQueryHubHistoryTabController.web";
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { avatarImg, deleteImg, resumeImg } from "./assets";
import StyledPagination from "../../../components/src/common/Pagination.web";
import CommonDeleteDialogue from "../../../components/src/common/CommonDeleteDialogue.web";
import CommonDialogue from "../../../components/src/common/CommonDialogue.web";
// Customizable Area End

// Customizable Area Start
const string = configJSON.HistoryTabString;
// Customizable Area End

export class AiQueryHubHistoryTab extends AiQueryHubHistoryTabController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderQueryTable = (
    totalResults: number,
    totalPages: number,
    startIndex: number,
    endIndex: number
  ) => {
    return (
      <>
        <StyledTableWrapper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date/Time</TableCell>
                  <TableCell>Created query</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.rows.map((row: string[], index: string) => {
                  return (
                    <>
                      <TableRow key={index}>
                        <TableCell className="date-time">
                          <div>{row[0].substring(0,9)}</div>
                          <div>{row[0].substring(9)}</div>
                        </TableCell>
                        <TableCell>{row[1]}</TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          <IconButton
                            data-test-id={`more-icon-${index}`}
                            onClick={(event) =>
                              this.handleMenuOpen(event, index)
                            }
                          >
                            <MoreVertIcon
                              className={`more-icon ${
                                this.state.selectedRow === index
                                  ? "active-more-icon"
                                  : ""
                              }`}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          {/* pagination */}
          <StyledPagination
            totalPages={totalPages}
            currentPage={this.state.currentPage}
            handleChangePage={this.handleChangePage}
            startIndex={startIndex}
            endIndex={endIndex}
            totalResults={totalResults}
          />

          {/* Action Menu */}
          <StyledMenu
            data-test-id={`action-menu`}
            anchorEl={this.state.anchorEl}
            keepMounted
            elevation={0}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            getContentAnchorEl={null}
            open={Boolean(this.state.anchorEl)}
            onClose={this.handleMenuClose}
          >
            <MenuItem>
              <span className="action-icon-detail">
                <img src={resumeImg} />
              </span>
              Resume query
            </MenuItem>
            <MenuItem
              data-test-id="delete-from-history"
              onClick={this.toggleDeleteDialogue}
            >
              <span className="action-icon-detail">
                <img src={deleteImg} />
              </span>
              <span className="delete-history-action">Delete from history</span>
            </MenuItem>
          </StyledMenu>
        </StyledTableWrapper>
      </>
    );
  };

  rendeActionTable = (
    totalResults: number,
    totalPages: number,
    startIndex: number,
    endIndex: number
  ) => {
    const rows = [
      {
        id: "09/13/24 9:12",
        user: "Jane Cooper",
        action: "updated",
        document: "Report_669289.doc",
      },
      { id: "08/13/24 9:12", user: "Leslie Alexander", action: "runs query" },
      {
        id: "07/13/24 9:12",
        user: "Soah Letterier",
        action: "updated",
        document: "Report_669289.doc",
      },
      {
        id: "06/13/24 9:12",
        user: "Charlotte Novak",
        action: "updated",
        document: "Report_669289.doc",
      },
      { id: "05/13/24 9:12", user: "Lora Normann", action: "runs query" },
      { id: "04/13/24 9:12", user: "Lora Normann", action: "runs query" },
      { id: "03/13/24 9:12", user: "Leslie Alexander", action: "runs query" },
      {
        id: "02/13/24 9:12",
        user: "Soah Letterier",
        action: "updated",
        document: "Report_669289.doc",
      },
      {
        id: "01/13/24 9:12",
        user: "Charlotte Novak",
        action: "updated",
        document: "Report_669289.doc",
      },
    ];

    return (
      <>
        <StyledTableWrapper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date/Time</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index: number) => {
                  return (
                    <>
                      <TableRow key={index}>
                        <TableCell className="date-time">
                          <div>{row.id.substring(0,9)}</div>
                          <div>{row.id.substring(9)}</div>
                        </TableCell>
                        <TableCell>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              gap: "16px",
                              flexGrow: 1,
                            }}
                          >
                            <Avatar
                              className="avtar-icon"
                              alt={""}
                              src={avatarImg}
                            />
                            <Typography className="action">
                              {row.user}
                              <span className="action-detail">
                                {row.action}
                              </span>
                              <span style={{ paddingLeft: "8px" }}>
                                {row.document}
                              </span>
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {/* pagination */}
          <StyledPagination
            totalPages={totalPages}
            currentPage={this.state.currentPage}
            handleChangePage={this.handleChangePage}
            startIndex={startIndex}
            endIndex={endIndex}
            totalResults={totalResults}
          />
        </StyledTableWrapper>
      </>
    );
  };

  renderDeleteDialogue = () => {
    return (
      <>
        <CommonDeleteDialogue
          open={this.state.openDeleteDialogue}
          title={string.delteDialogueTitle}
          body={
            <Typography>
              Please confirm you want to delete query{" "}
              <span style={{ fontWeight: 700 }}>
                I need a breakdown of my monthly expenses from the last six
                months...
              </span>{" "}
              from history.
            </Typography>
          }
          deleteBtnText={string.deleteBtn}
          cancelBtnText={string.cancelBtn}
          handleCancel={this.handleDialogue}
          handleDelete={this.handleDialogue}
        />
      </>
    );
  };

  renderCommonDialogue = () => {
    return (
      <>
        <CommonDialogue
          open={false}
          title={string.delteDialogueTitle}
          body={"Please confirm you want to delete query"}
          submitBtnText={string.deleteBtn}
          cancelBtnText={string.cancelBtn}
          handleCancel={this.handleDialogue}
          handleSubmit={this.handleDialogue}
        />
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const totalResults = this.state.rows.length;
    const itemsPerPage = 3;
    const totalPages = Math.ceil(totalResults / itemsPerPage);
    const startIndex = (this.state.currentPage - 1) * itemsPerPage + 1;
    const endIndex = Math.min(
      this.state.currentPage * itemsPerPage,
      totalResults
    );
    return (
      <GeneralTabViewWrapper>
        {/* Query Table */}
        {!this.props.isActionTable && this.renderQueryTable(totalResults, totalPages, startIndex, endIndex)}
        {/* Action Table */}
        {this.props.isActionTable && this.rendeActionTable(totalResults, totalPages, startIndex, endIndex)}

        {this.state.openDeleteDialogue && this.renderDeleteDialogue()}
      </GeneralTabViewWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const GeneralTabViewWrapper = styled(Box)({
  backgroundColor: "#FFFFFF",
  fontFamily: "DM Sans",
  "& .date-time": {
    color: "#5E6671",
  },
});

const StyledTableWrapper = styled(Box)({
  "& .MuiTableCell-head": {
    fontWeight: 700,
    fontSize: "14px",
    color: "#30353B",
  },
  "& .MuiTableCell-root": {
    borderBottom: "1px solid #E2E8F0",
    fontFamily: "DM Sans",
  },
  "& .more-icon": {
    padding: "5px",
    borderRadius: "6px",
    height: "32px",
    width: "32px",
    color: "#14101E",
  },
  "& .active-more-icon": {
    borderRadius: "8px",
    backgroundColor: "#EAE6FF",
    border: "solid 1px #D9D1EF",
  },
  "& .avtar-icon": {
    height: "32px",
    width: "32px",
    border: "1px solid #D9D1EF",
    borderRadius: "50%",
    padding: "2px",
  },
  "& .action": {
    fontWeight: 700,
    fontSize: "14px",
    color: "#684EF3",
    paddingLeft: "8px",
    fontFamily: "DM Sans",
  },
  "& .action-detail": {
    fontWeight: 400,
    color: "#14101E",
    paddingLeft: "8px",
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiPaper-root": {
    border: "1px solid #E2E8F0",
    boxShadow: "0px 4px 6px -2px #684EF333",
  },
  "& .MuiMenuItem-root": {
    display: "flex",
    alignItems: "center",
    padding: "9px 20px",
    color: "#30353B",
    fontSize: "14px",
    fontWeight: 400,
  },
  "& .action-icon-detail": {
    width: "18px",
    height: "18px",
    paddingRight: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .delete-history-action": {
    color: "#DC2626",
  },
});
export default AiQueryHubHistoryTab;
// Customizable Area End
