// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";
import { AIOption } from "./DashboardPreviewFile.web";
import { baseURL } from "../../../../packages/blocks/bulkuploading/src/BulkUploadingController";
import { UploadOptions } from "../src/DashboardController.web";
import { makeApiMessage } from "../../../blocks/utilities/src/commonfunctions";

export const configJSON = require("./config.js");

export type Props =  {
    id: string;
    onCloseDialog: (data?: Array<File>) => void
    isDialogOpen: boolean
    files: any
    navigation: any
    classes?: Record<string, string>
    workspaceSelected: boolean
    showAiProcessingForm: boolean
    selectedAiProcessing: UploadOptions | null
    isFileReplaceProcess?: boolean;
    fileReplaceId?: string;
    isViewFile?: boolean;
}

export type aiOptionType = {
    id: string,
    type: string,
    attributes: {
        titles: string
    }
}

interface WorkspaceOption {
    id: number
    name: string
}

interface S {
    files: Array<File>
    currentFile?: File
    isCancel: boolean
    phase: number
    pdf: any
    snackbarContent: string
    isSnackbarOpen: boolean
    nameFile: string
    fileData: string
    descriptionFile: string
    currentTab: number
    fileFormat: string
    listWorkspace: WorkspaceOption[],
    selectedWorkspace: WorkspaceOption | null,
    originalWorkspace: WorkspaceOption | null,
    iconToggle:boolean,
    workspaceChoice: number,
    selectedOptions: Array<{ id: number; name: string }>
    aiAllOptions: Array<{ id: number; name: string }>
    isUploading: boolean
    isRegenerate: boolean
    workspaceChoiceObj: any
    selectedAiProcessingModel: UploadOptions | null
    isParamsLoading: boolean
}

interface SS {
  id: string | number;
}

class DashboardPreviewFileController extends BlockComponent<Props, S, SS> {
    getWorkspaceListId: string = "";
    getProcessingParametersId: string = "";
    replaceFileApiCallId: string = "";
    savePDFid: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            files: [],
            pdf: "",
            isSnackbarOpen: false,
            snackbarContent: "",
            listWorkspace: [],
            selectedWorkspace: null,
            originalWorkspace: null,
            currentFile: undefined,
            isCancel: false,
            phase: 1,
            nameFile: "",
            descriptionFile: "",
            fileData: "",
            currentTab: 0,
            workspaceChoice: 0,
            workspaceChoiceObj: {},
            fileFormat: "pdf",
            iconToggle:false,
            selectedOptions: [],
            aiAllOptions: [],
            isRegenerate: false,
            isUploading: false,
            selectedAiProcessingModel: null,
            isParamsLoading: false
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                if (apiRequestCallId === this.getWorkspaceListId) {
                    this.handleWorkspaceResponse(responseJson)
                }
                if (responseJson.data) {
                    if (apiRequestCallId === this.getProcessingParametersId) {
                        const formatedData = responseJson.data.map((item: aiOptionType) => {
                            return { id: Number(item.id), name: item.attributes.titles }
                        });
                      
                        this.setState({ aiAllOptions: formatedData, isParamsLoading: false });
                    }
                  

                }
                this.handleFileReplaceApicall(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }

    async componentDidMount(): Promise<void> {

        const aiProcessModel = this.props.selectedAiProcessing;

        this.setState({ 
            selectedAiProcessingModel: aiProcessModel || UploadOptions.AIProcessing, 
            files: this.props.files, 
            currentFile: this.props.files[0],
            isParamsLoading: true
        }, () => {
            this.getWorkspaceList()
            this.getProcessingParametersWithAPI()
        })
    }

    // componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    //     if (prevProps.files.length !== this.props.files.length) {
    //         this.setState({ files: this.props.files, currentFile: this.props.files[0] })
    //     }
    // }

    handleWorkspaceResponse = (result: any) => {
        if(this.props.workspaceSelected) {
            const worksapceId = this.props.navigation.getParam("id");
            const workspace = (result as WorkspaceOption[]).find(option => option.id === +worksapceId) || null;
            
            this.setState({ listWorkspace: result, selectedWorkspace: workspace, originalWorkspace: workspace })
            return;
        }
        this.setState({ listWorkspace: result })
    }
  
    buildReportAPI = async (currentFile: File) => {
        const token = await getStorageData("authToken")
      const data = new FormData();
      data.append( "file", currentFile);
        fetch(`${configJSON.urlClientServer}/${configJSON.buildReport}`, { method: 'POST', body: data, headers: {token} })
    }

    handleSavePDF = (pdf: any) => {
        this.setState({ pdf, isRegenerate: false });
    }

    handleClose = () => {
        this.setState({isSnackbarOpen: false})
    }

    checkEnableButton = () => {
        if (this.state.currentTab === 0) {
            if (this.state.fileData !== "" && this.state.nameFile !== "" && this.state.descriptionFile !== "") {
                return true
            } else {
                return false
            }
        } else {
            if (
                this.state.fileData !== "" &&
                this.state.nameFile !== "" &&
                this.state.descriptionFile !== "" &&
                this.state.fileFormat !== "" &&
                this.state.selectedWorkspace &&
                this.state.pdf !== "" &&
                !this.state.isRegenerate &&
                !this.state.isUploading
            ) {
                return true
            } else {
                return false
            }
        }
    }

    onProceed = () => {
        if (this.state.currentTab === 0) {
            this.setState({ currentTab: 1 })
        } else {
            this.setState({ isUploading: true })
            this.handleCreatedNewFile()
        }
    }
    handleCreatedNewFile = async () => {
        if(!this.props.isFileReplaceProcess) {
        const token = await getStorageData("authToken")
        const data = new FormData();
        data.append("files[]", this.state.pdf);
        data.append("workspace_id", `${this.state.selectedWorkspace?.id}`);
        data.append("final_file_name", this.state.nameFile);
        data.append("content_type", this.state.fileFormat);
        const response = await fetch(`${baseURL}/${configJSON.bulkUploading}`, { method: 'POST', body: data, headers: {token} })
        if(response.ok) {
            this.setState({ isCancel: false, isSnackbarOpen: true, snackbarContent: "Files have been successfully uploaded", isUploading: false }, () => {
                setTimeout(() => {
                    this.closeDialog();
                }, 500)
            })
        }
    } else {
        const apiUrl = configJSON.replaceFileWorkspace;
        const workspaceId = this.props.navigation.getParam("id");
    
        const formData = new FormData();
        const newfile = new File([this.state.pdf], `${this.state.nameFile}.${this.state.fileFormat}`)
        formData.append("workspace_id", workspaceId.toString());
        formData.append("file_id", this.props.fileReplaceId || "");
        formData.append("files", newfile);
    
        const requestMessage = await makeApiMessage({
            url: apiUrl,
            method: "PUT",
            body: formData,
            isHeader: true,
        });
    
        this.replaceFileApiCallId = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    }

    onClickRegenerate = () => {
        this.setState({ isRegenerate: true })
    }

    resetRegenerate = () => {
        this.setState({ isRegenerate: false })
    }

    onCloseAll = () => {
        this.setState({ isCancel: false, currentTab: 0 }, () => {
            this.closeDialog()
        })
    }

    closeDialog = () => {
        this.setState({
            files: [],
            pdf: "",
            snackbarContent: "",
            isSnackbarOpen: false,
            isCancel: false,
            currentFile: undefined,
            phase: 1,
            descriptionFile: "",
            nameFile: "",
            fileData: "",
            workspaceChoice: 0,
            currentTab: 0,
            workspaceChoiceObj: null,
            fileFormat: "pdf",
            iconToggle:false,
            selectedOptions: [],
            selectedWorkspace: this.state.originalWorkspace  
        })
        this.props.onCloseDialog()
    }

    getWorkspaceList = async () => {
        const request = new Message(getName(MessageEnum.RestAPIRequestMessage))
        const token = await getStorageData("authToken")
        this.getWorkspaceListId = request.messageId;
        createRequestMessage({
            method: "GET",
            requestMessage: request,
            token,
            endPoint: `${configJSON.getListWorkspace}`
        });
    }

    getProcessingParametersWithAPI = async () => {
        const request = new Message(getName(MessageEnum.RestAPIRequestMessage))
        const token = await getStorageData("authToken")
        this.getProcessingParametersId = request.messageId;
        createRequestMessage({
            method: "GET",
            requestMessage: request,
            token,
            endPoint: this.state.selectedAiProcessingModel === UploadOptions.AIProcessing ? configJSON.getAIOptionList : configJSON.getGreekAIOptionList 
        });
    }

    onBackToPhase1 = () => {
        this.setState({ phase: 1 })
    }

    handleChange = (event: any) => {
        this.setState({ fileData: event.target.value });
    };

    handleChangeAction = (event:Array<AIOption> ) => { 
        this.setState({ selectedOptions: event});
    };

    handleChangeFormat = (event: any) => {
        this.setState({ fileFormat: event.target.value });
    };

    onCloseCancelDialog = () => {
        this.setState({ isCancel: false })
    }

    onOpenCancelDialog = () => {
        if(Boolean(this.props.isViewFile)) {
            this.closeDialog()
        } else {
            this.setState({ isCancel: true })
        }
    }

    onContinueBtn = () => {
        this.setState({ phase: 2 })
    }

    onChangeName = (nameFile: string) => {
        this.setState({ nameFile })
    }

    onChangeDescription = (descriptionFile: string) => {
        if (descriptionFile.trim().length > 0) {
            this.setState({ descriptionFile });
        } else {
            this.setState({ descriptionFile: "" });
        }
    }

    onChangeWorkspace = (value: WorkspaceOption | null) => {
        this.setState({ selectedWorkspace: value })
    }

    onChangeIcon = () => {
        this.setState({ iconToggle: !this.state.iconToggle });
    }

    handleSelectAll = (checked: boolean) => {
        if (checked) {
          this.setState({ selectedOptions: [...this.state.aiAllOptions] }, () => {
            this.handleChangeAction(this.state.selectedOptions);
          });
        } else {
          this.setState({ selectedOptions: [] }, () => {
            this.handleChangeAction(this.state.selectedOptions);
          });
        }
      };

    currentTabChange = (num: number) => {
        this.setState({ currentTab: num });
    }

    onChangeAiModel = (event: React.ChangeEvent<{name?: string, value: unknown }>) => {
        this.setState({ 
            selectedAiProcessingModel: event.target.value as UploadOptions, 
            selectedOptions: [] 
        }, () => this.getProcessingParametersWithAPI())
    }

    handleFileReplaceApicall = (apicallId: string, response: { message: string }) => {
        if (apicallId === this.replaceFileApiCallId) {
            if(response) {
                this.setState({
                    isUploading: false,
                    isSnackbarOpen: true,
                    snackbarContent: response.message,
                });
                this.closeDialog();
            }
        }
    }
}

export default DashboardPreviewFileController;
// Customizable Area End
