import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("./config");
import { navigateToScreen } from "../../utilities/src/commonfunctions";
export const mockNotificationData = [
  {
    id: 1,
    notificationText: "John Doe mentioned you at Leases Workspace",
    action: "Thanks @charlotte for the designs",
    name: "Doe",
    time: "5mins ago",
    unRead: true,
  },
  {
    id: 2,
    notificationText: "John Doe mentioned you at Leases Workspace",
    action: "Thanks @charlotte for the designs",
    name: "Pritesh",
    time: "5mins ago",
    unRead: true,
  },
  {
    id: 3,
    notificationText: "John Doe mentioned you at Leases Workspace",
    action: null,
    name: null,
    time: "5mins ago",
    unRead: false,
  },
  {
    id: 4,
    notificationText: "John Doe mentioned you at Leases Workspace",
    action: "Thanks @charlotte for the designs",
    name: "Pritesh",
    time: "5mins ago",
    unRead: true,
  },
  {
    id: 5,
    notificationText: "John Doe mentioned you at Leases Workspace",
    action: "This is main notification",
    name: null,
    time: "5mins ago",
    unRead: false,
  },
];
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  actionMenuAnchor: null | HTMLElement;
  notificationList: Array<unknown>;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class AppNotificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      actionMenuAnchor: null,
      notificationList: mockNotificationData,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleCloseNotification = () => {
    this.setState({ actionMenuAnchor: null });
  };

  handleOpenNotificationmenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      actionMenuAnchor: event.currentTarget,
    });
  };

  handleMarkAsRead = () => {
    this.setState({ notificationList: [] });
  };

  handleNavigateToDetailNotify = () => {
    const notifyDetailPage = navigateToScreen(
      "AppNotificationDetails",
      this.props
    );
    this.send(notifyDetailPage);
    this.handleCloseNotification();
  };
  // Customizable Area End
}
