import React from "react";
// Customizable Area Start
import {
  Box, Typography, AppBar, Toolbar, createTheme, ThemeProvider, Button,
  Grid, styled,
  TextField,
  InputAdornment,
  Snackbar,
  IconButton,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { deleteIcon } from "../../accountgroups/src/assets";
import ConfirmationDialog from '../../../../packages/blocks/accountgroups/src/ConfirmationDialog.web';
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import TableComponent from "../../../components/src/DashboardComponent/TableComponent.web";
import UserProfileDialog from "../../user-profile-basic/src/UserProfileDialog.web";
import { upload } from './assets';
import BulkUploadDashboardDialog from "./BulkUploadDashboardDialog.web";
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import moment from "moment";
import UserProfileBasicBlockWeb from "../../../../packages/blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import { Alert } from "@material-ui/lab";
import DashboardPreviewFile from "./DashboardPreviewFile.web";
import Loader from "../../../../packages/components/src/Loader.web";
import { downloadIcon } from "../../../../packages/blocks/termsconditions/src/assets";
import { UploadOptions } from "../src/DashboardController.web";
import Joyride, { Step, TooltipRenderProps } from "react-joyride";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import AppNotification from "../../../blocks/notifications/src/AppNotification.web";

const theme = createTheme({
  palette: {
    primary: {
      main: '#E2E8F0',
    },
  },
  shape: {
    borderRadius: 16,
  },
  overrides: {
    MuiAvatar: {
      root: {
        borderRadius: '0px',
      },
    },
    MuiTableCell: {
      alignRight: {
        textAlign: "start"
      },
      root: {
        padding: "15px 16px 12px 28px"
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "0px"
      }
    }
  },
});

const steps = [
  {
    target: "#step-1",
    content:
      "Here, you can upload different types of files individually or in bulk. Simply click the upload button, select your files, and manage them directly from the dashboard.",
      disableBeacon: true,
      placement: "right-start",
  },
  {
    placement: "right-start",
    target: "#step-2",
    content:
      "Create and manage workspaces. Each workspace can have specific users and projects. Collaborate efficiently by organizing your work into different spaces.",
      disableBeacon: true,
  },
  {
    target: "#step-3",
    disableBeacon: true,
    content:
      "Communicate with your team members using the internal chat feature. Start conversations, share files, and stay connected with your colleagues in real time.",
    placement: "right-start",
  },
  {
    target: "#step-4",
    content:
      "See a list of all available teams. You can join existing teams or create new ones. Get an overview of the team members and their roles within the organization.",
      disableBeacon: true,
    placement: "right-start",
  },
  {
    target: "#step-5",
    placement: "right-start",
    disableBeacon: true,
    content:
      "Create and manage multipage forms. This feature allows you to design complex forms for various purposes, ensuring that you collect all the necessary information in an organized manner.",
  },
  {
    content:
    "Edit your personal details, change your password, and manage your account settings. Keep your profile information up to date to ensure seamless communication within your teams.",
    placement: "right-end",
    disableBeacon: true,
    target: "#step-6",
  },
  {
    content:
    "Receive alerts and updates about important activities, messages, and tasks. Customize your notification settings to stay informed about what matters most to you.",
    target: "#step-7",
    placement: "right-start",
    disableBeacon: true,
  },
] as Step[];

// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getIcon = (fileName: string) => {
    const listName = fileName.split('.')
    const extension = listName[listName.length - 1]

    return (
      <div style={{ position: "relative" }}>
        <svg width="90" height="91" viewBox="0 0 90 91" fill="none" xmlns="http://www.w3.org/2000/svg" style={webStyles.iconRecent}>
          <g clipPath="url(#clip0_23865_35562)">
            <path fillRule="evenodd" clipRule="evenodd" d="M89.667 27.1137C89.6768 10.4163 79.8405 0.568566 63.1432 0.558779L26.8272 0.537492C10.0879 0.52768 0.221471 10.3639 0.211683 27.0612L0.190347 44.7946C0.180574 61.4685 6.3068 71.3141 23.0088 71.3239L32.3421 71.3294C35.6928 71.3267 38.8484 69.7559 40.8566 67.0737L45.1206 61.4109C47.1382 58.7334 50.2938 57.1579 53.6444 57.1552L66.8511 57.163C83.5904 57.1728 89.7741 67.3217 89.7841 50.2837L89.667 27.1137Z" fill="#E3DEFF" />
            <path fillRule="evenodd" clipRule="evenodd" d="M89.667 63.4387C89.6768 80.1361 79.8405 89.9838 63.1432 89.9936L26.8272 90.0149C10.0879 90.0247 0.221471 80.1885 0.211683 63.4912L0.190347 27.0912C0.180574 10.4172 6.3068 0.571601 23.0088 0.561811L32.3421 0.55634C35.6928 0.559043 38.8484 2.12986 40.8566 4.81202L45.1206 10.4749C47.1382 13.1523 50.2938 14.7278 53.6444 14.7305L66.8511 14.7228C83.5904 14.713 89.7741 23.2307 89.7841 40.2687L89.667 63.4387Z" fill="#684EF3" />
            <path d="M24.6215 54.0521L65.3848 54.0282" stroke="#F6F5F9" strokeWidth="4.66667" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24.6215 37.7191L65.3848 37.6952" stroke="#F6F5F9" strokeWidth="4.66667" strokeLinecap="round" strokeLinejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_23865_35562">
              <rect width="89.6798" height="89.5078" fill="white" transform="translate(0.133789 0.522461) rotate(-0.0335844)" />
            </clipPath>
          </defs>
        </svg>
        <span style={webStyles.extension}>{extension.toUpperCase()}</span>
       
      </div>
    )
  }
  renderSearch = () => {
    return (
      <TextField
          className={this.props.classes?.searchInput}
          data-test-id="searchInput"
          value={this.state.searchText}
          onChange={this.onChangeSearchInput}
          placeholder={webConfigJSON.search}
          InputProps={{
              startAdornment: (
                  <InputAdornment position="start">
                      <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8 16.147C9.77498 16.1466 11.4988 15.5524 12.897 14.459L17.293 18.855L18.707 17.441L14.311 13.045C15.405 11.6466 15.9996 9.92241 16 8.14697C16 3.73597 12.411 0.146973 8 0.146973C3.589 0.146973 0 3.73597 0 8.14697C0 12.558 3.589 16.147 8 16.147ZM8 2.14697C11.309 2.14697 14 4.83797 14 8.14697C14 11.456 11.309 14.147 8 14.147C4.691 14.147 2 11.456 2 8.14697C2 4.83797 4.691 2.14697 8 2.14697Z" fill="#94A3B8" />
                      </svg>
                  </InputAdornment>
              ),
              disableUnderline: true,
              className: this.props.classes?.searchStyle
          }}
      />
    )
  }

  CustomTooltip = (props: TooltipRenderProps): React.ReactElement => {
    const {  step,
      backProps,
      primaryProps,
      index,
      size,
      tooltipProps,
      isLastStep
    } = props;
  
      return (
        <TooltipWrapper {...tooltipProps} className="wrapper">
          <Typography className="steps">{index + 1}/{size}</Typography>
          <Typography className="step__content">{step.content}</Typography>
          <Box className="button__wrapper">
            {index > 0 && (
              <IconButton {...backProps} className="back__button">
                <KeyboardArrowLeftIcon fontSize="small" />
              </IconButton>
            )}
            <button {...primaryProps} className="next__button">
              {isLastStep ? webConfigJSON.closeBtnLabel : webConfigJSON.nextBtnLabel }
            </button>
          </Box>
        </TooltipWrapper>
      );
  }
  

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <>
        <div style={{ display: "flex", flexDirection: "row", backgroundColor: "#E2E8F0", height: "100vh" }}>
          <div style={{ position: "sticky" }}>
            <NavigationMenu navigation={this.props.navigation} id="dashboard" />
          </div>
          <div style={{ overflowY: "auto", width: "100%" }}>
            <div style={{ flexGrow: 1, }}>
              <ThemeProvider theme={theme}>
                <AppBar position="static">
                  <Toolbar style={webStyles.appbar}>
                    <Typography style={webStyles.title}>{webConfigJSON.dashboard}</Typography>
                    <div style={webStyles.viewSearch}>
                      {this.renderSearch()}
                      <Box id="step-7">
                      <AppNotification navigation={this.props.navigation} id="dashboard" />
                      </Box>
                    </div>
                  </Toolbar>
                </AppBar>
              </ThemeProvider>
              <Grid container style={webStyles.pdfContainer}>
                {
                  this.state.listAttachment.length === 0
                    ? <div />
                    : (
                      <Grid
                        container item xl={7} lg={7} md={7} sm={7} xs={7}
                        style={webStyles.viewRecentFile}
                      >
                        <Typography style={{ ...webStyles.filesCs, ...{ marginBottom: "23px" } }}>Your recent files</Typography>
                        <Grid container item style={webStyles.uploadFiles}>
                          {
                            this.state.listAttachment.flatMap((item) =>
                              item.attributes.files.map((file) => ({
                                id: item.id,
                                file,
                                submittedBy: item.attributes.submitted_by,
                                lastModified: item.attributes.last_modified,
                                workspace_id: item.attributes.workspace_id,
                                ai_file_uploaded: item.attributes.ai_file_uploaded
                              }))
                            )
                            .slice(0, 3).map((item, index) => {
                              return (
                                <Grid
                              data-test-id={`attachment_${index}`}
                              key={`attachment_${index}`}
                              container
                              item
                              xl={4}
                              lg={4}
                              md={4}
                              sm={4}
                              xs={4}
                              style={{ paddingRight: "20px", width: "unset", cursor: "pointer", position: "relative" }}
                            >
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                style={webStyles.uploadFileContainer}

                              >
                                                                        <IconButton
                                                                         style={{ position: 'absolute', top: 0, right: 7 }}
          data-test-id={`member-${item.id}`}
          onClick={(event) => this.handleClick1(event, item.id,item.file.filename, item.file.url, item.workspace_id)}
        >
          <MoreVertIcon />
        </IconButton>
                              
                                
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }} onClick={() => this.onChooseFile(item.file, item.ai_file_uploaded)}>
                                  {this.getIcon(item.file.filename)}
                                </div>
                                <Typography style={webStyles.nameFiles}>
                                  {item.file.filename}
                                </Typography>
                                <Typography style={webStyles.fileEdited}>
                                  {moment(new Date(item.lastModified)).fromNow()}
                                </Typography>
                              </Grid>
                              <Menu anchorEl={this.state.anchorEl2}
      open={Boolean(this.state.anchorEl2)} onClose={this.handleClose2}
      PaperProps={{ elevation: 0, style: { boxShadow: 'none', border: "1px solid #E2E8F0"
        }
      }}
    >
          <MenuItem 
          onClick={this.confirmdeleteFiles}>
            <ListItemIcon>
              <img
                src={deleteIcon} alt="Remove File"
                style={{ height: "20px",
                 width: "18px" } as const}
              />
            </ListItemIcon>
            <ListItemText
              primary="Remove File" style={{ color: "#DC2626", fontFamily: 'DM Sans', fontWeight: 400, fontSize: "14px" } as const}
            />
          </MenuItem> 
                                    <MenuItem onClick={this.downloadFile}>
                                      <ListItemIcon>
                                        <img
                                          src={downloadIcon} alt="Download File"
                                          style={{ height: "20px",
                                          width: "18px" } as const}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary="Download File" style={{ color: "#DC2626", fontFamily: 'DM Sans', fontWeight: 400, fontSize: "14px" } as const}
                                      />
                                    </MenuItem> 
                                  </Menu>
                                </Grid>
                              )
                            })
                          }
                        </Grid>
                      </Grid>
                    )
                }
                <Grid item
                  xl={this.state.listAttachment.length === 0 ? 12 : 5}
                  lg={this.state.listAttachment.length === 0 ? 12 : 5}
                  md={this.state.listAttachment.length === 0 ? 12 : 5}
                  sm={this.state.listAttachment.length === 0 ? 12 : 5}
                  xs={this.state.listAttachment.length === 0 ? 12 : 5}
                >
                  <Typography style={{ ...webStyles.filesCs, ...{ marginBottom: "23px" } }}>Upload Files</Typography>
                  <Box style={webStyles.uploadContainer}>
                    <img src={upload} />
                    <Typography style={webStyles.draganddropText}>
                      Drag and drop your files or
                    </Typography>
                    <Button data-test-id="open-upload-dialog" style={webStyles.browseButton} onClick={this.openUploadDialog}>
                      Browse
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Box style={webStyles.tableContainer}>
                <Box style={webStyles.containerBox}>
                  <Typography style={webStyles.filesCs}>Files</Typography>
                  <Typography style={webStyles.seeAll} data-test-id="btnFile" onClick={this.handleAllFiles}>View All</Typography>
                </Box>
                <ThemeProvider theme={theme}>
                  <TableComponent
                    arrayData={this.state.listAttachment.slice(0, 5)}
                    onChooseFile={this.onChooseFile}
                    onChooseMoreOption={this.handleClick1}
                  />
                </ThemeProvider>
              </Box>
            </div>
          </div>

          <BulkUploadDashboardDialog
            id="BulkUploadDashboardDialog"
            isDialogOpen={this.state.isOpenUploadDialog}
            onCloseDialog={this.onCloseUploadDialog}
            classes={this.props?.classes}
            selectWorkspace={false}
            navigation={this.props.navigation}
          />
          {
            this.state.isOpenPreviewDialog && (
          <DashboardPreviewFile
            id="DashboardPreviewFile"
            isDialogOpen={this.state.isOpenPreviewDialog}
            isViewFile={this.state.isViewFile}
            onCloseDialog={this.onClosePreviewDialog}
            classes={this.props?.classes}
            files={this.state.dataFiles?.files ?? []}
            navigation={this.props.navigation}
            showAiProcessingForm={this.state.showAiProcessingForm}
            workspaceSelected={false}
            selectedAiProcessing={(this.state.dataFiles?.uploadChoice as UploadOptions.AIProcessing | UploadOptions.GreekLegalDocument)}
          />
            )
          }
          <UserProfileDialog
            id="UserProfileDialog"
            // isDialogOpen={this.state.isNewAccount}
            isDialogOpen={false}
            onCloseDialog={this.onCloseUserProfileCloseDialog}
            classes={this.props?.classes}
          />
          <Loader loading={this.state.loading} />
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={this.state.isSnackbarOpen} autoHideDuration={3000} onClose={this.handleClose}
          >
              <Alert icon={
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.4933 7.28613L11.9575 10.8211L8.42247 7.28613L7.24414 8.46447L10.7791 11.9995L7.24414 15.5345L8.42247 16.7128L11.9575 13.1778L15.4933 16.7128L16.6716 15.5345L13.1366 11.9995L16.6716 8.46447L15.4933 7.28613Z" fill="white"/>
                    </svg>
                  }
                  style={
                    webStyles.viewAlert}
              >
                  {this.state.snackbarContent}
              </Alert>
          </Snackbar>
          <ConfirmationDialog
            id="ConfirmationDialog"
            isDialogOpen={this.state.isOpenDeleteFiles} onCloseDialog={this.onCloseCancelDialog}
            title={webConfigJSON.deleteFiles}
            description={webConfigJSON.deleteFliesDescription1 + "<span style='font-weight: bold'>"+this.state.fileName+"</span>" + webConfigJSON.deleteFilesDescription2}  titleCancelButton={webConfigJSON.cancelDelete}
            titleOkButton={webConfigJSON.confirmDelete}  onOkCloseDialog={this.onCloseAll}
            checkConfirmationTitle=""
          />

          <RFHDialogWrapper
            open={this.state.notAssociatedWithAnyWorkspace}
            maxWidth="xs"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown
            data-test-id="alert-dialog"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {webConfigJSON.NotAssicatedWithAnyWorkspaceDialogMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.redirectToWorkspace} className='dialog__button' data-test-id="add-new-workspace" fullWidth>
                {webConfigJSON.btnAddNewWorkspace}
              </Button>
            </DialogActions>
          </RFHDialogWrapper>

          <Joyride
            data-test-id="joyride"
            continuous
            steps={steps}
            run={this.state.run}
            showSkipButton={false}
            hideCloseButton
            disableCloseOnEsc
            callback={this.handleJoyrideCallback}
            locale={{
              last: "Close",
            }}
            tooltipComponent={this.CustomTooltip}
            styles={{
              options: {
                arrowColor: "none",
              }                
            }}
            disableOverlayClose={true}
          />

          <StyledDialog
            open={this.state.isNewAccount}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown
            data-test-id="new-account-success-dialog"
          >
            <DialogContent>
              <Box className="content">
                <Box className="icon">
                  <DoneRoundedIcon className="done__icon" />
                </Box>
                <Typography className="content__message">
                  {webConfigJSON.profileCreated}
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button className="btn__dialog" data-test-id="continue-to-dashboard" fullWidth variant="contained" onClick={this.startOnboardGuide}>
                {webConfigJSON.continueToDashboard}
              </Button>
            </DialogActions>
          </StyledDialog>
        </div>
      </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paperWidthSm": {
    width: "496px",
  },
  "& .MuiDialog-paper": {
    borderRadius: "24px",
    boxShadow: "none",
    border: "none",
  },
  "& .MuiDialogContent-root": {
    padding: "32px 32px 16px",
    "& .content": {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      "& .icon": {
        margin: "28px auto 0",
        width: "75px",
        height: "75px",
        borderRadius: "75px",
        backgroundColor: "#33BC55",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .done__icon": {
          color: "#FFF",
          fontSize: "42px"
        }
      },
      "& .content__message": {
        color: "#33BC55",
        fontWeight: 700,
        fontSize: "20px",
        fontFamily: "'DM Sans', Roboto",
        textAlign: "center"
      }
    }
  },
  "& .MuiDialogActions-root": {
    padding: "24px 32px 32px",
    "& .btn__dialog": {
      textTransform:"capitalize",
      backgroundColor: "#684EF3",
      borderRadius: "8px",
      fontSize: "16px",
      lneHeight: "24px",
      fontWeight: 400,
      paddingTop: "10px",
      paddignBottom: "10px",
      color: "#FFF",
      fontFamily: "'DM Sans', Roboto",
    }
  }
})

const TooltipWrapper = styled("div")({
    borderRadius: "8px",
    backgroundColor: "#FFF",
    padding: "16px 20px",
    maxWidth: "376px",
    boxSizing: "border-box",
    "& .steps": {
        fontSize: "16px",
        fontWeight: "500",
        marginBottom: "12px",
        lineHeight: "24px",
        fontFamily: "'DM Sans', Roboto",
        color: "#3C3E49"
    },
    "& .step__content": {
      fontSize: "14px",
      marginBottom: "12px",
      fontFamily: "'DM Sans', Roboto",
      color: "#3C3E49",
      fontWeight: "400",
    },
    "& .button__wrapper": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      gap: "8px",
      "& .back__button": {
        borderRadius: "8px",
        width: "36px",
        height: "36px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        border: "solid 1px #E8DBFF",
        color: "#262626",
        fontSize: "14px",
        maxHeight: "36px"
      },
      "& .next__button": {
        backgroundColor: "#684EF3",
        color: "#fff",
        border: "none",
        padding: "8px 24px",
        cursor: "pointer",
        fontSize: "14px",
        fontWeight: 500,
        borderRadius: "8px",
        fontFamily: "'DM Sans', Roboto",
        lineHeight: "21px",
        maxHeight: "36px",
        textTransform: "capitalize",
        "&:focus-visible": {
          outline: "none",
          border: "none"
        }
      }
    }
})

const RFHDialogWrapper = styled(Dialog)({
  "& .MuiDialog-paper": {
    backgroundColor: "#FFF",
    borderRadius: "24px",
    border: "none",
    "& .MuiDialogContent-root": {
      "& .MuiDialogContentText-root" : {
        fontSize: "16px",
        fontFamily: "DM Sans, Roboto, sans-serif !important",
        marginBottom: 0,
        color: "#14101e",
      },
      padding: "24px 24px 0 !important",
    },
    "& .MuiDialogActions-root": {
      justifyContent: "center",
      margin: "24px 0",
      padding: "0 24px",
      "& .dialog__button": {
        color: "rgb(104, 78, 243)",
        borderRadius: "8px",
        fontWeight: "400",
        fontFamily: "DM Sans",
        lineHeight: "24px",
        fontSize: "16px",
        backgroundColor: "rgb(255, 255, 255)",
        padding: "10px 0",
        textAlign: "center",
        border: "1px solid rgb(104, 78, 243)",
        cursor: "pointer",
        textTransform: "capitalize",
      }
    }
  },
})

const DetailedBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column"
});

const Customimage = styled('img')({
  width: "156px",
  height: "156px",
  borderRadius: "100%",
  '&:hover': {
    filter: "brightness(0.8)",
    '&::after': {
      "content": 'Edit Picture'
    }
  }
});


const CustomPopup = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  alignItems: "center",
  cursor: "pointer",
  width: "100%",
  gap: "10px",
  '&:hover': {
    background: "lightgrey",
  }
});

const CustomRemove = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  gap: "10px",
  width: "100%",
  '&:hover': {
    background: "lightgrey",
  }
});


const CustomInput = styled('input')({
  height: "44px",
  width: "212%",
  padding: "4px 9px",
  borderRadius: "8px",
  border: "1px solid #CDD7E6",

})

const webStyles = {
  viewAlert: {
    fontFamily: "DM Sans",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "21px",
    padding: "8px",
    borderRadius: "16px",
    color: "#fff",
    backgroundColor: "#33BC55"
  },
  appbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "18px 40px"
  } as const,
  viewSearch: {
    display: "flex",
    gap: "18px",
    alignItems: "center"
  } as const,
  extension: {
    backgroundColor: "#fff",
    color: "#684EF3",
    borderRadius: "10px",
    padding: "7px 10px",
    position: "absolute",
    bottom: 0,
    right: 0
  } as const,
  viewRecentFile: {
    width: "unset",
    display: "flex",
    flexDirection: "column"
  } as const,
  iconRecent: {
    marginBottom: "24px",
    width: "90px",
    height: "90px",
  },
  notification:{
    height: "24px",
    width: "24px" 
  },
  seeAll: {
    cursor: "pointer",
    color: "#684EF3",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    fontFamily: "DM Sans"
  },
  filesCs: {
    color: "#14101E",
    fontSize: "18px",
    lineHeight: "27px",
    fontWeight: "bold",
    fontFamily: "DM Sans"
  } as const,
  containerBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "23px"
  },
  uploadFileContainer: {
    borderRadius: "16px",
    backgroundColor: "#fff",
    padding: "38px 10px 20px 10px",
    height: "220px",
    width: "unset",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  } as const,
  nameFiles: {
    marginLeft: "6px",
    fontWeight: "bold",
    fontFamily: "DM sans",
    fontSize: "14px",
    lineHeight: "21px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "80%",
    whiteSpace: "nowrap",
  } as const,
  fileEdited: {
    marginLeft: "50px",
    fontWeight: 400,
    fontFamily: "DM sans",
    fontSize: "14px",
    lineHeight: "21px",
    width: "100%"
  },
  uploadFiles: {
    display: "flex",
    alignItems: 'center',
    justifyContent: "center",
    width: "unset"
  },
  draganddropText: {
    padding: "15px 0px",
    fontWeight: 400,
    fontFamily: "DM sans",
    fontSize: "14px"
  } as const,
  browseButton: {
    fontFamily: "DM sans",
    backgroundColor: "#684EF3",
    color: "#fff",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: 400,
    textTransform: "none",
    padding: "4px 20px",
    borderRadius: "8px"
  } as const,
  uploadContainer: {
    backgroundColor: "#EAE6FF",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "212px",
    border: "2px dashed #9B93F6",
    borderRadius: "16px"
  } as const,
  tableContainer: {
    flex: "1",
    margin: "24px 40px",
  },
  pdfContainer: {
    maxHeight: "272px",
    height: "100%",
    margin: "24px 40px",
    width: "unset"
  },
  title: {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "33.6px",
    color: "#14101E",
    fontFamily: "DM Sans"
  },
};

const useStyles: Record<string, CSSProperties> = {
  searchInput: {
      border: "1px solid #E2E8F0",
      width: "304px",
      backgroundColor: "#fff",
      paddingLeft: "12px",
      borderRadius: "8px",
      paddingBottom: "10px",
      paddingTop: "10px",
  },
  searchStyle: {
      fontWeight: 400,
      fontSize: '16px',
      fontFamily: "DM Sans",
      lineHeight: '24px',
      color: "#30353B",
  },
  inputText: {
      paddingTop: "10px",
      paddingBottom: "10px",
      border: "1px solid #E2E8F0",
      paddingLeft: "12px",
      backgroundColor: "#fff",
      borderRadius: "8px",
  },
  inputTextMultiline: {
      paddingTop: "10px",
      paddingBottom: "10px",
      border: "1px solid #E2E8F0",
      paddingLeft: "12px",
      backgroundColor: "#fff",
      borderRadius: "8px",
      height: "135px"
  },
  middleside: {
    width: '600px',
    overflow: "hidden",
    position: "sticky",
    padding: '24px 32px 0px 32px',
    borderRight: '1px solid #E2E8F0',
    backgroundColor: "#E2E8F0",
    '@media (max-width: 600px)': {
      width: '100%',
      borderRight: 'none',
      borderBottom: '1px solid #E2E8F0',
    }
  },
  rightSide: {
    padding: '24px 32px',
    overflow: "auto",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxWidth: "40vw"
  },
  body: {
    width: '100%',
    height: '90vh',
    display: 'flex',
    overflow: "hidden",
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      padding: '0px 16px',
    }
  },
  leftSide: {
    width: '240px',
    position: "sticky",
    height: '100%',
    padding: '24px 20px 20px 20px',
    borderRight: '1px solid #E2E8F0',
    backgroundColor: "#f6f5f9",
    '@media (max-width: 600px)': {
      width: '100%',
      borderRight: 'none',
      borderBottom: '1px solid #E2E8F0',
    }
  },
  customdropdown: {
    borderRadius: "8px",
    fontFamily: "DM Sans",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    border: "1px solid #E2E8F0",
    backgroundColor: "#fff",
    '& .MuiFilledInput-input': {
      padding: "12px",
      height: "fit-content"
    },
    '&::before, &::after': {
      borderBottom: "none !important"
    },
    '&:hover': {
      textDecoration: "none !important"
    }
  },
  dropdownitem: {
    fontFamily: "DM Sans",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    cursor: "pointer",
  },
  openMenuButton: {
    backgroundColor: "transparent",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#EAE6FF",
    }
  },
  tab: {
      backgroundColor: "#fff",
      padding: "6px",
      border: "1px solid #D9D1EF",
      borderRadius: "12px",
      minHeight: "unset",
  },
  chosenTab: {
      backgroundColor: "#EDEAFF",
      fontFamily: 'DM Sans',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '21px',
      padding: "4px 12px",
      color: "#684EF3",
      borderRadius: "6px",
      textTransform: "capitalize",
      minWidth: "unset",
      minHeight: "unset",
  },
  normalTab: {
      backgroundColor: "#fff",
      fontFamily: 'DM Sans',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
      padding: "4px 12px",
      color: "#5E6671",
      borderRadius: "6px",
      textTransform: "capitalize",
      minWidth: "unset",
      minHeight: "unset",
  },
}
export default withStyles(useStyles)(Dashboard);
export { useStyles };
// Customizable Area End
