import React from "react";
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
export const configJSON = require("../config");

interface PDFViewerState {
  numPages: number;
  currentPage: number;
  scale: number;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  open: boolean;
  onClose: () => void;
  pdfUrl: string;
  pdfName: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  numPages: number;
  scale: number;
  currentPage: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class FileViewModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pageRefs: React.RefObject<HTMLDivElement>[] = [];
  pdfContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
  observer: IntersectionObserver | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      numPages: 1,
      scale: 1.0,
      currentPage: 1,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setupIntersectionObserver();
    // Customizable Area End
  }

  // Customizable Area Start
  onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    this.setState({ numPages });
    this.pageRefs = new Array(numPages).fill(null).map(() => React.createRef());
  };

  setupIntersectionObserver = () => {
    if (this.observer) {
      this.observer.disconnect();
    }

    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const pageNumber = entry.target.getAttribute("data-page-number");
            if (pageNumber) {
              this.setState({ currentPage: parseInt(pageNumber) });
            }
          }
        });
      },
      {
        root: this.pdfContainerRef.current,
        threshold: 0.5,
      }
    );

    this.pageRefs.forEach((ref) => {
      if (ref.current) {
        this.observer?.observe(ref.current);
      }
    });
  };

  componentDidUpdate(_prevProps: {}, prevState: PDFViewerState) {
    if (prevState.numPages !== this.state.numPages) {
      this.setupIntersectionObserver();
    }
  }

  async componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  scrollToPage = (page: number) => {
    const element = this.pageRefs[page - 1]?.current;
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  handleZoomIn = () => {
    this.setState((prev) => ({
      scale: Math.min(2.0, prev.scale + 0.1),
    }));
  };

  handleResetScale = () => {
    this.setState({ scale: 1.0 });
  };

  handleZoomOut = () => {
    this.setState((prev) => ({
      scale: Math.max(0.5, prev.scale - 0.1),
    }));
  };

  onPdfPageChange = (event: React.ChangeEvent<{ value: string }>) => {
    this.setState({ currentPage: Number(event.target.value) }, () => {
      if (this.state.currentPage && this.state.currentPage > 0) {
        this.scrollToPage(this.state.currentPage);
      }
    });
  };

  handleSetdisabled = () => {
    const { numPages } = this.state;
    if (numPages == 0 || numPages == 1) return true;
    return false;
  };

  handleModalClose = () => {
    const { onClose } = this.props;
    this.setState({
      scale: 1.0,
      currentPage: 1,
    });
    onClose();
  };
  // Customizable Area End
}
