// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent } from "react";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData } from "../../../../packages/framework/src/Utilities";
import { Message } from "../../../../packages/framework/src/Message";
import createRequestMessage from "../../../../packages/blocks/utilities/src/create-request-message";
import moment from "moment";
import * as Yup from "yup";
import { emailRegex } from "../../../blocks/utilities/src/constant";
import { FormikHelpers } from "formik";
import { navigateToScreen } from "../../../blocks/utilities/src/commonfunctions";

export const configJSON = require("./config");

const defaultStartDate = new Date()
defaultStartDate.setHours(9, 0)

const defaultEndDate = new Date()
defaultEndDate.setHours(17, 0)

export interface UserInfoResponse {
    id: string
    attributes: {
        email: string
        first_name: string
        last_name: string
        company: string
        position: string
        bio: string
        displayed_name: string
        alternate_email: string
        time_zone: string
        working_hours_from: string
        working_hours_to: string
        profile_photo: string | null
        active: boolean,
        status_visibility: string;
        contact_info_visibility: string;
    }
}

export interface WorkspaceNoti {
    name: string;
    attributes: {
        chat_message: boolean
        comments: boolean
        mentions: boolean
        invites: boolean
        access_requests: boolean
        property_changes: boolean
    }
}

export interface ChangePassword { 
    currentPassword: string;
    password: string;
    confirmPassword: string;
}

export interface Props {
    navigation: any;
    id: string;
    classes: Record<string, string>
}

export interface S {
    selectedFile: File | null,
    previewUrl: string | ArrayBuffer | null,
    showPopup: boolean,
    showText: boolean,
    toLogout: boolean;
    showPage: boolean;
    secondPage: boolean;
    firstPage: boolean;
    thirdPage: boolean;
    currentPage: 'general' | 'notifications' | 'accounts';
    isNotificationsEnabled: boolean;
    showModal: boolean;
    closeModal: boolean;
    showLogoutModal: boolean;
    closeLogoutModal: boolean;
    showEditModal:boolean;
    showChangeEmail:boolean;
    showVerifyEmail: boolean;
    currentUser?: UserInfoResponse
    loading: boolean
    emailValue: string
    bioValue: string
    startDate: Date
    endDate: Date
    timezone: string
    isTutorialEnabled: boolean
    active: string
    showButtonDialog: boolean
    isSnackbarOpen: boolean
    snackbarContent: string
    displayNameValue: string
    accountId: string
    anchorEl: Element | ((element: Element) => Element) | null | undefined
    showNotiList: boolean
    listWorkspaceNoti: Array<WorkspaceNoti>;
    openHelpSupportDialogue: boolean;
    openTermsConditionDialogue: boolean;
    activityStatus: string;
    contactInformation: string;
    error: string;
    changeEmail: string;
    changePasswordDialogue: boolean;
    snackbarErrorSeverity: boolean;
}

export interface SS {
    id: any;
}

export default class UserProfileBasicControllerWeb extends BlockComponent<
    Props,
    S,
    SS
> {
    getCurrentUserId: string = ""
    updateCurrentUserId: string = ""
    sendOtpOnChangeEmailApiId: string = ""
    verifyOtpOnChangeEmailApiId: string = ""
    resendOtpEmailApiId: string = ""
    changePasswordEmailApiId: string = ""
    deleteAccountApiId: string = ""
    activityContactInformationApiId: string = ""
    formikHelpersRef: Partial<FormikHelpers<{email: string, confirmEmail: string}>> = {};
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        runEngine.attachBuildingBlock(this, this.subScribedMessages);

        this.state = {
            selectedFile: null,
            previewUrl: null,
            showPopup: false,
            showText: false,
            toLogout: false,
            showPage: false,
            secondPage: false,
            firstPage: true,
            thirdPage: false,
            currentPage: 'general',
            isNotificationsEnabled: false,
            showModal: false,
            closeModal: false,
            showLogoutModal: false,
            closeLogoutModal: true,
            showEditModal: false,
            showChangeEmail: false,
            showVerifyEmail: false,
            currentUser: undefined,
            loading: false,
            displayNameValue: "",
            bioValue: "",
            emailValue: "",
            startDate: defaultStartDate,
            endDate: defaultEndDate,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZoneName ?? "Asia/Kolkata",
            isTutorialEnabled: false,
            active: "Active",
            showButtonDialog: false,
            isSnackbarOpen: false,
            snackbarContent: "",
            accountId: "",
            anchorEl: null,
            showNotiList: false,
            listWorkspaceNoti: [],
            openHelpSupportDialogue: false,
            openTermsConditionDialogue: false,
            activityStatus: "",
            contactInformation: "",
            error: "",
            changeEmail: "",
            changePasswordDialogue: false,
            snackbarErrorSeverity: false
        };
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                if (apiRequestCallId === this.getCurrentUserId) {
                    this.handleDataUser(responseJson.data)
                }
                if (apiRequestCallId === this.updateCurrentUserId) {
                    this.setState({ loading: false, showButtonDialog: false, isSnackbarOpen: true, snackbarContent: "Changes have been saved" })
                    this.getCurrentUser()
                }
                this.handleSendOTPOnChangeEmail(apiRequestCallId, responseJson)
                this.handleVerifyOTPOnChangeEmail(apiRequestCallId, responseJson)
                this.handleResendOtp(apiRequestCallId, responseJson)
                this.handleChangePassword(apiRequestCallId, responseJson)
                this.handleDeleteAccount(apiRequestCallId, responseJson)
                this.handleActivityStatusContactInformation(apiRequestCallId, responseJson)
            }
            this.handleError(message)
        }
        // Customizable Area End
    }

    handleSendOTPOnChangeEmail = (apiRequestCallId: string,responseJson: {message: unknown, errors: string}) => {
        if (apiRequestCallId === this.sendOtpOnChangeEmailApiId) {
            if(responseJson && responseJson?.message){
            this.setState({showChangeEmail: false, showVerifyEmail: true, error: "" })
            } else {
                this.formikHelpersRef.setErrors && this.formikHelpersRef.setErrors({email: responseJson.errors})
                this.setState({ error: responseJson.errors })
            }
        }
    }

    handleVerifyOTPOnChangeEmail = (apiRequestCallId: string,responseJson: {message: unknown, errors: string}) => {
        if (apiRequestCallId === this.verifyOtpOnChangeEmailApiId) {
            if(responseJson && responseJson?.message){
                this.setState({ isSnackbarOpen: true, snackbarContent: "Email has been changed", showChangeEmail: false , showVerifyEmail: false }, 
                    () => this.getCurrentUser()
                )
            } else {
                this.setState({ error: responseJson.errors  })
            }
        }
    } 

    handleResendOtp = (apiRequestCallId: string,responseJson: {message: unknown, errors: [{message: string}]}) => {
        if (apiRequestCallId === this.resendOtpEmailApiId) {
            if(responseJson && responseJson?.message){
                this.setState({ isSnackbarOpen: true, snackbarContent: "Otp has been send to this email address" })
            } else {
                this.setState({ error: responseJson.errors?.[0]?.message  })
            }
        }
    }
    
    handleChangePassword = (apiRequestCallId: string,responseJson: {message: unknown, error: string}) => {
        if (apiRequestCallId === this.changePasswordEmailApiId) {
            if(responseJson && responseJson?.message){
                this.setState({ changePasswordDialogue: false, isSnackbarOpen: true, snackbarContent: "Password has been changed", snackbarErrorSeverity: false })
            } else {
                this.setState({ isSnackbarOpen: true, snackbarContent: responseJson.error, snackbarErrorSeverity: true  })
            }
        }
    }

    handleDeleteAccount = (apiRequestCallId: string,responseJson: {message: string, error: string}) => {
        if (apiRequestCallId === this.deleteAccountApiId) {
            if(responseJson && responseJson?.message){
                this.setState({ changePasswordDialogue: false, isSnackbarOpen: true, snackbarContent: responseJson.message }, () => {
                    setTimeout(() => {
                        localStorage.clear();
                        // navigate to login screen
                        const loginPage = navigateToScreen("EmailAccountLoginBlock", this.props);
                        this.send(loginPage);
                    }, 600);
                })
            } else {
                this.setState({ isSnackbarOpen: true, snackbarContent: responseJson.error, snackbarErrorSeverity: true  })
            }
        }
    }

    handleActivityStatusContactInformation = (apiRequestCallId: string,responseJson: {data: string}) => {
        if (apiRequestCallId === this.activityContactInformationApiId) {
            if(responseJson && responseJson?.data){
                this.getCurrentUser()
            }
        }
    }

    handleClose = () => this.setState({ isSnackbarOpen: false, snackbarContent: "" })

    handleDataUser = (userInfo: UserInfoResponse) => {
        this.setState({
            currentUser: userInfo,
            bioValue: userInfo.attributes.bio,
            displayNameValue: userInfo.attributes.displayed_name,
            emailValue: userInfo.attributes.alternate_email,
            timezone: userInfo?.attributes.time_zone ?? Intl.DateTimeFormat().resolvedOptions().timeZoneName ?? "GMT",
            startDate: userInfo?.attributes.working_hours_from ? new Date(`01/01/1970 ${userInfo?.attributes.working_hours_from}`) : defaultStartDate, 
            endDate: userInfo?.attributes.working_hours_to ? new Date(`01/01/1970 ${userInfo?.attributes.working_hours_to}`) : defaultEndDate,
            active: userInfo?.attributes?.active ? "Active" : "Inactive",
            loading: false,
            activityStatus: userInfo?.attributes.status_visibility,
            contactInformation: userInfo?.attributes.contact_info_visibility
        })
    }

    async componentDidMount() {
        super.componentDidMount()
        this.getCurrentUser()
        this.setState({
            listWorkspaceNoti: [
                {
                    name: "LT Group Workspace",
                    attributes: {
                        access_requests: true,
                        mentions: true,
                        invites: true,
                        chat_message: true,
                        comments: true,
                        property_changes: true,
                    }
                }
            ]
        })
    }

    onChangeDisplayName = (name: string) => {
        this.setState({displayNameValue: name, showButtonDialog: true})
    }

    onChangeBio = (bio: string) => {
        this.setState({ bioValue: bio, showButtonDialog: true })
    }

    onChangeEmail = (email: string) => {
        this.setState({ emailValue: email, showButtonDialog: true })
    }

    handleError = (message: Message) => {
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if ((responseJson?.errors?.[0].token === "Token has Expired" || responseJson?.errors?.[0].token === "Invalid token") && (
            apiRequestCallId === this.getCurrentUserId 
        )) {
            this.onOkLogOutDialog()
        }
    }

    reloadProfile = () => {
        this.getCurrentUser();
        // on success of edit profile dialogue
        this.setState({isSnackbarOpen: true, snackbarContent: "Changes have been saved"})
    }

    getCurrentUser = async () => {
        const token = await getStorageData("authToken")
        const accountId = await getStorageData("account_id");
        this.setState({ loading: true, accountId })
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.getCurrentUserId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint: `${configJSON.getCurrentUserAPI}/${accountId}`
        });
    }

    onUpdateProfile = async () => {
        this.setState({ loading: true })
        const account_id = await getStorageData("account_id");
        const token = await getStorageData("authToken")

        const createBulkUploadMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.updateCurrentUserId = createBulkUploadMsg.messageId;

        createBulkUploadMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.updateUserProfileAPI}`
        );

        const header = {
            token,
        }

        const formData = new FormData();
        formData.append("data[attributes][displayed_name]", this.state.displayNameValue);
        formData.append("data[attributes][bio]", this.state.bioValue);
        formData.append("data[attributes][alternate_email]", this.state.emailValue);
        formData.append("data[attributes][time_zone]", this.state.timezone);
        formData.append("data[attributes][working_hours_from]", moment(this.state.startDate).format("HH:mm"));
        formData.append("data[attributes][working_hours_to]", moment(this.state.endDate).format("HH:mm"));
        formData.append("data[attributes][active]", String(this.state.active === "Active"));
        createBulkUploadMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        createBulkUploadMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        createBulkUploadMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");

        runEngine.sendMessage(createBulkUploadMsg.id, createBulkUploadMsg);
    }

    showPop = () => {
        if (this.state.previewUrl != null) {
            this.setState({ showPopup: true, showText: true })
        }
    }

    removePop = () => {
        this.setState({ showPopup: false, showText: false })
    }

    handleOnloadEnd = (reader: FileReader) => {
        this.setState({ previewUrl: reader.result as string });
    };

    handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            this.setState({previewUrl:URL.createObjectURL(file)})
        }
    };

    handleFileRemove = () => {
        this.setState({ previewUrl: null });
    };

    handleNotificationsToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            isNotificationsEnabled: event.target.checked, 
        });
    };

    handlePageChange = (page: 'general' | 'notifications' | 'accounts') => {
        this.setState({ currentPage: page });
    };

    handleBackToModal = () => {
        this.setState({showModal:!this.state.showModal})
    }

    handleLogoutModal = () => {
        this.setState({showLogoutModal:!this.state.showLogoutModal})
    }

    handleEditShowProfile=()=>{
        this.setState({showEditModal:!this.state.showEditModal})
    }

    handleEditShowButton = () => {
        this.setState({showButtonDialog: true})
    }

    showChangeEmail=()=>{
      this.setState({showChangeEmail:!this.state.showChangeEmail, error: ""})
    }

    showVerifyEmail=()=>{
        this.setState({showVerifyEmail:!this.state.showVerifyEmail, error: ""})
      }

    handleTutorialToggle = () => {
        this.setState({ isTutorialEnabled: !this.state.isTutorialEnabled })
    }

    onOkLogOutDialog = () => {
        removeStorageData("account_id")
        removeStorageData("authToken")
        removeStorageData("username")
        const msgType: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        msgType.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock"
        );
        msgType.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msgType);
    }

    onChangeStartDate = (date: Date | null) => {
        this.setState({ startDate: date || new Date(), showButtonDialog: true })
    }

    onChangeEndDate = (date: Date | null) => {
        this.setState({ endDate: date || new Date(), showButtonDialog: true })
    }

    onChangeTimezone = (timezone: { value: string, label: string, abbrev: string, altName: string }) => {
        this.setState({ timezone: timezone.value, showButtonDialog: true })
    }

    handleChangeActive = (event: any) => {
        this.setState({ active: event.target.value, showButtonDialog: true });
    }

    onDiscardChanges = () => {
        this.setState({
            bioValue: this.state.currentUser?.attributes?.bio ?? "",
            displayNameValue: this.state.currentUser?.attributes?.displayed_name ?? "",
            emailValue: this.state.currentUser?.attributes?.alternate_email ?? "",
            timezone: this.state.currentUser?.attributes.time_zone ?? Intl.DateTimeFormat().resolvedOptions().timeZoneName ?? "GMT",
            startDate: this.state.currentUser?.attributes.working_hours_from ? new Date(`01/01/1970 ${this.state.currentUser?.attributes.working_hours_from}`) : defaultStartDate, 
            endDate: this.state.currentUser?.attributes.working_hours_to ? new Date(`01/01/1970 ${this.state.currentUser?.attributes.working_hours_to}`) : defaultEndDate,
            active: this.state.currentUser?.attributes?.active ? "Active" : "Inactive",
            showButtonDialog: false
        })
    }

    onUpdateChanges = () => {
        this.onUpdateProfile()
    }

    onOkDeleteDialog = async () => {
        const token = await getStorageData("authToken")
        const accountId = await getStorageData("account_id");
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.deleteAccountApiId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "DELETE",
            endPoint: `${configJSON.deleteAccountApi}${accountId}`,
        });
    }

    handleNotificationsListToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            showNotiList: event.target.checked, 
        });
    };

    toggleHelpSupportDialogue = () => {
        this.setState({
            openHelpSupportDialogue: !this.state.openHelpSupportDialogue, 
        });
    };

    toggleTermsConditions = () => {
        this.setState({
            openTermsConditionDialogue: !this.state.openTermsConditionDialogue, 
        });
    };

    editProfileValidationSchema = Yup.object({
        displayName: Yup.string()
                    .required('Name is required').nullable(),
        bio: Yup.string()
                    .required('Bio is required').nullable(),
        email: Yup.string()
                    .matches(
                        emailRegex,
                        "Incorrect email format"
                    )
                    .required('Email is required').nullable(),
        timeZone: Yup.string()
                    .required('TimeZone is required'),
        startDate: Yup.string()
                    .required('Start time is required'),
        endDate: Yup.string()
                    .required('End time is required') 
                    .test(
                        "is-greater",
                        "End time must be greater than start time",
                        function (value) {
                          const { startDate } = this.parent;
                          return startDate && value ? value > startDate : true;
                        }
                      ),
    })

    changeEmailValidationSchema = Yup.object({
        email: Yup.string()
                    .matches(
                        emailRegex,
                        "Incorrect email format"
                    )
                    .required('Email is required'),
        confirmEmail: Yup.string()
                        .matches(
                            emailRegex,
                            "Incorrect email format"
                        )
                    .required('Confirm Email is required')
                    .oneOf([Yup.ref('email'), null], "Emails doesn't match")
    })

    verifyEmailOTPValidationSchema = Yup.object({
        otpNumber: Yup.string()
                    .required('Otp is required')
                    .length(5, 'OTP must be exactly 5 digits'),
    })

    handleContactInformation = (event: React.ChangeEvent<{name?:string, value: unknown}>) => {
        this.setState({
            contactInformation: event.target.value as string
        }, () => this.updateActivityStatusContactInformation(this.state.contactInformation , false))
    }

    handleActivityStatus = (event: React.ChangeEvent<{name?:string, value: unknown}>) => {
        this.setState({
            activityStatus: event.target.value as string
        }, () => this.updateActivityStatusContactInformation(this.state.activityStatus , true))
    }

    sendOTPonChangeEmail = async (values: {email: string}, formikHelpers: FormikHelpers<{email: string,confirmEmail: string}>) => {
        const token = await getStorageData("authToken")
        this.setState({ changeEmail: values?.email });
        this.formikHelpersRef = {
            setErrors: formikHelpers.setErrors,
        }
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.sendOtpOnChangeEmailApiId = request.messageId;
        const httpBody = {
            email : values.email
        }
        createRequestMessage({
            token,
            requestMessage: request,
            method: "POST",
            endPoint: `${configJSON.sendOtpOnChangeEmail}`,
            body: JSON.stringify(httpBody)
        });
    }

    verifyEmailOtp = async (values: {otpNumber: string}) => {
        const token = await getStorageData("authToken")
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.verifyOtpOnChangeEmailApiId = request.messageId;
        const httpBody = {
            email : this.state.changeEmail,
            otp: values?.otpNumber
        }
        createRequestMessage({
            token,
            requestMessage: request,
            method: "POST",
            endPoint: `${configJSON.verifyEmailOtp}`,
            body: JSON.stringify(httpBody)
        });
    }

    resendEmailOtp = async () => {
        const token = await getStorageData("authToken")
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.resendOtpEmailApiId = request.messageId;
        const httpBody = {
            email : this.state.changeEmail,
        }
        createRequestMessage({
            token,
            requestMessage: request,
            method: "POST",
            endPoint: `${configJSON.sendOtpOnChangeEmail}`,
            body: JSON.stringify(httpBody)
        });
    }

    toggleChangePasswordDialogue = () => {
        this.setState({changePasswordDialogue: !this.state.changePasswordDialogue, snackbarErrorSeverity: false})
    }

    changePasswordValidationSchema = Yup.object({
        currentPassword: Yup.string()
                            .required('Current Password is required'),
        password: Yup.string()
                     .required('Password is required')
                     .matches(
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
                        "Use at least 8 characters. Include an uppercase, lowercase and a number"
                      ),
        confirmPassword: Yup.string()
                            .required('Confirm Password is required')
                            .oneOf([Yup.ref('password'), null], "Password confirmation does not match New password")
    })

    changePassword = async (values: ChangePassword) => {
        const token = await getStorageData("authToken")
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        this.changePasswordEmailApiId = request.messageId;
        const httpBody = {
            data: {
                current_password: values.currentPassword,
                new_password: values.password,
                confirm_password: values.confirmPassword,
            }
        }
        createRequestMessage({
            token,
            requestMessage: request,
            method: "POST",
            endPoint: `${configJSON.changePasswordApi}`,
            body: JSON.stringify(httpBody)
        });
    }

    updateActivityStatusContactInformation = async (value: string , isActivityStausDropDown: boolean) => {
        const token = await getStorageData("authToken");
        const accountId = await getStorageData("account_id");
        const request = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        )

        let dropdown = {};
        if(isActivityStausDropDown)
            dropdown = `status_visibility=${value}`
        else
            dropdown = `contact_info_visibility=${value}`

        this.activityContactInformationApiId = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "PUT",
            endPoint: `${configJSON.activityStatusContactInformationApi}${accountId}/update_status_and_contact_visibility?${dropdown}`,
        });
    }
};
// Customizable Area End
