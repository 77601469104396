// Customizable Area Start
import invert from 'invert-color';

/* get colors */
export const colors = (idDark=false) => {
  const lightColors:any = {
    background: '#FFFFFF',
    primary: '#512DA8',
    text: '#121212',
    error: '#D32F2F',
    black: '#000',
    white: '#fff',
    bluePurple: "#9B93F6",
    darkPurple: "#EAE6FF",
    majorelleBlue: "#684EF3",
    tableBorder: "#E2E8F0",
    darkText: "#30353B",
    cultured: "#F6F5F9",
    grayText: "#5E6671",
    maximumRed: "#DC2626",
    chineseBlack: "#14101E",
    soapPurple: "#D9D1EF",
    labelColor: "#171B21",
    buttonHover: "#5d42ed",
    disabledColor: "#94A3B8",
    deleteButtonBg: "#FEE2E2",
    success: "#33BC55",
    warning: "#FFFFCC",
    notifyActiveBg: "#E3DEFF",
    activeBg: "#EDEAFF",
  }

  const darkColors:any = {}
  for (const colors in lightColors) {
    darkColors[colors] = invert(lightColors[colors]);
  }
  return idDark ? darkColors : lightColors;
}
// Customizable Area End