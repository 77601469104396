Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.title1="Sell or Buy products";
exports.title2="Sell / Buy products";
exports.title3=" Buy products";
exports.title4="Sell products";

exports.subtitle1="Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";
exports.subtitle2="Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native";
exports.subtitle3="Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";
exports.subtitle4="Done with React Native Onboarding Swiper Done with React Native Onboarding SwiperDone with React Native Onboarding SwiperDone with React Native Onboarding Swiper";
exports.title = "On boarding guide";
// Customizable Area End