import React from "react";
// Customizable Area Start
import AppNotificationDetailsController, {
  Props,
  configJSON,
} from "./AppNotificationDetailsController";
import { Box, styled, Typography } from "@material-ui/core";
import AppNotification from "./AppNotification.web";
import { colors } from "../../../blocks/utilities/src/Colors";
import { notificationGrayIcon, notifiySaveIcon } from "./assets";
import { renderBaseonCondition } from "../../../blocks/utilities/src/commonfunctions";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
// Customizable Area End

// Customizable Area Start
const string = configJSON.notificationString;
// Customizable Area End

export class AppNotificationDetails extends AppNotificationDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderNotificationItem = (notification: any) => {
    return (
      <Box
        key={notification.id}
        className={`notify-list-item ${notification.unRead ? "bg-unread" : ""}`}
      >
        <Box className="notify-inner-content">
          <Box className="notify-icon-box">
            <img src={notifiySaveIcon} alt="notifiySaveIcon" />
          </Box>
          <Box className="notify-content-box">
            <Typography className="notify-box-head">
              {notification.notificationText}
            </Typography>
            <Typography className="notify-para-time">
              {renderBaseonCondition(
                notification.name,
                <>
                  <span>{notification.name}:</span> {notification.action}
                </>,
                <>{notification.action}</>
              )}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography className="notify-para-time">
            {notification.time}
          </Typography>
        </Box>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { notificationDetailList } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <NotifyDetailsWrapper>
        <NavigationMenu navigation={this.props.navigation} id="audittrail" />
        <Box className="history-main-container">
          <Box className="notify-detail-header">
            <Typography className="header-title">
              {string.notifications}
            </Typography>
            <AppNotification
              navigation={this.props.navigation}
              id="navigation-details"
            />
          </Box>
          {renderBaseonCondition(
            notificationDetailList.length === 0,
            <Box className="notify-empty-container">
              <Box className="notify-container-box" data-test-id="notify-empty-block">
                <img src={notificationGrayIcon} alt="notificationGrayIcon" />
                <Typography className="notify-empty-para">
                  {string.noNotificationYet}
                </Typography>
              </Box>
            </Box>,
            <Box className="notify-detail-container" data-test-id="detail-container-box">
              <Box className="notify-detail-box">
                <Box className="notify-time-header">
                  <Typography className="header-time-title">Today</Typography>
                  <Typography
                    className="header-time-link"
                    data-test-id="notify-link"
                    onClick={this.handleMarkAsAllRead}
                  >
                    {string.markasAllread}
                  </Typography>
                </Box>
                <Box className="notify-list-box">
                  {notificationDetailList.map((notification) => {
                    return this.renderNotificationItem(notification);
                  })}
                </Box>
                <Box className="notify-time-header border-top-bottom">
                  <Typography className="header-time-title">Yesterday</Typography>
                </Box>
                <Box className="notify-list-box">
                  {notificationDetailList.slice(0, 3).map((notification) => {
                    return this.renderNotificationItem(notification);
                  })}
                </Box>
                <Box className="notify-time-header border-top-bottom">
                  <Typography className="header-time-title">
                    3rd August, 2024
                  </Typography>
                </Box>
                <Box className="notify-list-box">
                  {notificationDetailList.slice(0, 1).map((notification) => {
                    return this.renderNotificationItem(notification);
                  })}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </NotifyDetailsWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const NotifyDetailsWrapper = styled(Box)({
  background: "#f6f5f9",
  height: "100vh",
  display: "flex",
  width: "100%",
  overflow: "hidden",
  "& .history-main-container": {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  "& .notify-detail-header": {
    padding: "23px 36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${colors().tableBorder}`,
  },
  "& .header-title": {
    fontSize: "24px",
    lineHeight: "34px",
    fontFamily: "DM Sans",
    fontWeight: 400,
    color: colors().chineseBlack,
  },
  "& .notify-detail-container": {
    flex: "1 1 0",
    overflow: "auto",
    padding: "24px 42px",
  },
  "& .notify-detail-box": {
    background: colors().white,
    borderRadius: "16px",
    overflow: "hidden"
  },
  "& .notify-time-header": {
    padding: "20px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${colors().tableBorder}`,
  },
  "& .header-time-title": {
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "DM Sans",
    fontWeight: 500,
    color: colors().chineseBlack,
  },
  "& .header-time-link": {
    fontSize: "14px",
    lineHeight: "21px",
    fontFamily: "DM Sans",
    fontWeight: 400,
    color: colors().majorelleBlue,
    cursor: "pointer",
  },
  "& .notify-icon-box": {
    minWidth: "44px",
    minHeight: "44px",
    borderRadius: "50%",
    background: colors().notifyActiveBg,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .notify-list-item": {
    padding: "16px 24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .notify-content-box": {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 0"
  },
  "& .notify-para-time": {
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily: "DM Sans",
    fontWeight: 400,
    color: colors().grayText,
    "& span": {
      fontWeight: 500,
      color: colors().chineseBlack,
    },
  },
  "& .notify-box-head": {
    fontSize: "14px",
    lineHeight: "21px",
    fontFamily: "DM Sans",
    fontWeight: 700,
    color: colors().chineseBlack,
  },
  "& .notify-inner-content": {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    flex: "1 1 0"
  },
  "& .border-top-bottom": {
    borderTop: `1px solid ${colors().tableBorder}`,
    borderBottom: `1px solid ${colors().tableBorder}`,
  },
  "& .notify-empty-container": {
    flex: "1 1 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .notify-container-box": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .notify-empty-para": {
    fontSize: "14px",
    lineHeight: "21px",
    fontFamily: "DM Sans",
    fontWeight: 400,
    color: colors().grayText,
    margin: "4px 0 0 0",
  },
  "& .bg-unread": {
    background: "rgba(237, 234, 255, 0.5)",
  },
});
export default AppNotificationDetails;
// Customizable Area End
