// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { getStorageData } from "../../../framework/src/Utilities";
import createRequestMessage from "../../utilities/src/create-request-message";
import { baseURL } from "../../bulkuploading/src/BulkUploadingController";
import { CategoryObject, UpdateWorkspaceObject } from "./GeneralTabViewController";
import { UserResponse } from "./CreateWorkspaceDialogController.web";
import { commonDebounce } from "../../../blocks/utilities/src/commonfunctions";

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    id: string;
    isDialogOpen: boolean
    onCloseDialog: (data?: UpdateWorkspaceObject) => void
    classes?: Record<string, string>
    listCategories: Array<CategoryObject>
    navigation?: any;
    workspaceDetail?:any;
    // Customizable Area End
}

interface S {
    name: string
    errorName: string
    description: string
    category: string
    listCategory: Array<CategoryObject>
    subCategory: string
    listSubCategory: Array<CategoryObject>
    users: Array<UserResponse>
    listChosenUser: Array<UserResponse>
    searchText: string
}

interface SS {
}

class UpdateWorkspaceDialogController extends BlockComponent<Props, S, SS> {
    getUsersID: string = ""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            name: '',
            errorName: '',
            description: '',
            category: '',
            listCategory: [],
            subCategory: '',
            listSubCategory: [],
            users: [],
            listChosenUser: [],
            searchText: ""
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson) {
                if (apiRequestCallId === this.getUsersID) {
                    this.setState({ users: responseJson?.data ?? []})
                }
            }
        }
        // Customizable Area End
    }

    async componentDidMount(): Promise<void> {
        this.getListUser()
        const workspaceId = this.props.navigation.getParam("id");

        if(this.props.workspaceDetail){
            const {workspace_name, description, category, subcategory, workspace_members} = this.props.workspaceDetail
            const newResult:any = [];
            workspace_members.map((data:any)=>{
                newResult.push({
                    ...data.account_details.data
                });
            })
            this.setState({
                name: workspace_name,
                description,
                category,
                listCategory: this.props.listCategories,
                subCategory: subcategory,
                listChosenUser: newResult,
            })
        }
    }
    
    getListUser = async () => {
        const token = await getStorageData("authToken")
        const request = new Message(getName(MessageEnum.RestAPIRequestMessage))

        this.getUsersID = request.messageId;
        createRequestMessage({
            token,
            requestMessage: request,
            method: "GET",
            endPoint: `${baseURL}/${configJSON.searchMemberWorkspace}?term=${this.state.searchText}`
        });
    }

    closeDialog = () => {
        this.setState({
            name: '',
            errorName: '',
            description: '',
            category: '',
            listCategory: this.props.listCategories,
            subCategory: '',
            listSubCategory: [],
            listChosenUser: [],
        })
        this.props.onCloseDialog()
    }

    updateAndCloseDialog = () => {
        if (this.validationValue()) {
            const listAccountIds = this.state.listChosenUser.map(item => Number(item.id))
            const data: UpdateWorkspaceObject = {
                description: this.state.description,
                name: this.state.name,
                category: this.state.category,
                subcategory: this.state.subCategory,
                account_ids: listAccountIds
            }
            this.setState({
                name: '',
                errorName: '',
                description: '',
                category: '',
                listCategory: this.props.listCategories,
                subCategory: '',
                listSubCategory: [],
                listChosenUser: [],
            })
            this.props.onCloseDialog(data)
        }
    }

    onChangeName = (name: string) => {
        this.setState({ name })
        if (name === "") {
            this.setState({ errorName: configJSON.fieldIsNotEmpty })
        }
    }

    onChangeDescription = (description: string) => {
        this.setState({ description })
    }

    onChangeCategory = (value: string) => {
        const currentListCategory = this.state.listCategory.find((item) => item.attributes.name === value)
        const listSubCategory = currentListCategory?.attributes.sub_categories?.map((item) => ({
            id: item.id.toString(),
            attributes: {
                id: item.id,
                name: item.name
            }
        }))
        this.setState({ category: value, subCategory: "" ,listSubCategory: listSubCategory || [] })
    }

    onChangeSubCategory = (value: string) => {
        this.setState({ subCategory: value })
    }

    getCurrentCreateColor = () => {
        if (this.validationValue()) {
            return "#684EF3"
        } else return "#94A3B8"
    }

    validationValue = () => {
        if (this.state.name.trim() === "") {
            return false
        } else if (this.state.category === "") {
            return false
        } else if (this.state.listChosenUser.length === 0) {
            return false
        } else return true
    }

    handleChosenUser = (inputVal: UserResponse) => {
        const newListArray = [...this.state.users].filter((item) => item.attributes.email !== inputVal.attributes.email)
        const newListChosenArray = [...this.state.listChosenUser]
        newListChosenArray.push(inputVal)
        this.setState({ searchText: "", users: newListArray, listChosenUser: newListChosenArray })
    }

    onRemoveUser = (inputVal: UserResponse) => {
        const newListArray = [...this.state.users]
        const newListChosenArray = [...this.state.listChosenUser].filter((item) => item.attributes.email !== inputVal.attributes.email)
        newListArray.push(inputVal)
        this.setState({ searchText: "", users: newListArray, listChosenUser: newListChosenArray })
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
        if (prevProps.listCategories.length !== this.props.listCategories.length) {
            this.setState({ listCategory: this.props.listCategories})
        }
    }

    handleSearchMember = (event: React.ChangeEvent<{ value: string }>) => {
        this.setState({ searchText: event.target.value }, () => this.getListUser())
    };

    debouncedSearchMember = commonDebounce(this.handleSearchMember, 300);
}

export default UpdateWorkspaceDialogController;
// Customizable Area End
