import React from "react";
// Customizable Area Start
import AppNotificationController, {
  Props,
  configJSON,
} from "./AppNotificationController";
import { Typography, Box, IconButton, styled, Menu } from "@material-ui/core";
import { colors } from "../../../blocks/utilities/src/Colors";
import { notificationGrayIcon, notifiySaveIcon } from "./assets";
import { renderBaseonCondition } from "../../../blocks/utilities/src/commonfunctions";
// Customizable Area End

// Customizable Area Start
const string = configJSON.notificationString;
// Customizable Area End

export class AppNotification extends AppNotificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderNotificationItem = (notification: any) => {
    return (
      <Box
        key={notification.id}
        className={`main-notify-item ${notification.unRead ? "bg-unread" : ""}`}
      >
        <Box className="notify-icon-box">
          <img src={notifiySaveIcon} alt="notifiySaveIcon" />
        </Box>
        <Box className="notify-content-box">
          <Typography className="notify-box-head">
            {notification.notificationText}
          </Typography>
          <Typography className="notify-box-para">
            {renderBaseonCondition(
              Boolean(notification.name),
              <>
                <span>{notification.name}:</span> {notification.action}
              </>,
              <>{notification.action}</>
            )}
          </Typography>
          <Typography className="notify-box-para">
            {notification.time}
          </Typography>
        </Box>
      </Box>
    );
  };

  renderNotificationMenu = () => {
    const { actionMenuAnchor, notificationList } = this.state;
    const open = Boolean(actionMenuAnchor);
    return (
      <NotificationMenu
        anchorEl={actionMenuAnchor}
        id="notification-menu"
        data-test-id="notification-menu"
        open={open}
        onClose={this.handleCloseNotification}
        MenuListProps={{
          "aria-labelledby": "notification-button",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        getContentAnchorEl={null}
      >
        <Box className="notify-header">
          <Typography className="notify-heading">
            {string.notifications}
          </Typography>
          <Typography
            data-test-id="notify-link"
            className="notify-link"
            onClick={this.handleMarkAsRead}
          >
            {string.markasAllread}
          </Typography>
        </Box>
        {renderBaseonCondition(
          notificationList.length == 0,
          <Box className="notify-container">
            <Box
              className="notify-container-empty"
              data-test-id="notify-empty-block"
            >
              <img src={notificationGrayIcon} alt="notificationGrayIcon" />
              <Typography className="notify-empty-para">
                {string.noNotificationYet}
              </Typography>
            </Box>
          </Box>,
          <Box className="notify-container-box">
            {notificationList.map((notification) => {
              return this.renderNotificationItem(notification);
            })}
          </Box>
        )}
        <Box className="notify-footer">
          <Typography
            className="notify-link"
            data-test-id="notify-detail-link"
            onClick={this.handleNavigateToDetailNotify}
          >
            {string.showAllNotification}
          </Typography>
        </Box>
      </NotificationMenu>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { actionMenuAnchor } = this.state;
    const open = Boolean(actionMenuAnchor);
    // Customizable Area End
    return (
      // Customizable Area Start
      <AppNotificationWrapper>
        <IconButton
          data-test-id={`notification-button`}
          id="notification-button"
          aria-controls={open ? "notification-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={(event) => this.handleOpenNotificationmenu(event)}
          className={open ? "active-menu" : ""}
        >
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29 23.733V20.147C29 16.93 26.815 14.22 23.855 13.405C23.562 12.667 22.846 12.147 22 12.147C21.154 12.147 20.438 12.667 20.145 13.405C17.185 14.221 15 16.93 15 20.147V23.733L13.293 25.44C13.2 25.5327 13.1262 25.6429 13.0759 25.7642C13.0256 25.8855 12.9998 26.0156 13 26.147V28.147C13 28.4122 13.1054 28.6665 13.2929 28.8541C13.4804 29.0416 13.7348 29.147 14 29.147H30C30.2652 29.147 30.5196 29.0416 30.7071 28.8541C30.8946 28.6665 31 28.4122 31 28.147V26.147C31.0002 26.0156 30.9744 25.8855 30.9241 25.7642C30.8738 25.6429 30.8 25.5327 30.707 25.44L29 23.733ZM29 27.147H15V26.561L16.707 24.854C16.8 24.7613 16.8738 24.6511 16.9241 24.5297C16.9744 24.4084 17.0002 24.2783 17 24.147V20.147C17 17.39 19.243 15.147 22 15.147C24.757 15.147 27 17.39 27 20.147V24.147C27 24.413 27.105 24.667 27.293 24.854L29 26.561V27.147ZM22 32.147C22.6193 32.1478 23.2235 31.9556 23.7285 31.5971C24.2335 31.2387 24.6143 30.7318 24.818 30.147H19.182C19.3857 30.7318 19.7665 31.2387 20.2715 31.5971C20.7765 31.9556 21.3807 32.1478 22 32.147Z"
              fill="#14101E"
            />
          </svg>
          <Box className="active-circle" />
        </IconButton>
        {this.renderNotificationMenu()}
      </AppNotificationWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const AppNotificationWrapper = styled(Box)({
  "& .MuiIconButton-root": {
    minHeight: "44px",
    minWidth: "44px",
    padding: "0",
    borderRadius: "8px",
    position: "relative",
  },
  "& .active-menu": {
    background: colors().notifyActiveBg,
  },
  "& .active-circle": {
    minWidth: "9px",
    minHeight: "9px",
    background: "rgba(104, 78, 243, 1)",
    borderRadius: "50%",
    position: "absolute",
    top: "8px",
    right: "8px",
  },
});
const NotificationMenu = styled(Menu)({
  "& .MuiPopover-paper": {
    minWidth: "420px",
    maxWidth: "420px",
    width: "100%",
    minHeight: "500px",
    maxHeight: "500px",
    margin: "5px 5px 0 0",
    borderRadius: "16px",
    boxShadow: "0px 24px 48px -12px #684EF333",
    borderRight: `1px solid ${colors().tableBorder}`,
  },
  "& .MuiList-root": {
    padding: 0,
    height: "500px",
    display: "flex",
    flexDirection: "column",
  },
  "& .notify-container": {
    overflowY: "auto",
    flex: "1 1 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .notify-header": {
    padding: "8px 16px",
    borderBottom: `1px solid ${colors().tableBorder}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .notify-heading": {
    fontSize: "18px",
    lineHeight: "27px",
    fontFamily: "DM Sans",
    fontWeight: 500,
    color: colors().chineseBlack,
  },
  "& .notify-link": {
    fontSize: "14px",
    lineHeight: "21px",
    fontFamily: "DM Sans",
    fontWeight: 400,
    color: colors().majorelleBlue,
    cursor: "pointer",
  },
  "& .notify-footer": {
    padding: "8px 16px",
    borderTop: `1px solid ${colors().tableBorder}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .notify-container-empty": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  "& .notify-container-box": {
    overflowY: "auto",
    flex: "1 1 0",
    display: "flex",
    flexDirection: "column",
  },
  "& .notify-empty-para": {
    fontSize: "14px",
    lineHeight: "21px",
    fontFamily: "DM Sans",
    fontWeight: 400,
    color: colors().grayText,
    margin: "4px 0 0 0",
  },
  "& .main-notify-item": {
    padding: "12px 16px",
    display: "flex",
    alignItems: "flex-start",
    gap: "8px",
    borderBottom: `1px solid ${colors().tableBorder}`,
  },
  "& .bg-unread": {
    background: "rgba(237, 234, 255, 0.5)",
  },
  "& .notify-icon-box": {
    minWidth: "44px",
    minHeight: "44px",
    borderRadius: "50%",
    background: colors().notifyActiveBg,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .notify-content-box": {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
  },
  "& .notify-box-head": {
    fontSize: "14px",
    lineHeight: "21px",
    fontFamily: "DM Sans",
    fontWeight: 700,
    color: colors().chineseBlack,
  },
  "& .notify-box-para": {
    fontSize: "12px",
    lineHeight: "18px",
    fontFamily: "DM Sans",
    fontWeight: 400,
    color: colors().grayText,
    "& span": {
      fontWeight: 500,
      color: colors().chineseBlack,
    },
  },
});
export default AppNotification;
// Customizable Area End
