// Customizable Area Start
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import NavigationMenu from "../../../../packages/blocks/navigationmenu/src/NavigationMenu";
import { FormControl, InputAdornment, Menu, MenuItem, TextField, styled, Select, Grid, Avatar, IconButton, Dialog, DialogContent, SnackbarContent, Snackbar } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';

import WorkspaceListController, {
    Props,
    WorkspaceMember,
    WorkspaceProps,
    configJSON,
} from "./WorkspaceListController.web";
import CreateWorkspaceDialog from "./CreateWorkspaceDialog.web";
import Loader from "../../../../packages/components/src/Loader";
import { emptyBackground } from "./assets";
import UserProfileBasicBlockWeb from "../../../../packages/blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import CommonAvtar from "../../../components/src/common/CommonAvtar.web";

export const SelectComponent = styled(Select)(({ theme }) => ({
    "& .MuiSelect-select:focus": {
        backgroundColor: "rgba(0,0,0,0)",
    },
    "& .MuiSelect-selectMenu": {
        height: 0
    }
}));
// Customizable Area End

export class WorkspaceList extends WorkspaceListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderSearchInput = () => {
        return (
            <TextField
                className={this.props.classes.searchInput}
                data-test-id="searchInput"
                value={this.state.searchInput}
                onChange={this.onChangeSearchInput}
                placeholder={configJSON.search}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8 16.147C9.77498 16.1466 11.4988 15.5524 12.897 14.459L17.293 18.855L18.707 17.441L14.311 13.045C15.405 11.6466 15.9996 9.92241 16 8.14697C16 3.73597 12.411 0.146973 8 0.146973C3.589 0.146973 0 3.73597 0 8.14697C0 12.558 3.589 16.147 8 16.147ZM8 2.14697C11.309 2.14697 14 4.83797 14 8.14697C14 11.456 11.309 14.147 8 14.147C4.691 14.147 2 11.456 2 8.14697C2 4.83797 4.691 2.14697 8 2.14697Z" fill="#94A3B8" />
                            </svg>
                        </InputAdornment>
                    ),
                    disableUnderline: true,
                    className: this.props.classes.searchStyle
                }}
            />
        )
    }

    renderMenuCreate = () => {
        return (
            <div>
                <Button
                    data-test-id="open-menu"
                    className={this.props.classes.openMenuButton}
                    onClick={(event) => this.openMenu(event)}
                >
                    {configJSON.create}
                    {
                        Boolean(this.state.anchorEl)
                            ? (
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.29297 14.4239L7.70697 15.8379L12 11.5449L16.293 15.8379L17.707 14.4239L12 8.71692L6.29297 14.4239Z" fill="white" />
                                </svg>
                            )
                            : (
                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.293 8.45703L12 12.75L7.70697 8.45703L6.29297 9.87103L12 15.578L17.707 9.87103L16.293 8.45703Z" fill="white" />
                                </svg>
                            )
                    }
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.closeMenu}
                    PaperProps={{
                        style: styles.menuStyle
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <MenuItem data-test-id="create-workspace" onClick={this.onCreateWorkspace}>
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" style={styles.iconMenu}>
                            <path d="M12 5.39697H3C2.17275 5.39697 1.5 6.06972 1.5 6.89697V14.397C1.5 15.2242 2.17275 15.897 3 15.897H12C12.8273 15.897 13.5 15.2242 13.5 14.397V6.89697C13.5 6.06972 12.8273 5.39697 12 5.39697ZM3 14.397V8.39697H12V6.89697L12.0015 14.397H3Z" fill="#14101E" />
                            <path d="M16.5 3.89697C16.5 3.06972 15.8273 2.39697 15 2.39697H5.25C4.42275 2.39697 3.75 3.06972 3.75 3.89697H13.5007C14.3265 3.89697 14.9978 4.56822 15 5.39247L15.0015 11.397H15V12.897C15.8273 12.897 16.5 12.2242 16.5 11.397V6.15222L16.5007 6.14697V4.64697L16.5 4.63947V3.89697Z" fill="#14101E" />
                        </svg>
                        {configJSON.newWorkspace}
                    </MenuItem>
                    <MenuItem data-test-id="create-team" onClick={this.onCreateTeam}>
                        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg" style={styles.iconMenu}>
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.9925 6.14673C8.9925 7.39173 7.995 8.39673 6.75 8.39673C5.505 8.39673 4.5 7.39173 4.5 6.14673C4.5 4.90173 5.505 3.89673 6.75 3.89673C7.995 3.89673 8.9925 4.90173 8.9925 6.14673ZM14.2425 7.27173C14.2425 8.30673 13.41 9.14673 12.375 9.14673C11.34 9.14673 10.5 8.30673 10.5 7.27173C10.5 6.23673 11.34 5.39673 12.375 5.39673C13.41 5.39673 14.2425 6.23673 14.2425 7.27173ZM12.375 10.6467C11.0025 10.6467 8.25 11.3367 8.25 12.7092V13.6467C8.25 14.0592 8.5875 14.3967 9 14.3967H15.75C16.1625 14.3967 16.5 14.0592 16.5 13.6467V12.7092C16.5 11.3367 13.7475 10.6467 12.375 10.6467ZM1.5 12.5217C1.5 10.7742 5.0025 9.89673 6.75 9.89673C7.245 9.89673 7.875 9.97173 8.5275 10.1067C6.9975 10.9542 6.75 12.0717 6.75 12.7092V14.3967H2.25C1.8375 14.3967 1.5 14.0592 1.5 13.6467V12.5217Z" fill="#14101E" />
                        </svg>
                        {configJSON.newTeam}
                    </MenuItem>
                </Menu>
            </div>
        )
    }

    getTextColor = (isPlaceholder: boolean) => {
        if (isPlaceholder) return "#94A3B8"
        else return "#30353B"
    }

    renderDropdown = () => {
        return (
            <FormControl variant="filled">
                <SelectComponent
                    data-test-id={`selectelement`}
                    id="demo-simple-select-filled"
                    className={this.props.classes.customdropdown}
                    value={this.state.chosenCategory}
                    endAdornment={(
                        <InputAdornment position="end" className={this.props.classes.viewIconDropdown}>
                            {
                                this.state.chosenCategory !== "" && (
                                    <IconButton onClick={this.clearCategory}>
                                        <ClearIcon style={styles.iconExpand} />
                                    </IconButton>
                                )
                            }
                            <ExpandMoreIcon style={styles.iconExpand} />
                        </InputAdornment>
                    )}
                    IconComponent={() => <div />}
                    renderValue={value => this.state.chosenCategory !== "" ? value : configJSON.dropDownSpaceholder}
                    displayEmpty={true}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                    style={{ color: this.getTextColor(this.state.chosenCategory === "") }}
                >
                    {this.state.totalCategory.map((item) =>
                        <MenuItem
                            className={this.props.classes.dropdownitem}
                            key={item.name}
                            value={item.name}
                            data-test-id={`menuItem_${item.name}`}
                            onClick={() => this.changeDropdown(item.name)}
                        >
                            {item.name}
                        </MenuItem>
                    )}
                </SelectComponent>
            </FormControl>
        )
    }

    renderListWorkspace = () => {
        const { classes } = this.props
        return (
            <Grid container className={classes.viewListWorkspace}>
                {
                    this.state.workspaceList.length > 0 ?
                        (
                            this.state.workspaceList.map((item: WorkspaceProps, index: number) => (
                                <Grid
                                    key={`itemWorkspace_${index}`}
                                    container item xl={4} lg={4} md={6} sm={6} xs={12}
                                    style={styles.itemWorkspace}
                                    onClick={() => this.onClickWorkspace(item)}
                                >
                                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.itemWorkspace}>
                                        <span className={classes.titleWorkspace}>
                                            {item.workspace_name}
                                        </span>
                                        <span className={classes.descriptionWorkspace}>
                                            {item?.description}
                                        </span>
                                        <div className={classes.detailWorkspace}>
                                            <div className={classes.viewCategoryWorkspace}>
                                                <span className={classes.categoryWorkspace}>
                                                    {item?.category}
                                                </span>
                                                {item?.subcategory && <span className={classes.subCategoryWorkspace}>{item.subcategory}</span>}
                                            </div>
                                            {this.renderAvaList(item.workspace_members)}
                                        </div>
                                    </Grid>
                                </Grid>
                            ))
                        ) : (
                            <div style={{
                                margin: "10px",
                                padding: "30px",
                                color: "#14101E",
                                width: "100%",
                                fontSize: "20px",
                                fontWeight: 400,
                                fontFamily: "DM Sans",
                            }}
                            >No Workspace found
                            </div>
                        )
                }
            </Grid>
        )
    }

    renderAvaList = (listUsers: Array<WorkspaceMember>) => {
        return (
            <div className={this.props.classes.listAva}>
                {
                    listUsers.slice(0, 3).map((user: WorkspaceMember, index: number) => {
                        if (index !== 2) {
                            return (
                                <CommonAvtar
                                    key={`avaWorkspace_${index}`}
                                    src={user.account_details?.data.attributes?.profile_photo}
                                    alt={user.account_details?.data.attributes?.first_name}
                                    className={this.props.classes.ava}
                                />
                            )
                        } else {
                            return (
                                <div key={`avaWorkspace_${index}`} className={this.props.classes.avaCounter}>{`+${listUsers.length - 2}`}</div>
                            )
                        }
                    })
                }
            </div>
        )
    }

    renderEmptyWorkspace = () => (
        <div style={styles.emptyWorkspace}>
            <svg style={styles.emptyIcon} width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.6063 50.2229H4.89618C3.54973 50.2229 2.39709 49.7435 1.43825 48.7846C0.479417 47.8258 0 46.6731 0 45.3267V23.2939C0 21.9475 0.479417 20.7948 1.43825 19.836C2.39709 18.8772 3.54973 18.3977 4.89618 18.3977H34.2732C35.6197 18.3977 36.7723 18.8772 37.7312 19.836C38.2397 20.3445 38.6133 20.9075 38.8521 21.525C31.2443 24.258 25.8033 31.5359 25.8033 40.0846C25.8033 43.7921 26.8266 47.2605 28.6063 50.2229Z" fill="#D6DEEA" />
                <path d="M28.6063 50.2229H4.89618C3.54973 50.2229 2.39709 49.7435 1.43825 48.7846C0.479417 47.8258 0 46.6731 0 45.3267V23.2939C0 21.9475 0.479417 20.7948 1.43825 19.836C2.39709 18.8772 3.54973 18.3977 4.89618 18.3977H34.2732C35.6197 18.3977 36.7723 18.8772 37.7312 19.836C38.2397 20.3445 38.6133 20.9075 38.8521 21.525C31.2443 24.258 25.8033 31.5359 25.8033 40.0846C25.8033 43.7921 26.8266 47.2605 28.6063 50.2229Z" fill="#CDD7E6" />
                <path d="M44.0551 20.423C43.9624 18.5651 43.2519 16.972 41.9235 15.6436C40.4955 14.2156 38.7614 13.5016 36.7213 13.5016H9.79235V10.6017C9.79235 9.25529 10.2718 8.10265 11.2306 7.14382C12.1894 6.18498 13.3421 5.70557 14.6885 5.70557H44.0656C45.412 5.70557 46.5647 6.18498 47.5235 7.14382C48.4823 8.10265 48.9618 9.25529 48.9618 10.6017V20.6691C47.8436 20.4722 46.6931 20.3695 45.5185 20.3695C45.0263 20.3695 44.5383 20.3875 44.0551 20.423Z" fill="#D6DEEA" />
                <path d="M44.0551 20.423C43.9624 18.5651 43.2519 16.972 41.9235 15.6436C40.4955 14.2156 38.7614 13.5016 36.7213 13.5016H9.79235V10.6017C9.79235 9.25529 10.2718 8.10265 11.2306 7.14382C12.1894 6.18498 13.3421 5.70557 14.6885 5.70557H44.0656C45.412 5.70557 46.5647 6.18498 47.5235 7.14382C48.4823 8.10265 48.9618 9.25529 48.9618 10.6017V20.6691C47.8436 20.4722 46.6931 20.3695 45.5185 20.3695C45.0263 20.3695 44.5383 20.3875 44.0551 20.423Z" fill="#CDD7E6" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M59.2678 58.2947L53.1515 52.258C52.0367 52.9481 50.829 53.479 49.5284 53.8506C48.2279 54.2222 46.8874 54.408 45.5072 54.408C41.5258 54.408 38.1416 53.0145 35.3547 50.2275C32.5677 47.4405 31.1742 44.0563 31.1742 40.0749C31.1742 36.0935 32.5677 32.7094 35.3547 29.9224C38.1416 27.1354 41.5258 25.7419 45.5072 25.7419C49.4886 25.7419 52.8728 27.1354 55.6598 29.9224C58.4468 32.7094 59.8403 36.0935 59.8403 40.0749C59.8403 41.5082 59.6412 42.8752 59.243 44.1758C58.8449 45.4764 58.3008 46.6841 57.6107 47.7989L63.727 53.8355L59.2678 58.2947ZM45.505 48.0367C47.7346 48.0367 49.6191 47.267 51.1586 45.7275C52.6981 44.188 53.4678 42.3035 53.4678 40.0739C53.4678 37.8443 52.6981 35.9598 51.1586 34.4204C49.6191 32.8809 47.7346 32.1111 45.505 32.1111C43.2754 32.1111 41.3909 32.8809 39.8514 34.4204C38.3119 35.9598 37.5422 37.8443 37.5422 40.0739C37.5422 42.3035 38.3119 44.188 39.8514 45.7275C41.3909 47.267 43.2754 48.0367 45.505 48.0367Z" fill="#D6DEEA" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M59.2678 58.2947L53.1515 52.258C52.0367 52.9481 50.829 53.479 49.5284 53.8506C48.2279 54.2222 46.8874 54.408 45.5072 54.408C41.5258 54.408 38.1416 53.0145 35.3547 50.2275C32.5677 47.4405 31.1742 44.0563 31.1742 40.0749C31.1742 36.0935 32.5677 32.7094 35.3547 29.9224C38.1416 27.1354 41.5258 25.7419 45.5072 25.7419C49.4886 25.7419 52.8728 27.1354 55.6598 29.9224C58.4468 32.7094 59.8403 36.0935 59.8403 40.0749C59.8403 41.5082 59.6412 42.8752 59.243 44.1758C58.8449 45.4764 58.3008 46.6841 57.6107 47.7989L63.727 53.8355L59.2678 58.2947ZM45.505 48.0367C47.7346 48.0367 49.6191 47.267 51.1586 45.7275C52.6981 44.188 53.4678 42.3035 53.4678 40.0739C53.4678 37.8443 52.6981 35.9598 51.1586 34.4204C49.6191 32.8809 47.7346 32.1111 45.505 32.1111C43.2754 32.1111 41.3909 32.8809 39.8514 34.4204C38.3119 35.9598 37.5422 37.8443 37.5422 40.0739C37.5422 42.3035 38.3119 44.188 39.8514 45.7275C41.3909 47.267 43.2754 48.0367 45.505 48.0367Z" fill="#CDD7E6" />
            </svg>
            <span style={styles.emptyText}>{configJSON.emptyText}</span>
            <div style={styles.buttonCreate} onClick={this.onCreateWorkspace}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 11H13V5H11V11H5V13H11V19H13V13H19V11Z" fill="white" />
                </svg>
                <span style={styles.createEmptyText}>{configJSON.createWorkspace}</span>
            </div>
        </div>
    )
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <div className={this.props.classes.mainContainer}>
                <div className={this.props.classes.navView}>
                    <NavigationMenu navigation={this.props.navigation} id="workspace" />
                </div>

                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={this.state.openSnackBar}
                    onClose={this.handleCloseSnackbar}
                    key={"top" + "center"}
                >
                    <SnackbarContent
                        message={this.state.snackbarMessage}
                        style={{ backgroundColor: 'red', borderRadius: "8px" }}
                        action={
                            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleCloseSnackbar}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        }
                    />
                </Snackbar>

                    <div className={this.props.classes.container}>
                        <div className={this.props.classes.viewHeaderText}>
                            <span className={this.props.classes.headerText}>
                                {configJSON.workSpace}
                            </span>
                            <svg className={this.props.classes.notiIcon} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 13.733V10.147C19 6.92997 16.815 4.21997 13.855 3.40497C13.562 2.66697 12.846 2.14697 12 2.14697C11.154 2.14697 10.438 2.66697 10.145 3.40497C7.185 4.22097 5 6.92997 5 10.147V13.733L3.293 15.44C3.19996 15.5327 3.12617 15.6429 3.07589 15.7642C3.0256 15.8855 2.99981 16.0156 3 16.147V18.147C3 18.4122 3.10536 18.6665 3.29289 18.8541C3.48043 19.0416 3.73478 19.147 4 19.147H20C20.2652 19.147 20.5196 19.0416 20.7071 18.8541C20.8946 18.6665 21 18.4122 21 18.147V16.147C21.0002 16.0156 20.9744 15.8855 20.9241 15.7642C20.8738 15.6429 20.8 15.5327 20.707 15.44L19 13.733ZM19 17.147H5V16.561L6.707 14.854C6.80004 14.7613 6.87383 14.6511 6.92412 14.5297C6.9744 14.4084 7.00019 14.2783 7 14.147V10.147C7 7.38997 9.243 5.14697 12 5.14697C14.757 5.14697 17 7.38997 17 10.147V14.147C17 14.413 17.105 14.667 17.293 14.854L19 16.561V17.147ZM12 22.147C12.6193 22.1478 13.2235 21.9556 13.7285 21.5971C14.2335 21.2387 14.6143 20.7318 14.818 20.147H9.182C9.38566 20.7318 9.76648 21.2387 10.2715 21.5971C10.7765 21.9556 11.3807 22.1478 12 22.147Z" fill="#14101E" />
                            </svg>
                        </div>
                            {
                                (this.state.workspaceList.length === 0  && this.state.searchInput.length === 0)
                                    ? this.renderEmptyWorkspace()
                                    : (
                                        <div style={styles.viewWorkspace}>
                                            <div className={this.props.classes.filterGroup}>
                                                <div className={this.props.classes.buttonGroup}>
                                                    {this.renderSearchInput()}
                                                    {this.renderDropdown()}
                                                </div>
                                                {this.renderMenuCreate()}
                                            </div>
                                            {this.renderListWorkspace()}
                                        </div>
                                    )
                            }
                        <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"center" }}
          open={this.state.isSuccess}
          onClose={this.handleSuccessClose}
          key={"top" + "center"}
        >
          <SnackbarContent
            message={this.state.isSuccessMessage}
            style={{ backgroundColor: '#33BC55',borderRadius:"8px" }}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSuccessClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"center" }}
          open={this.state.isError}
          onClose={this.handleErrorClose}
          key={"top" + "center"}
        >
          <SnackbarContent
            message={this.state.isErrorMessage}
            style={{ backgroundColor: '#DC2626',borderRadius:"8px" }}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleErrorClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Snackbar>
                        <Dialog
                open={this.state.isAcceptWorksapce}
                aria-labelledby="customized-dialog-title"
                fullWidth
                PaperProps={{
                    style: styles.viewDialog
                }}
            >
                <DialogContent
                    className="dialog-address-content"
                    style={{ padding: 0 }}
                >
                    <div style={styles.textTitle}>
                        {configJSON.workspaceInviteText}
                    </div>
                    <div style={styles.textDescription} dangerouslySetInnerHTML={{ __html: configJSON.workspaceInviteDescription }}/>
                    <div style={styles.viewButton}>
                        <div
                            data-test-id="cancelButton"
                            onClick={this.onOkCloseDialog}
                            style={styles.cancelButton}
                        >
                            Accept
                        </div>
                        <div
                            data-test-id="okButton"
                            onClick={this.onCloseRemoveDialog}
                            style={styles.okButton}
                        >
                            Decline
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
                        <CreateWorkspaceDialog
                            id="CreateWorkspaceDialog"
                            isDialogOpen={this.state.isCreateDialogOpen}
                            classes={this.props.classes}
                            onCloseDialog={this.onCloseCreateDialog}
                            listCategories={[...this.state.listCategories]}
                        />
                        {/* <Loader loading={this.state.loading} /> */}
                    </div>
            </div>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const styles = {
    viewDialog: {
        borderRadius: '24px',
        border: '0px solid #D0D2DA',
        maxHeight: "50%",
        backgroundColor: "#fff"
    },
    textTitle: {
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "33.6px",
        padding: "24px 32px",
        borderBottom: '1px solid #F6F5F9',
        backgroundColor: "#fff"
    } as React.CSSProperties,
    textDescription: {
        color: "#14101E",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        padding: "0 32px",
        marginTop: "24px"
    },
    viewButton: {
        display: "flex",
        gap: "16px",
        marginTop: "24px",
        marginBottom: "32px",
        padding: "0 32px"
    },
    cancelButton: {
        borderRadius: "8px",
        color: "#684EF3",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        paddingTop: "10px",
        paddingBottom: "10px",
        backgroundColor: "#fff",
        border: "1px solid #684EF3",
        width: "100%",
        textAlign: "center",
        cursor: "pointer"
    } as React.CSSProperties,
    okButton: {
        borderRadius: "8px",
        color: "#ff0000",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        paddingTop: "10px",
        paddingBottom: "10px",
        backgroundColor: "#FEE2E2",
        width: "100%",
        textAlign: "center",
        cursor: "pointer"
    } as React.CSSProperties,
    buttonCreate: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
        padding: "10px 16px",
        backgroundColor: "#684EF3",
        borderRadius: "8px",
        marginTop: "24px",
        cursor: "pointer"
    } as React.CSSProperties,
    createEmptyText: {
        color: "#fff",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400
    },
    emptyIcon: {
        width: "64px",
        height: "64px",
    },
    emptyText: {
        color: "#5E6671",
        fontFamily: "DM Sans",
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 400
    },
    emptyWorkspace: {
        display: "flex",
        flexDirection: 'column',
        alignItems: "center",
        justifyContent: "center",
        background: `url(${emptyBackground}) no-repeat center center fixed`,
        height: "78vh",
        border: "1px solid #E2E8F0",
        borderRadius: "16px",
        margin: "20px 40px"
    } as React.CSSProperties,
    viewWorkspace: {
        display: "flex",
        flexDirection: 'column',
        overflow: "auto",
    } as React.CSSProperties,
    menuStyle: {
        borderRadius: "8px",
    },
    iconMenu: {
        marginRight: "8px",
        cursor: "pointer",
    },
    iconExpand: {
        color: "#30353B",
        width: "16px",
        height: "16px",
        cursor: "pointer"
    },
    itemWorkspace: {
        paddingRight: "20px",
        cursor: "pointer"
    },
}

const useStyles: Record<string, CSSProperties> = {
    viewHeaderText: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "23px 0px",
        margin: "0 40px",
        borderBottom: "1px solid #E2E8F0",
    },
    mainContainer: {
        display: "flex",
        width: "100%",
        height: "100%",
        backgroundColor: "#f6f5f9"
    },
    navView: {
        position: "sticky"
    },
    container: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f6f5f9",
        width: "100%",
    },
    headerText: {
        fontFamily: "DM Sans",
        fontSize: '24px',
        lineHeight: '33.6px',
        color: "#14101E",
        width: "100%",
        fontWeight: 400
    },
    filterGroup: {
        display: "flex",
        marginTop: "20px",
        paddingLeft: "40px",
        paddingRight: "40px",
        justifyContent: 'space-between'
    },
    buttonGroup: {
        display: "flex",
        alignItems: 'center',
        gap: "12px"
    },
    searchInput: {
        border: "1px solid #E2E8F0",
        width: "288px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "12px",
        backgroundColor: "#fff",
        borderRadius: "8px",
    },
    inputText: {
        border: "1px solid #E2E8F0",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "12px",
        backgroundColor: "#fff",
        borderRadius: "8px",
    },
    searchStyle: {
        fontWeight: 400,
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        color: "#30353B",
    },
    openMenuButton: {
        fontWeight: 400,
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        color: "#fff",
        textTransform: "capitalize",
        backgroundColor: "#684EF3",
        borderRadius: "8px",
        padding: "10px 16px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#684EF3",
        }
    },
    customdropdown: {
        borderRadius: "8px",
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        border: "1px solid #E2E8F0",
        backgroundColor: "#fff",
        '& .MuiFilledInput-input': {
            padding: "12px",
            height: "fit-content"
        },
        '&::before, &::after': {
            borderBottom: "none !important"
        },
        '&:hover': {
            textDecoration: "none !important"
        }
    },
    dropdownitem: {
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        cursor: "pointer",
    },
    viewListWorkspace: {
        padding: "20px 20px 20px 40px",
        width: "100%"
    },
    itemWorkspace: {
        backgroundColor: "#fff",
        borderRadius: "16px",
        display: "flex",
        flexDirection: "column",
        padding: "16px 24px",
        marginBottom: "20px"
    },
    titleWorkspace: {
        fontWeight: 700,
        fontFamily: "DM Sans",
        fontSize: '18px',
        lineHeight: '27px',
        color: "#14101E",
    },
    descriptionWorkspace: {
        fontWeight: 400,
        fontFamily: "DM Sans",
        fontSize: '14px',
        lineHeight: '21px',
        color: "#30353B",
        marginTop: "4px",
        minHeight: "45px",
        overflow: "hidden",
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical'
    },
    detailWorkspace: {
        marginTop: "10px",
        padding: "16px 0",
        display: "flex",
        justifyContent: "space-between",
        borderTop: "1px solid #E2E8F0",
        alignItems: "center"
    },
    viewCategoryWorkspace: {
        display: 'flex',
        gap: "6px"
    },
    categoryWorkspace: {
        backgroundColor: "#E6E2FB",
        padding: "4px 8px",
        color: "#684EF3",
        fontWeight: 400,
        fontFamily: "DM Sans",
        fontSize: '12px',
        lineHeight: '18px',
        borderRadius: "16px"
    },
    subCategoryWorkspace: {
        borderRadius: "16px",
        backgroundColor: "#F6F5F9",
        padding: "4px 8px",
        fontWeight: 400,
        fontFamily: "DM Sans",
        fontSize: '12px',
        lineHeight: '18px',
        color: "#30353B"
    },
    listAva: {
        display: 'flex',
    },
    ava: {
        width: "32px",
        height: "32px",
        borderRadius: "32px",
        border: "1px solid #D9D1EF",
        marginRight: "-4px"
    },
    avaCounter: {
        width: "32px",
        height: "32px",
        borderRadius: "32px",
        border: "1px solid #D9D1EF",
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "#E6E2FB",
        display: "flex",
        color: "#684EF3",
        fontWeight: 400,
        fontFamily: "DM Sans",
        fontSize: '12px',
        lineHeight: '18px',
    },
    inputView: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        marginTop: "28px",
        padding: "0 32px"
    },
    viewButton: {
        display: "flex",
        gap: "16px",
        padding: "0 32px",
        marginBottom: "40px"
    },
    cancelButton: {
        padding: "10px 26px",
        border: "1px solid #684EF3",
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#684EF3",
        backgroundColor: "#fff",
        borderRadius: "8px",
        cursor: "pointer"
    },
    createButton: {
        padding: "10px 26px",
        borderRadius: "8px",
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#fff",
        width: "100%",
        textAlign: "center",
        cursor: "pointer"
    },
    optionAutocomplete: {
        display: "flex",
        padding: "12px",
        backgroundColor: "#f6f5f9",
        alignItems: "center",
        borderRadius: "8px",
        width: "100%",
        ".MuiAutocomplete-option[data-focus='true']:has(&)": {
            backgroundColor: "transparent"
        }
    },
    optionAvaAutocomplete: {
        width: "44px",
        height: "44px",
        borderRadius: "44px",
        border: "2px solid #D9D1EF",
        marginRight: "12px",
    },
    viewContentOption: {
        display: "flex",
        flexDirection: "column",
        gap: 0,
        width: "100%"
    },
    titleDropDownAutocomplete: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "21px",
        color: "#14101E",
        margin: 0
    },
    descriptionDropDownAutocomplete: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        color: "#30353B",
        margin: 0
    },
    titleText: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "21px",
        color: "#30353B",
    },
    removeButton: {
        alignItems: "center",
        cursor: "pointer",
        gap: "4px",
        fontWeight: 400,
        color: "#30353B",
        display: "flex",
        fontFamily: "DM Sans",
        lineHeight: "21px",
        padding: "4px 12px",
        borderRadius: "26px",
        fontSize: "14px",
        marginRight: "12px",
        backgroundColor: "#fff",
    },
    viewIconDropdown: {
        alignItems: "center",
        gap: "4px",
        display: "flex",
    },
    viewListUser: {
        width: "calc(100% - 24px)",
        marginBottom: "16px",
        marginTop: "16px",
        flexDirection: "column",
        gap: "8px",
        display: 'flex',
    },
}

export default withStyles(useStyles)(WorkspaceList)
// Customizable Area End
