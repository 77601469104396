export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const uploadIcon = require("../assets/upload.png");
export const fileIcon = require("../assets/fileIcon.svg");
export const avtarIcon = require("../assets/avtarIcon.png");
export const downloadFilIcon = require("../assets/downloadIcon.svg");
export const resumeImg = require("../assets/resume.svg");
export const deleteImg = require("../assets/delete.svg");

export const avatarImg = require("../assets/avatar.png");
export const download = require("../assets/ic_download.svg");
export const share = require("../assets/ic_share.svg");
export const regenerate = require("../assets/ic_regenerate.svg");
export const logoWhite = require("../assets/ic-white-logo.svg");
export const logoBlack = require("../assets/ic-black-logo.svg");
export const doubleRightIcon = require("../assets/ic-double-right-black.svg");
export const doubleLeftIcon = require("../assets/ic-double-left-white.svg");
export const submitIcon = require("../assets/submit_icon.png");

export const checked = require("../assets/radio_checked.svg");
export const unchecked = require("../assets/radio_unchecked.svg");
export const searchIcon = require("../assets/searchIcon.svg");
export const notificationIcon = require("../assets/notification__icon.svg");
export const workspaceIcon = require("../assets/workspace__icon.svg");