// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
// import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import Cfpromptingcustomquery1 from "../../blocks/cfpromptingcustomquery1/src/Cfpromptingcustomquery1";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword.web";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlockWeb from "../../blocks/user-profile-basic/src/UserProfileBasicBlock.web";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Notifications from "../../blocks/notifications/src/Notifications";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailVerification from "../../blocks/email-account-registration/src/EmailVerification.web";
import Confirmation from "../../blocks/email-account-registration/src/Confirmation";
import FactorAuthentication from "../../blocks/email-account-registration/src/FactorAuthentication.web"
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import ViewChat from "../../blocks/chat/src/ViewChat";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web"; 
import DashboardAllFiles from "../../blocks/dashboard/src/DashboardAllFiles.web"; 
import Chat from "../../blocks/chat/src/Chat.web"
import WorkspaceList from "../../blocks/accountgroups/src/WorkspaceList.web";
import DetailWorkspaceTabView from "../../blocks/accountgroups/src/DetailWorkspaceTabView.web";
import GeneralTabAllFiles from '../../blocks/accountgroups/src/GeneralTabAllFiles.web';
import NeeveDashboard from "../../blocks/cfpromptingcustomquery1/src/NeeveDashboard.web";
import NeeveQuery from "../../blocks/cfpromptingcustomquery1/src/NeeveQuery.web";
import SsoLogin from "../../blocks/social-media-account/src/SsoLogin.web";
import AppNotificationDetails from "../../blocks/notifications/src/AppNotificationDetails.web";
import AuditTrailHistory from "../../blocks/audittrail/src/components/AuditTrailHistory.web";

const routeMap = {
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Cfpromptingcustomquery1:{
 component:Cfpromptingcustomquery1,
path:"/Cfpromptingcustomquery1"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
FactorAuthentication:{
  component:FactorAuthentication,
  path:"/FactorAuthentication"
},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlockWeb,
path:"/UserProfileBasicBlock"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailVerification:{
  component:EmailVerification,
 path:"/EmailVerification"},
 Confirmation:{
  component:Confirmation,
 path:"/Confirmation"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/",exact:true},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Chats:{
 component:Chat,
path:"/Chats"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

Dashboard:{
  component:Dashboard,
  path:"/Dashboard",
},
DashboardAllFiles:{
  component:DashboardAllFiles,
  path:"/DashboardAllFiles",
},
WorkspaceList:{
  component: WorkspaceList,
  path:"/WorkspaceList",
},
SsoLogin:{
  component: SsoLogin,
  path:"/SsoLogin",
},
DetailWorkspaceTabView:{
  component: DetailWorkspaceTabView,
  path:"/DetailWorkspaceTabView/:id",
},
AuditTrailHistory:{
  component:AuditTrailHistory,
  path:"/GeneralTabAllFiles/:id/allInteractions",
},
NeeveDashboard: {
  component: NeeveDashboard,
  path: "/NeeveDashboard"
},
AppNotificationDetails: {
  component: AppNotificationDetails,
  path: "/AppNotificationDetails"
},
NeeveQuery: {
  component: NeeveQuery,
  path: "/NeeveQuery/:navigationBarTitleText"
}


  // Home: {
  //   component: HomeScreen,
  //   path: '/',
  //   exact: true
  // },
  // InfoPage: {
  //   component: InfoPage,
  //   path: '/InfoPage'
  // },

  // AlertWeb: {
  //   component: AlertBlock,
  //   path: "*/AlertWeb",
  //   modal: true
  // }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <BuilderProvider>
        <View style={{ height: '100vh'}}>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;