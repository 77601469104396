// Customizable Area Start
import React from "react";
import ViewProfileDrawerController from "./ViewProfileDrawerController";
import {DialogContent, Drawer, IconButton, Snackbar, SnackbarContent, styled } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { renderBaseonCondition } from "../../../blocks/utilities/src/commonfunctions";


export class ViewProfileDrawer extends ViewProfileDrawerController {

    render() {
        const { isDialogOpen, } = this.props;

        const renderClosecon = () => {
            return (
              <div onClick={this.closeDialog}>
                <svg
                  data-test-id="closeIcon"
                  className="closeIcon"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.192 10.3438L15.949 14.5858L11.707 10.3438L10.293 11.7578L14.535 15.9998L10.293 20.2418L11.707 21.6558L15.949 17.4137L20.192 21.6558L21.606 20.2418L17.364 15.9998L21.606 11.7578L20.192 10.3438Z"
                    fill="#30353B"
                  />
                </svg>
              </div>
            );
          };

          const renderBg = () => {
            return (
              <div className="bgSquare">
                <svg
                  width="209"
                  height="156"
                  viewBox="0 0 209 156"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.65" clipPath="url(#clip0_23995_67835)">
                    <path d="M253 59L180 59" stroke="#D6DEEA" stroke-width="2" />
                    <path d="M253 59L180 59" stroke="#CDD7E6" stroke-width="2" />
                    <path
                      d="M325 131L254 131L180 131C165.941 131 108 131 108 131M74 131L36 131"
                      stroke="#D6DEEA"
                      stroke-width="2"
                    />
                    <path
                      d="M325 131L254 131L180 131C165.941 131 108 131 108 131M74 131L36 131"
                      stroke="#CDD7E6"
                      stroke-width="2"
                    />
                    <path
                      d="M289 97L218 97L180 97C165.941 97 108 97 108 97"
                      stroke="#D6DEEA"
                      stroke-width="2"
                    />
                    <path
                      d="M289 97L218 97L180 97C165.941 97 108 97 108 97"
                      stroke="#CDD7E6"
                      stroke-width="2"
                    />
                    <path
                      d="M181 167V348M181 60V132"
                      stroke="#D6DEEA"
                      stroke-width="2"
                    />
                    <path
                      d="M181 167V348M181 60V132"
                      stroke="#CDD7E6"
                      stroke-width="2"
                    />
                    <path
                      d="M145 238V348M145 96V132"
                      stroke="#D6DEEA"
                      stroke-width="2"
                    />
                    <path
                      d="M145 238V348M145 96V132"
                      stroke="#CDD7E6"
                      stroke-width="2"
                    />
                    <path
                      d="M109 238V349M109 96C109 96 109 104.078 109 132"
                      stroke="#D6DEEA"
                      stroke-width="2"
                    />
                    <path
                      d="M109 238V349M109 96C109 96 109 104.078 109 132"
                      stroke="#CDD7E6"
                      stroke-width="2"
                    />
                    <path d="M73 384V276V132" stroke="#D6DEEA" stroke-width="2" />
                    <path d="M73 384V276V132" stroke="#CDD7E6" stroke-width="2" />
                    <path
                      d="M37 276V420M37 132V240"
                      stroke="#D6DEEA"
                      stroke-width="2"
                    />
                    <path
                      d="M37 276V420M37 132V240"
                      stroke="#CDD7E6"
                      stroke-width="2"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_23995_67835">
                      <rect width="209" height="156" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            );
          };

        return (
            <MainDrawer
                anchor="right"
                data-test-id="profileViewDrawer"
                open={isDialogOpen}
                onClose={this.closeDialog}
                PaperProps={{
                    style: webStyles.viewDialog,
                }}
                >
                <DialogContent
                    className="dialog-address-content"
                    style={{ padding: 0 }}
                >
                    <div style={webStyles.titlesection}>
                        {renderClosecon()}
                        {renderBg()}
                    <div style={{ position: 'relative', display: 'inline-block', ...webStyles.uploadedContainer }}>
    <img
        src={this.state.userData.profile_photo}
        style={webStyles.uploadedimg}
    />
    <div style={{ position: 'absolute', top: '5px', right: '5px' }}>
      {renderBaseonCondition(
        this.handleShowActiveTag(),
        <>
        {renderBaseonCondition(
          this.state.userData.active,
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" fill="#684EF3" />
            <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" stroke="#F6F5F9" stroke-width="3" />
          </svg>,
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" fill="#94A3B8" />
            <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" stroke="#F6F5F9" stroke-width="3" />
          </svg>
        )}
        </>,
        <></>
      )}
    </div>
</div>

                                <div style={webStyles.viewTitleInput}>
                        <span style={webStyles.userNameTextInput}>{this.state.userData.first_name}{' '}{this.state.userData.last_name}</span>
                        <span style={webStyles.bioTextInput}>{this.state.userData.position} at {this.state.userData.company}</span>
                        <div
                        data-test-id="continueButton"
                        style={webStyles.okButton}
                        onClick={this.closeDialog}
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 0H2C0.897 0 0 0.897 0 2V14C0 15.103 0.897 16 2 16H5V19.767L11.277 16H18C19.103 16 20 15.103 20 14V2C20 0.897 19.103 0 18 0ZM18 14H10.723L7 16.233V14H2V2H18V14Z" fill="white"/>
</svg>

                        <span style={{marginLeft:'8px'}}>Chat</span>
                    </div>
                    </div>

                    </div>
                    <div style={{paddingTop:"30px"}}>
                    <div className="viewInput">
                        <span style={webStyles.titleTextInput}>User name</span>
                        <span style={webStyles.valueTextInput}><span style={{color:'#684EF3'}}>@</span>{this.state.userData.displayed_name}</span>
                       
                    </div>
                    <div className="viewInput">
                        <span style={webStyles.titleTextInput}>Bio</span>
                        <span style={webStyles.valueTextInput}>{this.state.userData.bio}</span>
                    </div>
                      {renderBaseonCondition(
                        this.handleShowEmailOnBaseVisiblity(),
                        <div className="viewInput">
                          <span style={webStyles.titleTextInput}>Email</span>
                          <div style={{display:"flex",textAlign:'center', alignItems: "center"}}>
                            <span style={webStyles.valueTextInput}>
                              {this.state.userData.email} 
                            </span>
                            <span style={{ display: "inline-flex", marginLeft: "8px", cursor: "pointer" }} onClick={this.handleCopyToClipboard}>
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.668 1.66699H8.33464C7.41547 1.66699 6.66797 2.41449 6.66797 3.33366V6.66699H3.33464C2.41547 6.66699 1.66797 7.41449 1.66797 8.33366V16.667C1.66797 17.5862 2.41547 18.3337 3.33464 18.3337H11.668C12.5871 18.3337 13.3346 17.5862 13.3346 16.667V13.3337H16.668C17.5871 13.3337 18.3346 12.5862 18.3346 11.667V3.33366C18.3346 2.41449 17.5871 1.66699 16.668 1.66699ZM3.33464 16.667V8.33366H11.668L11.6696 16.667H3.33464ZM16.668 11.667H13.3346V8.33366C13.3346 7.41449 12.5871 6.66699 11.668 6.66699H8.33464V3.33366H16.668V11.667Z" fill="#94A3B8"/>
                              </svg>
                            </span>
                          </div>
                        </div>,
                        <></>
                      )}
                    <div className="viewInput">
                        <span style={webStyles.titleTextInput}>Time zone</span>
                        <span style={webStyles.valueTextInput}>{this.state.userData.time_zone}</span>
                        <span style={webStyles.timeTextInput}>Curent time : {this.state.currentTime}</span>
                    </div>
                    <div className="viewInput">
                        <span style={webStyles.titleTextInput}>Working Hours</span>
                        <span style={webStyles.valueTextInput}>{this.state.userData.working_hours_from} - {this.state.userData.working_hours_to}</span>
                    </div>
                    </div>
                </DialogContent>
                <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"center" }}
          open={this.state.isEmailCopied}
          onClose={this.handleClose}
          key={"top" + "center"}
        >
          <SnackbarContent
            message="Email has been copied"
            style={{ backgroundColor: '#33BC55',borderRadius:"8px" }}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Snackbar>
            </MainDrawer>

        );
    }
}

const MainDrawer = styled(Drawer)({
    "& .bgSquare": {
        position: "absolute",
        right: 0
    },
    "& .closeIcon": {
        width: "32px",
        height: "32px",
        position: "absolute",
        left: "auto",
        right: 32,
        zIndex:9999,
        cursor: "pointer"
    },
    "& .viewInput": {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        padding: "0 40px",
        margin: "0 0 24px 0"
    }
});

const webStyles = {
    viewDialog: {
        border: '0px solid #D0D2DA',
        height:"100vh",
        maxWidth: "560px",
        width: "100%",
        backgroundColor: "#fff"
    } as React.CSSProperties,
    titlesection: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "33.6px",
        padding: "24px 32px",
        borderBottom: '1px solid #F6F5F9',
        backgroundColor: "#E2E8F0",
        position: "sticky"
    } as React.CSSProperties,
    uploadedContainer: {
        backgroundColor: "#EAE6FF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        alignItems: "left",
        border: "4px solid #FFFFFF",
        borderRadius: "136px",
        height:'132px',
        width:"132px",
        gap: "8px",
    } as React.CSSProperties,
    uploadedimg: {
        width: "132px",
        height: "132px",
        cursor: "pointer",
        borderRadius:"136px"
    },
    viewTitleInput: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        padding: "0 22px",
    } as React.CSSProperties,
    titleTextInput: {
        fontFamily: "DM Sans",
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#171B21"
    },
    userNameTextInput: {
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "24px",
        lineHeight: "33.6px",
        color: "#14101E"
    },
    bioTextInput: {
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#5E6671"
    },
    valueTextInput: {
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#171B21"
    },
    timeTextInput: {
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#5E6671"
    },
    optionalTextInput: {
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "21px",
        color: "#5E6671"
    },
    okButton: {
        display: 'flex',
        alignItems: 'center', 
        justifyContent: 'center', 
        borderRadius: "8px",
        color: "#fff",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        backgroundColor:"#684EF3",
        lineHeight: "24px",
        textAlign: "center",
        width:'86px',
        height:'32px',
        cursor: "pointer",
        marginTop:'30px'
    } as React.CSSProperties,
}

export default ViewProfileDrawer;
// Customizable Area End