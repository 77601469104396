import React from "react";
// Customizable Area Start
import AuditTrailHistoryController, {
  Props,
  configJSON,
} from "./AuditTrailHistoryController.web";
import {
  Dialog,
  DialogContent,
  Paper,
  Typography,
  Box,
  TableHead,
  Table,
  styled,
  TableContainer,
  Breadcrumbs,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
} from "@material-ui/core";
import { colors } from "../../../../blocks/utilities/src/Colors";
import StyledPagination from "../../../../components/src/common/Pagination.web";
import {
  getPaginationIndex,
  renderBaseonCondition,
} from "../../../../blocks/utilities/src/commonfunctions";
import CommonAvtar from "../../../../components/src/common/CommonAvtar.web";
import AppNotification from "../../../../blocks/notifications/src/AppNotification.web";
import NavigationMenu from "../../../../blocks/navigationmenu/src/NavigationMenu.web";
import FileViewModal from "../../../../blocks/audittrail/src/components/FileViewModal.web";
// Customizable Area End

// Customizable Area Start
const string = configJSON.auditTrailHistoryString;
// Customizable Area End

export class AuditTrailHistory extends AuditTrailHistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderViewFileDialog = () => {
    const { isViewDialogOpen } = this.state;
    const type = this.state.currentFile?.filename?.split(".") ?? "";
    return (
      <FileViewModal
        open={isViewDialogOpen}
        onClose={() => this.closeDialog()}
        pdfName={this.state.currentFile?.filename || ""}
        pdfUrl={this.state.currentFile?.url ?? ""}
      />
    );
  };

  customSeparator = () => {
    return (
      <Box>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.4996 13.2292L10.7329 9.99583L7.4996 6.7625C7.34355 6.60681 7.25586 6.39543 7.25586 6.175C7.25586 5.95457 7.34355 5.74319 7.4996 5.5875C7.8246 5.2625 8.3496 5.2625 8.6746 5.5875L12.4996 9.4125C12.8246 9.7375 12.8246 10.2625 12.4996 10.5875L8.6746 14.4125C8.3496 14.7375 7.8246 14.7375 7.4996 14.4125C7.18293 14.0875 7.1746 13.5542 7.4996 13.2292Z"
            fill="#94A3B8"
          />
        </svg>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      currentWorkspaceName,
      totalPages,
      auditHistoryLists,
      currentPage,
      perPage,
      totalResults,
      isFetchingData,
    } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <AuditTrailHistoryWrapper>
        <NavigationMenu navigation={this.props.navigation} id="workspace" />
        <Box className="history-main-container">
          <Box className="history-header">
            <Breadcrumbs separator={this.customSeparator()} className="titles">
              <Typography
                className="breadcum-text"
                onClick={this.goToWorkspaceList}
                data-test-id="btngoto-workspace-list"
              >
                {string.workspaces}
              </Typography>
              <Typography
                className="breadcum-text"
                onClick={this.goToWorkspaceListById}
                data-test-id="btngoto-workspace-list-by-id"
              >
                {currentWorkspaceName}
              </Typography>
              <Typography className="breadcum-text active-breadcum">
                {string.interationsHistory}
              </Typography>
            </Breadcrumbs>
            <AppNotification navigation={this.props.navigation} />
          </Box>
          <Box className="history-container">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="table-head-text w-200">
                      {string.dateTime}
                    </TableCell>
                    <TableCell className="table-head-text">
                      {string.action}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {renderBaseonCondition(
                    isFetchingData,
                    <TableRow>
                      <TableCell colSpan={2} style={{ textAlign: "center" }}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>,
                    <>
                      {renderBaseonCondition(
                        auditHistoryLists.length === 0,
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            style={{ textAlign: "center" }}
                          >
                            {string.noDataFound}
                          </TableCell>
                        </TableRow>,
                        <>
                          {auditHistoryLists.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell className="timeText">
                                {row.lastUpdate}
                              </TableCell>
                              <TableCell>
                                <Box className="main-cell-box">
                                  <CommonAvtar
                                    src={row.profile_photo}
                                    alt={row.actionByFirstName}
                                    className="profile-avtar"
                                  />
                                  <Box className="row-text-wrap">
                                    <Typography className="action-text dark-blue-text">
                                      {row.actionByFullName}
                                    </Typography>
                                    <Typography className="action-text">
                                      {row.description}
                                    </Typography>
                                    {row.url && (
                                      <Typography
                                        onClick={() =>
                                          this.handleClickFile(row)
                                        }
                                        className="action-text dark-blue-text pointer"
                                        data-test-id={`pdf-url-${row.filename}`}
                                      >
                                        {row.filename}
                                      </Typography>
                                    )}
                                  </Box>
                                </Box>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
              {renderBaseonCondition(
                totalPages > 1,
                <Box className="pagination-box">
                  <StyledPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handleChangePage={this.handleChangePage}
                    startIndex={getPaginationIndex(
                      perPage,
                      currentPage,
                      totalResults,
                      "start"
                    )}
                    endIndex={getPaginationIndex(
                      perPage,
                      currentPage,
                      totalResults,
                      "end"
                    )}
                    totalResults={totalResults}
                  />
                </Box>,
                <></>
              )}
            </TableContainer>
          </Box>
        </Box>
        {this.renderViewFileDialog()}
      </AuditTrailHistoryWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const AuditTrailHistoryWrapper = styled(Box)({
  display: "flex",
  width: "100%",
  background: colors().cultured,
  height: "100vh",
  overflow: "hidden",
  "& .history-main-container": {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  "& .history-header": {
    padding: "25px 34px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${colors().tableBorder}`,
  },
  "& .breadcum-text": {
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
    fontFamily: "DM Sans",
    color: colors().grayText,
    cursor: "pointer",
  },
  "& .active-breadcum": {
    fontWeight: 500,
    color: colors().darkText,
    cursor: "none",
  },
  "& .history-container": {
    padding: "17px 40px",
    flex: "1 1 0",
    overflow: "auto",
  },
  "& .history-table-box": {
    background: colors().white,
    borderRadius: "16px",
    overflow: "hidden",
  },
  "& .timeText": {
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
    fontFamily: "DM Sans",
    color: colors().grayText,
  },
  "& .action-text": {
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 400,
    fontFamily: "DM Sans",
    color: colors().grayText,
  },
  "& .MuiPaper-root": {
    borderRadius: "16px",
    boxShadow: "none",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  "& .table-head-text": {
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: 700,
    fontFamily: "DM Sans",
    color: colors().grayText,
  },
  "& .w-200": {
    width: "200px",
  },
  "& .pagination-box": {
    padding: "6px 0 0 0",
  },
  "& .row-text-wrap": {
    display: "flex",
    alignItems: "ceneter",
    flexWrap: "wrap",
    gap: "3px",
  },
  "& .main-cell-box": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  "& .dark-blue-text": {
    color: colors().majorelleBlue,
    fontWeight: 700,
  },
  "& .pointer": {
    cursor: "pointer",
  },
  "& .profile-avtar": {
    height: "32px",
    width: "32px",
  },
});
export default AuditTrailHistory;
// Customizable Area End
