// Customizable Area Start
import React from "react";
import MuiTab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import DetailWorkspaceTabViewController from "./DetailWorkspaceTabViewController.web";
import withStyles, { CSSProperties } from "@material-ui/core/styles/withStyles";
import NavigationMenu from "../../../../packages/blocks/navigationmenu/src/NavigationMenu.web";
import { MemberTab } from "./MemberTab.web";
import GeneralTabView from "./GeneralTabView.web";
import QueryHubTabView from "./QueryHubTabView.web";
import Loader from "../../../../packages/components/src/Loader.web";
import AiQueryHub from "../../cfpromptingcustomquery1/src/AiQueryHub.web";
import AppNotification from "../../../blocks/notifications/src/AppNotification.web";
const configJSON = require("./config");

export class DetailWorkspaceTabView extends DetailWorkspaceTabViewController {
  renderTabsCompontents(tabIndex: number) {
    const idWorkspace: number = this.props.navigation.getParam("id")
    if (tabIndex === 0) {
        return (
            <GeneralTabView 
                navigation={this.props.navigation} 
                listUser={this.state.currentWorkspace?.workspace_members || []}
                currentUserId={this.state.currentUserId}
                classes={undefined}
                reloadWorkspace={this.reloadWorkspace}
            />
        );
    } else if (tabIndex === 1) {
        return (
            // <QueryHubTabView
            //     navigation={this.props.navigation}
            //     classes={this.props.classes}
            //     currentUserId={this.state.currentUserId}
            //     workspaceId={idWorkspace}
            // />
            <AiQueryHub navigation={this.props.navigation} classes={this.props.classes} />
        );
    } else {
        return (
            <MemberTab
                classes={this.props.classes}
                listUser={this.state.currentWorkspace?.workspace_members || []}
                workspaceId={idWorkspace}
                reloadWorkspace={this.reloadWorkspace}
                currentUserId={this.state.currentUserId}
            />
        )

    }
  }

  render() {
    const { view, loading } = this.state;
    return (
        <div className={this.props.classes.mainContainer}>
            <div className={this.props.classes.navView}>
                <NavigationMenu navigation={this.props.navigation} id="workspace" />
            </div>
            <div className={this.props.classes.container}>
                <div className={this.props.classes.headerView}>
                    <div className={this.props.classes.titleHeader}>
                        <span data-test-id="goBackWorkspace" className={this.props.classes.titleWorkspace} onClick={this.onGobackToWorkspace}>{configJSON.workSpace}</span>
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.4996 13.3761L10.7329 10.1428L7.4996 6.90947C7.34355 6.75378 7.25586 6.5424 7.25586 6.32197C7.25586 6.10154 7.34355 5.89017 7.4996 5.73447C7.8246 5.40947 8.3496 5.40947 8.6746 5.73447L12.4996 9.55947C12.8246 9.88447 12.8246 10.4095 12.4996 10.7345L8.6746 14.5595C8.3496 14.8845 7.8246 14.8845 7.4996 14.5595C7.18293 14.2345 7.1746 13.7011 7.4996 13.3761Z" fill="#64748B"/>
                        </svg>
                        <span className={this.props.classes.nameWorkspace}>{this.state.currentWorkspace?.workspace_name}</span>
                    </div>
                    <div className={this.props.classes.tabHeader}>
                        <Tabs
                            value={view}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            className={this.props.classes.tab}
                            TabIndicatorProps={{
                                style: { display: 'none' }
                            }}
                        >
                            <MuiTab
                                className={view === 0 ? this.props.classes.chosenTab : this.props.classes.normalTab}
                                label={configJSON.generalTabTitle}
                            />
                            <MuiTab
                                className={view === 1 ? this.props.classes.chosenTab : this.props.classes.normalTab}
                                label={configJSON.queryTabTitle}
                            />
                            <MuiTab
                                className={view === 2 ? this.props.classes.chosenTab : this.props.classes.normalTab}
                                label={configJSON.memberTabTitle}
                            />
                        </Tabs>
                        <svg width="44" height="44" viewBox="0 0 44 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 28.147V31.913L21.277 28.147H26C27.103 28.147 28 27.25 28 26.147V18.147C28 17.044 27.103 16.147 26 16.147H14C12.897 16.147 12 17.044 12 18.147V26.147C12 27.25 12.897 28.147 14 28.147H15ZM14 18.147H26V26.147H20.723L17 28.381V26.147H14V18.147Z" fill="#14101E"/>
                            <path d="M30 12.147H18C16.897 12.147 16 13.044 16 14.147H28C29.103 14.147 30 15.044 30 16.147V24.147C31.103 24.147 32 23.25 32 22.147V14.147C32 13.044 31.103 12.147 30 12.147Z" fill="#14101E"/>
                        </svg>
                        <AppNotification navigation={this.props.navigation} />
                    </div>
                </div>
                {this.renderTabsCompontents(view)}
            </div>
            <Loader loading={loading} />
        </div>
    );
  }
}

const useStyles: Record<string, CSSProperties> = {
    mainContainer: {
        display: "flex",
        width: "100%",
        height: "100%",
        backgroundColor: "#f6f5f9"
    },
    navView: {
        position: "sticky"
    },
    container: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f6f5f9",
        width: "100%",
    },
    headerView: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "26px 32px",
        borderBottom: '1px solid #E2E8F0'
    },
    titleHeader: {
        display: "flex",
        gap: "8px",
        alignItems: "center",
    },
    titleWorkspace: {
        fontFamily: 'DM Sans',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        color: "#475569",
        cursor: "pointer"
    },
    nameWorkspace: {
        fontFamily: 'DM Sans',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '21px',
        color: "#0F172A"
    },
    tabHeader: {
        display: "flex",
        gap: "20px",
        alignItems: "center",
    },
    tab: {
        backgroundColor: "#fff",
        padding: "6px",
        border: "1px solid #D9D1EF",
        borderRadius: "12px",
        minHeight: "unset",
    },
    chosenTab: {
        backgroundColor: "#EDEAFF",
        fontFamily: 'DM Sans',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '21px',
        padding: "4px 12px",
        color: "#684EF3 !important",
        borderRadius: "6px",
        textTransform: "capitalize",
        minWidth: "unset",
        minHeight: "unset",
    },
    normalTab: {
        backgroundColor: "#fff",
        fontFamily: 'DM Sans',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
        padding: "4px 12px",
        color: "#5E6671",
        borderRadius: "6px",
        textTransform: "capitalize",
        minWidth: "unset",
        minHeight: "unset",
    },
    inputSearch: {
        border: "1px solid #E2E8F0",
        width: "288px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "12px",
        backgroundColor: "#fff",
        borderRadius: "8px",
    },
    inputSearchStyle: {
        fontWeight: 400,
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        color: "#30353B",
    },
    customdropdown: {
        borderRadius: "8px",
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        border: "1px solid #E2E8F0",
        backgroundColor: "#fff",
        '& .MuiFilledInput-input': {
            padding: "12px",
            height: "fit-content"
        },
        '&::before, &::after': {
          borderBottom: "none !important"
        },
        '&:hover': {
          textDecoration: "none !important"
        }
    },
    dropdownitem: {
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        cursor: "pointer",
    },
    viewTable: {
        border: "2px solid #ECEDF0",
        backgroundColor: "#fff",
        borderRadius: "16px",
        overflow: "unset"
    },
    tableRowHeader: {
        border: "1px solid #ECEDF0",
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        verticalAlign: "top",
    },
    tableRow: {
        border: "1px solid #ECEDF0",
        borderLeftWidth: 0,
        borderRightWidth: 0,
        verticalAlign: "top",
    },
    viewButton: {
        display: "flex",
        gap: "16px",
        padding: "0 32px",
        marginBottom: "40px"
    },
    cancelButton: {
        padding: "10px 26px",
        border: "1px solid #684EF3",
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#684EF3",
        backgroundColor: "#fff",
        borderRadius: "8px",
        cursor: "pointer"
    },
    inputView: {
        display: "flex",
        flexDirection: "column",
        gap: "4px",
        marginTop: "28px",
        padding: "0 32px"
    },
    optionAutocomplete: {
        backgroundColor: "#f6f5f9",
        display: "flex",
        alignItems: "center",
        padding: "12px",
        width: "100%",
        borderRadius: "8px",
        ".MuiAutocomplete-option[data-focus='true']:has(&)": {
            backgroundColor: "transparent"
        }
    },
    viewContentOption: {
        display: "flex",
        flexDirection: "column",
        gap: 0,
        width: "100%"
    },
    optionAvaAutocomplete: {
        width: "44px",
        height: "44px",
        borderRadius: "44px",
        border: "2px solid #D9D1EF",
        marginRight: "12px",
    },
    createButton: {
        padding: "10px 26px",
        borderRadius: "8px",
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#fff",
        width: "100%",
        textAlign: "center",
        cursor: "pointer"
    },
    titleDropDownAutocomplete: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "21px",
        color: "#14101E",
        margin: 0
    },
    descriptionDropDownAutocomplete: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        color: "#30353B",
        margin: 0
    },
    removeButton: {
        display: "flex",
        alignItems: "center",
        gap: "4px",
        color: "#30353B",
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "21px",
        backgroundColor: "#fff",
        borderRadius: "26px",
        padding: "4px 12px",
        marginRight: "12px",
        cursor: "pointer"
    },
    viewListUser: {
        marginTop: "16px",
        marginBottom: "16px",
        gap: "8px",
        display: 'flex',
        flexDirection: "column",
        width: "calc(100% - 24px)"
    },
    viewIconDropdown: {
        display: "flex",
        gap: "4px",
        alignItems: "center"
    }
};

export default withStyles(useStyles)(DetailWorkspaceTabView);
// Customizable Area End
