import React from "react";

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Box,
  Typography,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import moment from "moment";
import { logoText } from "../../email-account-login/src/assets";
import { downloadIcon } from "./assets";
const theme = createTheme({
  overrides: {
    MuiDialogContent: {
      root: {
        padding: "0px",
      },
    },
    MuiDialog: {
      paper: {
        width: "1376px",
      },
      paperWidthSm: {
        maxWidth: "1376px",
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: "26px",
      },
    },
  },
});

import {
  renderBaseonCondition,
  renderBaseonStringCondition,
} from "../../../blocks/utilities/src/commonfunctions";
import { colors } from "../../../blocks/utilities/src/Colors";
// Customizable Area End

import UserTermsConditionsController, {
  Props,
} from "./UserTermsConditionsController.web";

export default class UserTermsConditions extends UserTermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isPrivacyPolicyOpen, showIconPrivacyPolicy, activeTab } =
      this.state;

    return (
      <ThemeProvider theme={theme}>
        <PrivacyDialog open={true}>
          <DialogTitle>
            <Box style={styles.header as React.CSSProperties}>
              <img
                style={styles.headerText as React.CSSProperties}
                src={logoText}
              />
              <Box
                style={{
                  marginLeft: "auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CloseIcon
                  data-test-id="closeDialogue"
                  style={styles.iconClose as React.CSSProperties}
                  onClick={() => this.props.closeDialogue()}
                />
              </Box>
            </Box>
          </DialogTitle>

          <DialogContent>
            <Box className="leftSide">
              <Box>
                <StyledTermsBox
                  data-test-id="PrivacyPolicyBtn"
                  onClick={this.handlePrivacy}
                  className={renderBaseonStringCondition(
                    activeTab === "privacy",
                    "active",
                    ""
                  )}
                >
                  <Box className="flex-box">
                    <Box>
                      <Typography
                        style={styles.privacyPolicy as React.CSSProperties}
                      >
                        Privacy Policy
                      </Typography>
                    </Box>
                    <Box>
                      {renderBaseonCondition(
                        showIconPrivacyPolicy,
                        <ArrowForwardIosIcon
                          style={{ fontSize: "1rem" } as React.CSSProperties}
                        />,
                        <KeyboardArrowUpIcon
                          style={{ fontSize: "1.5rem" } as React.CSSProperties}
                          onClick={this.handlePrivacy}
                        />
                      )}
                    </Box>
                  </Box>
                </StyledTermsBox>
                {renderBaseonCondition(
                  isPrivacyPolicyOpen,
                  <Box className="section-wrapper">
                    {this.state.sections.map(
                      (section: { id: string; header: string }, index: any) => (
                        <StyledTerms
                          data-test-id={`header-${index}`}
                          key={index}
                          onClick={() => this.handleScroll(section.id)}
                        >
                          {`${index + 1}. ${section.header}`}
                        </StyledTerms>
                      )
                    )}
                  </Box>,
                  <></>
                )}
              </Box>
              <Box>
                <StyledTermsBox
                  data-test-id="TermsandconditionBtn"
                  onClick={this.handleTearms}
                  className={renderBaseonStringCondition(
                    activeTab === "terms",
                    "active",
                    ""
                  )}
                >
                  <Box className="flex-box">
                    <Box>
                      <Typography
                        style={styles.privacyPolicy as React.CSSProperties}
                      >
                        Terms and Conditions
                      </Typography>
                    </Box>
                    <Box>
                      {this.state.showIconTearms ? (
                        <ArrowForwardIosIcon
                          style={{ fontSize: "1rem" } as React.CSSProperties}
                        />
                      ) : (
                        <KeyboardArrowUpIcon
                          style={{ fontSize: "1.5rem" } as React.CSSProperties}
                          onClick={this.handleTearms}
                        />
                      )}
                    </Box>
                  </Box>
                </StyledTermsBox>
                {this.state.isTearmsOpen && (
                  <Box className="section-wrapper">
                    {this.state.sections.map((section: any, index: any) => (
                      <StyledTerms
                        data-test-id={`header-${index}`}
                        key={index}
                        onClick={() => this.handleScroll(section.id)}
                      >
                        {`${index + 1}. ${section.header}`}
                      </StyledTerms>
                    ))}
                  </Box>
                )}
              </Box>
            </Box>
            <ContentWrapper>
              <Box>
                <Box
                  style={
                    {
                      display: "flex",
                      gap: "12px",
                      justifyContent: "space-between",
                    } as React.CSSProperties
                  }
                >
                  <div
                    style={
                      {
                        display: "flex",
                        flexDirection: "column",
                      } as React.CSSProperties
                    }
                  >
                    <Typography style={styles.heading as React.CSSProperties}>
                      {renderBaseonStringCondition(
                        activeTab === "privacy",
                        "Privacy Policy",
                        "Terms and Conditions"
                      )}
                    </Typography>
                    <Typography
                      style={styles.revisedTime as React.CSSProperties}
                    >
                      Last Revised:{" "}
                      {this.state.reviseddate
                        ? moment(this.state.reviseddate).format("MMM DD, YYYY")
                        : ""}
                    </Typography>
                  </div>
                  <img
                    data-test-id="downloadIcon"
                    style={styles.downloadIcon as React.CSSProperties}
                    src={downloadIcon}
                    alt="Download"
                    onClick={() => {
                      const url = this.state.pdfURL;
                      const fileName = url.substring(url.lastIndexOf("/") + 1);
                      const link = document.createElement("a");
                      link.href = url;
                      link.download = fileName;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }}
                  />
                </Box>
              </Box>

              <Box id="dialog-description">
                {this.state.sections.map((section: any, index: number) => (
                  <Box key={section.id} id={section.id} className="section-box">
                    <h3 className="section-header">
                      {index + 1 + ". " + section.header}
                    </h3>
                    <Box
                      className="section-content"
                      dangerouslySetInnerHTML={{ __html: section.content }}
                    />
                  </Box>
                ))}
              </Box>
            </ContentWrapper>
          </DialogContent>
        </PrivacyDialog>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const ContentWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  padding: "24px 32px",
  width: "100%",
  overflowY: "auto",
  "& .section-box": {
    margin: "32px 0",
  },
  "& .section-header": {
    fontWeight: 700,
    fontFamily: "DM Sans",
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().darkText,
    textTransform: "uppercase",
  },
  "& .section-content": {
    "& p": {
      fontWeight: 400,
      fontFamily: "DM Sans",
      fontSize: "16px",
      lineHeight: "24px",
      color: colors().darkText,
      margin: "8px 0",
    },
  },
});

const PrivacyDialog = styled(Dialog)({
  "& .MuiDialogTitle-root": {
    padding: "24px 42px 28px 42px",
    borderBottom: `1px solid ${colors().tableBorder}`,
  },
  "& .leftSide": {
    maxWidth: "375px",
    width: "100%",
    padding: "24px 32px",
    borderRight: `1px solid ${colors().tableBorder}`,
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    overflowY: "auto",
    "@media (max-width: 600px)": {
      width: "100%",
      borderRight: "none",
      borderBottom: `1px solid ${colors().tableBorder}`,
    },
  },
  "& .MuiDialogContent-root": {
    display: "flex",
    height: "calc(100vh - 80px)",
    overflow: "hidden",
  },
});

const StyledTermsBox = styled(Box)({
  padding: "16px",
  backgroundColor: colors().cultured,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "12px",
  gap: "8px",
  cursor: "pointer",
  flexDirection: "column",
  "& .flex-box": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  "&.active": {
    backgroundColor: colors().activeBg,
  },
  "& .section-wrapper": {
    backgroundColor: "transparent",
  },
});

const StyledTerms = styled(Typography)({
  fontWeight: 400,
  fontFamily: "DM Sans",
  fontSize: "16px",
  lineHeight: "24px",
  width: "320px",
  padding: "8px 20px",
  color: "#30353B",
  cursor: "pointer",
  borderBottom: `1px solid ${colors().tableBorder}`,
});

const styles = {
  iconClose: {
    height: "20px",
    width: "20px",
    padding: "10.39px 10.39px",
    color: "#684EF3",
  },

  headerText: {
    width: "141.05px",
    height: "28px",
    gap: "2.8px ",
  },
  downloadIcon: {
    width: "44px",
    height: "44px",
    gap: "2.8px ",
    cursor: "pointer",
  },

  privacyPolicy: {
    fontWeight: "bold",
    fontFamily: "DM Sans",
    fontSize: "16px",
    color: "#30353B",
    lineHeight: "24px",
  },
  heading: {
    fontWeight: "700",
    fontFamily: "DM Sans",
    fontSize: "20px",
    color: "#30353B",
    lineHeight: "30px",
  },
  revisedTime: {
    fontWeight: "400",
    fontFamily: "DM Sans",
    fontSize: "16px",
    color: "#5E6671",
    lineHeight: "24px",
  },
  dialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialogContent: {
    // maxWidth: "1376px",
    borderRadius: 24,
    width: "100%",
    backgroundColor: "#fff",
    border: "1px #E2E8F0",
    height: "100%",
    boxShadow: "5px",
    "@media (max-width: 600px)": {
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      borderRadius: 0,
      padding: "16px",
    },
  },
  modal: {
    display: "flex",
    padding: "8px",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    maxWidth: "1376px",
    borderRadius: 24,
    width: "100%",
    backgroundColor: "#fff",
    border: "1px #E2E8F0",
    boxShadow: "5px",
    padding: "16px 32px 24px",
    "@media (max-width: 600px)": {
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      borderRadius: 0,
      padding: "16px",
    },
  },
  header: {
    width: "100%",
    // height: '80px',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
  },

  rightSide: {
    width: "93%",
    height: "65vh",
    padding: "24px 32px",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
};
// Customizable Area End
