Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';

exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'chat/chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';

exports.getMyChatsApiEndpoint = 'chat/chats/mychats';
exports.chatNeeve = "Neeve Chat";
exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.unreadMessageText = "Unread Messages";
exports.lastMessageText = "Last Message:";
exports.writeMessagePlaceholder = "Write message here";
exports.usersText = "Users:";
exports.accountIdPlaceholder = "Account ID";
exports.namePlaceholder = "Name";

exports.pageTitle = "Chats";
exports.newChatLabel = "New chat";
exports.addMembersLabel = "Add members or team";
exports.addMembersPlaceholder = "Search member to add by email, name or team";
exports.removeBtn = "Remove";
exports.addGroupLabel = "Add a group name";
exports.createChatTitle = "Create new chat";
exports.cancelBtn = "Cancel";
exports.createBtn = "Create";

exports.newLabel = "Chat Listing";

exports.chatLabel = "Workspace Chat";

exports.searchUserApiEndPoint = "account_block/accounts/search_users_by_query?search_query=";
exports.chatApiEndPoint = "bx_block_chat/chats/fetch_conversations";
exports.privateChatEndPoint = "bx_block_chat/chats/create_conversation";
exports.groupChatEndPoint = "bx_block_chat/chats/create_group_conversation";
  // Customizable Area End