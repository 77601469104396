export const insertPhoto = require("../assets/insert_photo.png");
export const icWorkspace = require("../assets/ic-workspace.svg");
export const icChatSearch = require("../assets/ic-chat-search.svg");
export const sendIcon = require("../assets/ic-send.svg");
export const editIcon = require("../assets/ic-edit.svg");
export const replyIcon = require("../assets/ic-reply.svg");

export const replyMoreMenuIcon = require("../assets/ic-reply-more-menu.svg");
export const editMoreMenuIcon = require("../assets/ic-edit-more-menu.svg");
export const deleteMoreMenuIcon = require("../assets/ic-delete-more-menu.svg");
export const businessAttachment = require("../assets/business.jpeg").default;
export const xlsxFileIcon = require("../assets/ic-file-xlsx.svg");
export const leaveChatIcon = require("../assets/leave_chat.svg");
export const sendMessageIcon = require("../assets/send_message.svg");
export const viewProfileIcon = require("../assets/view_profile.svg");
export const noResultsIcon = require("../assets/no_results.svg");