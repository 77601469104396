// Customizable Area Start
import React, { ReactNode } from "react";
import { Dialog, DialogContent, TextField, FormControl, MenuItem, Avatar } from "@material-ui/core";
import { SelectComponent } from "./WorkspaceList.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Autocomplete } from "@material-ui/lab";
import { CategoryObject } from "./WorkspaceListController.web";
import UpdateWorkspaceDialogController, { configJSON } from "./UpdateWorkspaceDialogController.web";
import { filterOptions } from "./CreateWorkspaceDialog.web";
import { UserResponse } from "./CreateWorkspaceDialogController.web";

export class UpdateWorkspaceDialog extends UpdateWorkspaceDialogController {
    renderInput = (
        title: string,
        value: string,
        placeholder: string,
        onChangeValue: (value: string) => void,
        error?: string
    ) => {
        return (
            <div className={this.props.classes?.inputView}>
                <div className={this.props.classes?.titleText}>{title}</div>
                <TextField
                    className={this.props.classes?.inputText}
                    data-test-id={`inputText_${title}`}
                    value={value}
                    onChange={(event) => onChangeValue(event.target.value)}
                    placeholder={placeholder}
                    InputProps={{
                        disableUnderline: true,
                        className: this.props.classes?.searchStyle
                    }}
                />
                {
                    error !== "" && <span className={this.props.classes?.errorText}>{error}</span>
                }
            </div>
        )
    }

    getColorPlaceholder = (isPlaceholder: boolean) => {
        if (!isPlaceholder) return "#30353B"
        else return "#94A3B8" 
    }

    renderSelectComponent = (
        title: string,
        currentValue: string,
        placeholder: string,
        onChangeValue: (value: string) => void,
        listItem: Array<any>
    ) => {
        return (
            <div className={this.props.classes?.inputView}>
                <div className={this.props.classes?.titleText}>{title}</div>
                <FormControl variant="filled">
                    <SelectComponent
                        id="SelectComponent"
                        value={currentValue}
                        className={this.props.classes?.customdropdown}
                        IconComponent={() => (
                            <ExpandMoreIcon style={webStyles.iconExpand} />
                        )}
                        displayEmpty={true}
                        renderValue={value => (currentValue !== "" ? value : placeholder) as ReactNode}
                        style={{
                            color: this.getColorPlaceholder(currentValue === ""),
                        }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null
                        }}
                    >
                        {listItem.map((item: CategoryObject) =>
                            <MenuItem
                                className={this.props.classes?.dropdownitem}
                                key={item.id}
                                value={item.attributes.name}
                                data-test-id={`selectItem_${item.attributes.name}`}
                                onClick={() => onChangeValue(item.attributes.name)}
                            >
                                {item.attributes.name}
                            </MenuItem>
                        )}
                    </SelectComponent>
                </FormControl>
            </div>
        )
    }

    renderAutocompleteList = () => {
        return (
            <div className={this.props.classes?.inputView}>
                <div className={this.props.classes?.titleText}>{configJSON.addMemberTitle}</div>
                <Autocomplete
                    data-test-id="autocomplete"
                    filterOptions={filterOptions}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={configJSON.addMemberPlaceholder}
                            variant="outlined"
                            onChange={(event) => this.debouncedSearchMember(event)}
                            InputProps={{
                                ...params.InputProps,
                                style: webStyles.textInput,
                            }}
                        />
                    )}
                    options={this.state.users}
                    onChange={(event, inputVal) => {
                        if (inputVal) this.handleChosenUser(inputVal)
                    }}
                    getOptionLabel={() => ''}
                    renderOption={(option) => this.renderUser(option, false)}
                    getOptionSelected={(option, value) => option.attributes?.email === value.attributes?.email}
                />
                <div className={this.props.classes?.viewListUser}>
                    {this.state.listChosenUser.map((item: UserResponse) => this.renderUser(item, true))}
                </div>
            </div>
        )
    }

    renderUser = (option: UserResponse, showRemoveButton: boolean) => {
        return (
            <div key={`user_${option.id}`} className={this.props.classes?.optionAutocomplete}>
                <Avatar alt="avatar" src={option.attributes?.profile_photo || ""} className={this.props.classes?.optionAvaAutocomplete}>
                    {option.attributes?.first_name?.charAt(0)}
                </Avatar>
                <div className={this.props.classes?.viewContentOption}>
                    <p className={this.props.classes?.titleDropDownAutocomplete}>{`${option.attributes?.first_name ?? ""} ${option.attributes?.last_name ?? ""}`}</p>
                    <p className={this.props.classes?.descriptionDropDownAutocomplete}>{option.attributes?.email}</p>
                </div>
                {
                    showRemoveButton && (
                        <div data-test-id={`option_${option.id}`} className={this.props.classes?.removeButton} onClick={() => this.onRemoveUser(option)}>
                            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.7946 4.37622L7.96598 7.20422L5.13798 4.37622L4.19531 5.31889L7.02331 8.14689L4.19531 10.9749L5.13798 11.9176L7.96598 9.08955L10.7946 11.9176L11.7373 10.9749L8.90931 8.14689L11.7373 5.31889L10.7946 4.37622Z" fill="#30353B"/>
                            </svg>
                            {configJSON.remove}
                        </div>
                    )
                }
            </div>
        )
    }

    renderViewButton = () => {
        return (
            <div className={this.props.classes?.viewButton}>
                <div data-test-id='closeCreateWorkspace' className={this.props.classes?.cancelButton} onClick={this.closeDialog}>{configJSON.cancel}</div>
                <div
                    data-test-id='createWorkspace'
                    className={this.props.classes?.createButton}
                    style={{ backgroundColor: this.getCurrentCreateColor() }}
                    onClick={this.updateAndCloseDialog}
                >
                    {configJSON.textUpdate}
                </div>
            </div>
        )
    }

    render() {
        const { isDialogOpen, } = this.props
        
        return (
            <Dialog
                onClose={() => this.closeDialog()}
                aria-labelledby="customized-dialog-title"
                open={isDialogOpen}
                fullWidth
                PaperProps={{
                    style: webStyles.viewDialog
                }}
            >
                <DialogContent
                    className="dialog-address-content"
                    style={{ padding: 0 }}
                >
                    <div style={webStyles.textTitle}>{configJSON.updateWorkspace}</div>
                    {this.renderInput(
                        configJSON.workspaceName,
                        this.state.name,
                        configJSON.createWorkspaceName,
                        this.onChangeName,
                        this.state.errorName
                    )}
                    {this.renderInput(
                        configJSON.descriptionTitle,
                        this.state.description,
                        configJSON.descriptionPlaceholder,
                        this.onChangeDescription
                    )}
                    {this.renderSelectComponent(
                        configJSON.category,
                        this.state.category,
                        configJSON.categoryPlaceholder,
                        this.onChangeCategory,
                        this.state.listCategory
                    )}
                    {this.renderSelectComponent(
                        configJSON.subCategory,
                        this.state.subCategory,
                        configJSON.subCategoryPlaceholder,
                        this.onChangeSubCategory,
                        this.state.listSubCategory
                    )}
                    {this.renderAutocompleteList()}
                    {this.renderViewButton()}
                </DialogContent>
            </Dialog>
        );
    }
}

const webStyles = {
    viewDialog: {
        borderRadius: '24px',
        border: '0px solid #D0D2DA',
        height: "85%",
        backgroundColor: "#fff"
    },
    textTitle: {
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "33.6px",
        position: "sticky",
        top: 0,
        padding: "24px 32px",
        zIndex: 2,
        borderBottom: '1px solid #F6F5F9',
        backgroundColor: "#fff"
    } as React.CSSProperties,
    iconExpand: { color: "#30353B" },
    textInput: {
        width: "100%",
        height: 48,
        color: "#30353B",
        fontFamily: "DM Sans",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        padding: "0px 20px 0px 10px"
    }
}

export default UpdateWorkspaceDialog;
// Customizable Area End