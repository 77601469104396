// Customizable Area Start
// @ts-ignore
import DatePicker from "react-datepicker";
import React from "react";
import { Box, Typography, Button, TextField, MenuItem, withStyles, FormControl, InputAdornment, Snackbar, styled, DialogContent, DialogActions } from "@material-ui/core";
import { backgroundName, complete, notificationDisabled, pending } from './assets';
import UserProfileBasicControllerWeb, { Props } from "./UserProfileBasicController.web";
import Avatar from '@material-ui/core/Avatar';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ConfirmationDialog from "../../../../packages/blocks/accountgroups/src/ConfirmationDialog.web";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import TimezoneSelect from "react-timezone-select"
import { SelectComponent } from "../../../../packages/blocks/accountgroups/src/WorkspaceList.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert } from "@material-ui/lab";
import "react-datepicker/dist/react-datepicker.css";
import UserProfileDialog from "./UserProfileDialog.web";
import Loader from "../../../../packages/components/src/Loader.web";
import { ItemNotiWorkspaceSetting } from "../../../../packages/components/src/ItemNotiWorkspaceSetting.web";
import { StyledSwitch } from "../../../components/src/common/StyledSwitch.web";
import CustomSelect from "../../../components/src/common/CustomSelect.web";
import CommonDialogue from "../../../components/src/common/CommonDialogue.web";
import CustomDialogWithAction from "../../../components/src/common/CustomDialogWithAction.web";
import { Form, Formik } from "formik";
import CustomInput from "../../../components/src/common/CustomInput.web";
import CommonButton from "../../../components/src/common/CommonButton.web";
import OTPInput from "react-otp-input";
import UserTermsConditions from "./UserTermsConditions.web";
import CommonSnackbar from "../../../components/src/common/CommonSnackbar.web";
import CustomPassword from "../../../components/src/common/CustomPassword.web";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";

const CustomTimeInput = React.forwardRef(
    ({ value, onClick }: any, ref: any) => (
        <button style={webStyles.inputTime} onClick={onClick} ref={ref}>
            {value}
            <svg style={webStyles.dropDownTime} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.793 8.30957L12.5 12.6026L8.20697 8.30957L6.79297 9.72357L12.5 15.4306L18.207 9.72357L16.793 8.30957Z" fill="#30353B"/>
            </svg>
        </button>
    ),
);

export class UserProfileBasicBlockWeb extends UserProfileBasicControllerWeb {
    constructor(props: Props) {
        super(props);

    }

    renderButtonScreen = (
        testId: string,
        input: 'general' | 'notifications' | 'accounts',
        title: string,
        isChosen: boolean
    ) => {
        return (
            <div
                data-test-id={testId}
                onClick={() => this.handlePageChange(input)}
                style={isChosen ? webStyles.chosenPage : webStyles.normalPage}
            >
                {title}
            </div>
        )
    }

    renderInputField = (
        id: string,
        value: string,
        placeholder: string,
        onChangeValue: (value: string) => void,
        isMultiline: boolean,
        setFieldValue: Function,
        error?: string,
    ) => {
        return (
            <div style={webStyles.inputView}>
                <TextField
                    className={this.props.classes?.inputText}
                    data-test-id={`inputText_${id}`}
                    value={value}
                    onChange={(event) => { onChangeValue(event.target.value.trimStart()); setFieldValue(id, event.target.value.trimStart()) }}
                    style={{
                        minHeight: isMultiline ? "72px" : "24px"
                    }}
                    multiline={isMultiline}
                    placeholder={placeholder}
                    InputProps={{
                        disableUnderline: true,
                        className: this.props.classes?.inputStyle,
                        error: true,
                        startAdornment: id === "displayName" ? <><InputAdornment position="start">@</InputAdornment></> : <></>
                    }}
                />
                {
                    error !== "" && <span style={{color:"red", fontFamily: "DM Sans", fontSize: "12px"}}>{error}</span>
                }
            </div>
        )
    }

    renderTimePicker = (
        id: string,
        value: Date,
        onChangeValue: (date: Date | null) => void,
        setFieldValue: Function,
        error?: string
    ) => {
        return (
            <>
                <div style={{ position: "relative", width: "46%", fontFamily: "DM Sans" }}>
                    <DatePicker
                        wrapperClassName="datePicker"
                        data-test-id={id}
                        selected={value}
                        onChange={(date: Date | null) => { onChangeValue(date); setFieldValue(id, date) }}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={30}
                        timeCaption="Time"
                        dateFormat="h:mm aa"
                        customInput={<CustomTimeInput />}
                    />
                </div>
            </>
        )
    }

    renderActive = () => {
        return (
            <FormControl variant="filled">
                <SelectComponent
                    data-test-id={`renderDropdown`}
                    className={this.props.classes?.customdropdown}
                    value={this.state.active}
                    IconComponent={(props) => <ExpandMoreIcon style={{ color: "white" }} {...props} />}
                    displayEmpty={true}
                    MenuProps={{
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "center"
                        },
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "center"
                        },
                    }}
                    onChange={this.handleChangeActive}
                >
                    <MenuItem
                        className={this.props.classes?.dropdownitem}
                        value="Active"
                        data-test-id="Active"
                    >
                        Active
                    </MenuItem>
                    <MenuItem
                        className={this.props.classes?.dropdownitem}
                        value="Inactive"
                        data-test-id="Inactive"
                    >
                        Inactive
                    </MenuItem>
                </SelectComponent>
            </FormControl>
        )
    }

    renderDialogButton = (formValid: boolean, dirty: boolean) => {
        const disabled = (!formValid) ? {backgroundColor: "#94A3B8"}: {}
        if (this.state.showButtonDialog) {
            return (
                <div style={webStyles.viewDialogButton}>
                    <div data-test-id="discardButton" style={webStyles.buttonDiscardChange} onClick={this.onDiscardChanges}>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.192 6.84375L11.949 11.0858L7.70697 6.84375L6.29297 8.25775L10.535 12.4998L6.29297 16.7418L7.70697 18.1558L11.949 13.9137L16.192 18.1558L17.606 16.7418L13.364 12.4998L17.606 8.25775L16.192 6.84375Z" fill="#684EF3"/>
                        </svg>
                        <span style={{height: "24px"}}> Discard changes</span>
                    </div>
                    <span style={{marginRight: "32px", width: "190px"}}>
                        <CommonButton
                            label="Update profile"
                            dataTestId="updateButton"
                            onClick={this.onUpdateChanges}
                            type="submit"
                            isDisabled={!formValid}
                        />
                    </span>
                </div>
            )
        } else return <div />
    }

    renderGeneralPage = () => {
        if (this.state.currentPage === 'general') {
            const fullName = `${this.state.currentUser?.attributes?.first_name ?? ""} ${this.state.currentUser?.attributes?.last_name ?? ""}`
            return (
                <Box style={webStyles.containerPage}>
                    <Box style={{ padding: "20px 0 20px 24px" }}>
                        <Box style={webStyles.viewInfoName}>
                            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                <Avatar key={this.state.currentUser?.attributes?.profile_photo ?? ""} alt="avatar" src={this.state.currentUser?.attributes?.profile_photo || ""} style={webStyles.avatar}>
                                    {this.state.currentUser?.attributes?.first_name?.charAt(0) ?? ""}
                                </Avatar>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                <Box style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                                    <Typography style={webStyles.nameText}>{fullName}</Typography>

                                    <Typography style={webStyles.positionText}>
                                        {this.state.currentUser?.attributes?.position}
                                        {` at ${this.state.currentUser?.attributes.company || ""}`}
                                    </Typography>

                                    {this.renderActive()}
                                </Box>
                                <svg data-test-id="editIcon" onClick={this.handleEditShowProfile} style={webStyles.editIcon} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.9987 30.9997C14.0784 31.0093 14.159 31.0093 14.2387 30.9997L18.2387 29.9997C18.4161 29.9575 18.5786 29.8676 18.7087 29.7397L30.9987 17.4097C31.3712 17.0349 31.5803 16.528 31.5803 15.9997C31.5803 15.4713 31.3712 14.9644 30.9987 14.5897L29.4187 12.9997C29.2329 12.8137 29.0123 12.6662 28.7696 12.5655C28.5268 12.4649 28.2665 12.4131 28.0037 12.4131C27.7408 12.4131 27.4806 12.4649 27.2378 12.5655C26.995 12.6662 26.7744 12.8137 26.5887 12.9997L14.2987 25.2897C14.1695 25.4203 14.0764 25.5822 14.0287 25.7597L13.0287 29.7597C12.9927 29.889 12.9832 30.0244 13.0007 30.1575C13.0182 30.2906 13.0623 30.4189 13.1304 30.5346C13.1986 30.6504 13.2893 30.7512 13.3972 30.8311C13.5052 30.911 13.6281 30.9683 13.7587 30.9997C13.8384 31.0093 13.919 31.0093 13.9987 30.9997ZM27.9987 14.4097L29.5887 15.9997L27.9987 17.5897L26.4187 15.9997L27.9987 14.4097ZM15.9087 26.5097L24.9987 17.4097L26.5887 18.9997L17.4887 28.0997L15.3787 28.6197L15.9087 26.5097Z" fill="#5E6671" />
                                </svg>
                            </Box>
                        </Box>
                    </Box>

                    <Formik
                        initialValues={{
                            displayName: this.state.displayNameValue,
                            bio: this.state.bioValue,
                            email: this.state.emailValue,
                            timeZone: this.state.timezone,
                            startDate: this.state.startDate,
                            endDate: this.state.endDate,
                            status: this.state.active
                        }}
                        validationSchema={this.editProfileValidationSchema}
                        onSubmit={(values) => {this.onUpdateChanges()}}
                        enableReinitialize
                        data-test-id="formik"
                    >
                    {({isValid, dirty, setFieldValue, errors}) => (
                            <Form data-test-id="general-form" onSubmit={(e) => e.preventDefault()}
                                style={{display: "flex", justifyContent: "space-between",flexDirection: "column",height: "100%"}}
                            >
                    <Box style={{...webStyles.viewMainInfo, padding:"24px 40px 100px"}}>
                                    <Box style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
                                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Box  >
                                                <Typography style={webStyles.titleField}>Displayed Name</Typography>
                                                <Typography style={webStyles.descriptionField}>How your name will appear to user</Typography>
                                            </Box>
                                            {this.renderInputField(
                                                "displayName",
                                                this.state.displayNameValue,
                                                "Please fill your display name",
                                                this.onChangeDisplayName,
                                                false,
                                                setFieldValue,
                                                errors.displayName
                                            )}
                                        </Box>

                                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Box >
                                                <Typography style={webStyles.titleField}>Bio</Typography>
                                            </Box>
                                            {this.renderInputField(
                                                "bio",
                                                this.state.bioValue,
                                                "Please fill your bio",
                                                this.onChangeBio,
                                                false,
                                                setFieldValue,
                                                errors.bio
                                            )}
                                        </Box>

                                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                            <Box  >
                                                <Typography style={webStyles.titleField}>Email</Typography>
                                                <Typography style={webStyles.descriptionField}>Alternative email to contact you. Will be shown for <br />other users. Can be differnt from an email registered<br /> for your account.</Typography>
                                            </Box>
                                            {this.renderInputField(
                                                "email",
                                                this.state.emailValue,
                                                "Please fill your alternate email",
                                                this.onChangeEmail,
                                                true,
                                                setFieldValue,
                                                errors.email
                                            )}
                                        </Box>
                                    </Box>

                                    <hr style={webStyles.divider} />

                                    <Box style={webStyles.viewPicker}>
                                        <Box >
                                            <Typography style={webStyles.titleField}>Time zone</Typography>
                                        </Box>
                                        <Box style={webStyles.dropdownView}>
                                            <div style={{ width: "100%", fontFamily: "DM Sans" }}>
                                                <TimezoneSelect
                                                    // @ts-ignore
                                                    menuPortalTarget={document.body}
                                                    // @ts-ignore
                                                    menuPosition={'fixed'}
                                                    value={this.state.timezone}
                                                    data-test-id="timezone"
                                                    onChange={(timezone: {
                                                        value: string;
                                                        label: string;
                                                        abbrev: string;
                                                        altName: string;
                                                    }) => {
                                                        this.onChangeTimezone(timezone);
                                                        setFieldValue("timeZone", timezone.value)
                                                    }}
                                                />
                                    <span style={{color:"red", fontSize: "12px"}}>{errors.timeZone}</span>
                                            </div>
                                        </Box>
                                    </Box>
                                    <div style={{ height: "24px" }} />
                                    <Box style={webStyles.viewPicker}>
                                        <Box >
                                            <Typography style={webStyles.titleField}>Working Hours</Typography>
                                        </Box>
                            <Box style={{width: "50%"}}>
                                <div style={{...webStyles.dropdownView, width: "100%"}}>
                                                {this.renderTimePicker("startDate", this.state.startDate, this.onChangeStartDate, setFieldValue, errors.startDate as string)}
                                                <span>-</span>
                                {this.renderTimePicker("endDate", this.state.endDate, this.onChangeEndDate, setFieldValue, errors.endDate  as string)}
                                            </div>
                                <span style={{fontSize: "12px", color: "red", width: "100%", fontFamily: "DM Sans"}}>{errors.startDate} {errors.endDate}</span>
                                        </Box>
                                    </Box>
                                </Box>
                                {this.renderDialogButton(isValid, dirty)}
                            </Form>
                        )}
                    </Formik>
                </Box>
            )
        } else return <div />
    }

    renderListNotiSetting = () => {
        if (this.state.showNotiList) {
            return (
                <div style={{ marginTop: "24px", marginBottom: "7px", gap: "24px" }}>
                    {
                        this.state.listWorkspaceNoti.map((item) => (
                            <ItemNotiWorkspaceSetting item={item} />
                        ))
                    }
                </div>
            )
        } else return <div />
    }

    renderNotificationPage = () => {
        if (this.state.currentPage === 'notifications') {
            return (
                <Box style={webStyles.containerPage}>
                    <Box style={{ ...webStyles.viewNotiTitle, ...{ borderBottomWidth: this.state.isNotificationsEnabled ? 1 : 0 }}}>
                        <Box>
                            <Typography style={webStyles.headerNotiTitle}>Notifications & Alerts</Typography>
                            <Typography style={webStyles.descriptionField}>I want to receive notifications and alerts</Typography>
                        </Box>

                        <Box>
                            <StyledSwitch data-test-id='shownotification' checked={this.state.isNotificationsEnabled} onChange={this.handleNotificationsToggle} />
                        </Box>
                    </Box>

                    {this.state.isNotificationsEnabled ? (
                        <>
                            <Box style={webStyles.viewNotiList}>
                                <div style={webStyles.viewHeaderNotiList}>
                                    <Box>
                                        <Typography style={webStyles.titleField}>Activity in your workspaces</Typography>
                                        <Typography style={webStyles.descriptionField}>
                                            Receive email and in-app notifications for new messages, Invites, File Uploads, Mentions and workspace property changes
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <StyledSwitch data-test-id='showNotiList' checked={this.state.showNotiList} onChange={this.handleNotificationsListToggle} />
                                    </Box>
                                </div>
                                {this.renderListNotiSetting()}
                                {this.state.showNotiList &&
                                <div style={{...webStyles.viewHeaderNotiList, padding: "0 0 0 16px"}}>
                                        <Box>
                                            <Typography style={webStyles.titleField}>Leases Workspace</Typography>
                                            <Typography style={webStyles.descriptionField}>Get notified about Leases Workspace activity</Typography>
                                        </Box>
                                        <Box>
                                            <StyledSwitch data-test-id='showNotiList' />
                                        </Box>
                                    </div>
                                }
                            </Box>
                            <Box style={webStyles.viewNotiDetail}>
                                <Box>
                                    <Typography style={webStyles.titleField}>Security Alerts</Typography>
                                    <Typography style={webStyles.descriptionField}>Get notified about important security alerts, such changes to your account</Typography>
                                </Box>

                                <Box>
                                    <StyledSwitch />
                                </Box>
                            </Box>
                            <Box style={{...webStyles.viewNotiDetail,margin: "0px 40px 40px"}}>
                                <Box>
                                    <Typography style={webStyles.titleField}>Announcement and update email</Typography>
                                    <Typography style={webStyles.descriptionField}>Receive occasional emails about product launches and new features from EmergeGen</Typography>
                                </Box>

                                <Box>
                                    <StyledSwitch />
                                </Box>
                            </Box>
                        </>
                    ) : (
                        !this.state.isNotificationsEnabled && (
                            <img src={notificationDisabled} alt="nonotification" style={webStyles.emptyNoti} />
                        )
                    )}

                </Box>
            )
        } else return <div />
    }

    renderPage = () => {
        if (!this.state.showPage) {
            return (
                <Box style={{ background: "#f6f5f9", width: "100%", height: "100%" }}>
                    <Box style={webStyles.viewHeaderApp}>
                        <Typography style={webStyles.headerAppProfile}>Profile settings</Typography>
                        <svg style={webStyles.notiIcon} width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29 23.586V20C29 16.783 26.815 14.073 23.855 13.258C23.562 12.52 22.846 12 22 12C21.154 12 20.438 12.52 20.145 13.258C17.185 14.074 15 16.783 15 20V23.586L13.293 25.293C13.2 25.3857 13.1262 25.4959 13.0759 25.6172C13.0256 25.7386 12.9998 25.8687 13 26V28C13 28.2652 13.1054 28.5196 13.2929 28.7071C13.4804 28.8946 13.7348 29 14 29H30C30.2652 29 30.5196 28.8946 30.7071 28.7071C30.8946 28.5196 31 28.2652 31 28V26C31.0002 25.8687 30.9744 25.7386 30.9241 25.6172C30.8738 25.4959 30.8 25.3857 30.707 25.293L29 23.586ZM29 27H15V26.414L16.707 24.707C16.8 24.6143 16.8738 24.5041 16.9241 24.3828C16.9744 24.2614 17.0002 24.1313 17 24V20C17 17.243 19.243 15 22 15C24.757 15 27 17.243 27 20V24C27 24.266 27.105 24.52 27.293 24.707L29 26.414V27ZM22 32C22.6193 32.0008 23.2235 31.8086 23.7285 31.4502C24.2335 31.0917 24.6143 30.5849 24.818 30H19.182C19.3857 30.5849 19.7665 31.0917 20.2715 31.4502C20.7765 31.8086 21.3807 32.0008 22 32Z" fill="#14101E"/>
                        </svg>
                    </Box>
                    <Box style={{ display: "flex", height: "89.55vh" }}>
                        <Box style={webStyles.viewLeftContent}>
                            <Box style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                {this.renderButtonScreen("generalButtonScreen", "general", "General", this.state.currentPage === "general")}
                                {this.renderButtonScreen("notificationsButtonScreen", "notifications", "Notfications and alerts", this.state.currentPage === "notifications")}
                                {this.renderButtonScreen("accountsButtonScreen", "accounts", "Accounts and security", this.state.currentPage === "accounts")}
                            </Box>

                            <Box style={{ display: "flex", flexDirection: "column", justifyContent: "end" }}>
                                <Box data-test-id="helpIcon" style={webStyles.normalPage} onClick={this.toggleHelpSupportDialogue}>
                                    <ChatOutlinedIcon />
                                    <Typography style={webStyles.helpButton}>Help & support</Typography>
                                </Box>
                                <Box data-test-id='logout' onClick={this.handleLogoutModal} style={webStyles.viewLogOutButton}>
                                    <ArrowBackOutlinedIcon style={{ color: "red" }} />
                                    <Typography style={webStyles.logOutButton}>Log out</Typography>
                                </Box>
                            </Box>
                        </Box>
                        {this.renderGeneralPage()}

                        {this.renderNotificationPage()}

                        {this.state.currentPage === 'accounts' && (

                            <Box style={{ width: "100%", flexDirection: "column", display: "flex", overflowY: "scroll",justifyContent: "space-between", backgroundColor: "white" }}>
                                <Box>
                                <Box style={{ padding: "20px 40px" }}>
                                    <Typography style={{ fontSize: "24px", fontWeight: 400, fontFamily: "DM Sans" }}>
                                        Account and security settings
                                    </Typography>
                                </Box>
                                <Box data-test-id='changeemail' onClick={this.showChangeEmail} style={{ display: 'flex', justifyContent: "space-between", padding: " 14px 40px" }}>
                                    <Box style={{ display: "flex", flexDirection: "column" }}>
                                        <Typography style={{ fontSize: "16px", fontWeight: 700, fontFamily: "DM Sans", cursor: "pointer" }}>
                                            Change email address
                                        </Typography>
                                        <Typography style={{ fontSize: "14px", fontWeight: 400, color: "#684EF3", fontFamily: "DM Sans" }}>
                                            {this.state.currentUser?.attributes?.email ?? ""}
                                        </Typography>
                                    </Box>
                                    <Box style={{cursor: "pointer"}}>
                                        <NavigateNextIcon />
                                    </Box>
                                </Box>

                                {this.renderDivider()}

                                <Box data-test-id='changepassword' onClick={this.toggleChangePasswordDialogue} style={{ display: 'flex', justifyContent: "space-between", padding: "14px 40px", fontFamily: "DM Sans" }}>
                                    <Box style={{ display: "flex", flexDirection: "column" }}>
                                        <Typography style={{ fontSize: "16px", fontWeight: 700, fontFamily: "DM Sans", cursor: "pointer" }}>
                                            Change Password
                                        </Typography>
                                        <Typography style={{ fontSize: "14px", fontWeight: 400, color: "#5E6671", fontFamily: "DM Sans", cursor: "pointer" }}>
                                            Use a strong password
                                        </Typography>
                                    </Box>
                                    <Box style={{ cursor: "pointer" }}>
                                        <NavigateNextIcon />
                                    </Box>
                                </Box>
                                {this.renderDivider()}

                                <Box style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", padding: "14px 40px" }}>
                                    <Box style={{ display: "flex", flexDirection: "column"}}>
                                        <Typography style={{ fontSize: "16px", fontWeight: 700, fontFamily: "DM Sans" }}>
                                            Who can see your activity status?
                                        </Typography>

                                    </Box>
                                    <StyledSelect>
                                        <CustomSelect
                                            name="activity-status"
                                            data-test-id="activity-status"
                                            options={[{label: "Everyone", value: "Everyone"}, {label: "Only me", value: "Only_me"}]}
                                            value={this.state.activityStatus}
                                            onChange={this.handleActivityStatus}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        marginTop: "0"
                                                    }
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "center"
                                                },
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "center"
                                                },
                                            }}
                                        />
                                    </StyledSelect>
                                </Box>
                                {this.renderDivider()}

                                <Box style={{ display: 'flex', justifyContent: "space-between", alignItems: "center", padding: "14px 40px" }}>
                                    <Box style={{ display: "flex", flexDirection: "column"}}>
                                        <Typography style={{ fontSize: "16px", fontWeight: 700, fontFamily: "DM Sans" }}>
                                            Who can see your contact information?
                                        </Typography>

                                    </Box>
                                    <StyledSelect>
                                        <CustomSelect
                                            name="contact-information"
                                            data-test-id="contact-information"
                                            options={[{label: "Everyone", value: "Everyone"}, {label: "Only me", value: "Only_me"}]}
                                            value={this.state.contactInformation}
                                            onChange={this.handleContactInformation}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        marginTop: "0"
                                                    }
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "center"
                                                },
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "center"
                                                },
                                            }}
                                        />
                                    </StyledSelect>
                                </Box>
                                {this.renderDivider()}

                                <Box data-test-id='termsConditions' style={{ display: 'flex', justifyContent: "space-between", padding: "14px 40px", }} onClick={this.toggleTermsConditions}>
                                    <Box style={{ display: "flex", flexDirection: "column"}}>
                                        <Typography style={{ fontSize: "16px", fontWeight: 700, cursor: "pointer", fontFamily: "DM Sans" }}>
                                            Terms and Conditions/Privacy Policy
                                        </Typography>

                                    </Box>
                                    <Box>
                                        <NavigateNextIcon style={{cursor: "pointer"}}/>
                                    </Box>
                                </Box>
                                {this.renderDivider()}
                                </Box>
                                <Box style={{ display: "flex", flexDirection: "column", paddingTop: "6rem" }}>
                                    <Box style={{ display: 'flex', background: "#FEE2E2", justifyContent: "space-between", alignItems: "end", height: "100%", padding: "24px 40px 40px" }}>
                                        <Box data-test-id='previous' onClick={this.handleBackToModal} style={{ display: "flex", flexDirection: "column", cursor: "pointer" }}>
                                            <Typography style={{ fontSize: "16px", fontWeight: 700, color: '#DC2626', fontFamily: "DM Sans" }}>
                                                Delete an account
                                            </Typography>
                                            <Typography style={{ fontSize: "14px", fontWeight: 400, fontFamily: "DM Sans" }}>
                                                Delete your account and all account data
                                            </Typography>
                                        </Box>
                                        <Box style={{ display: "flex", cursor: "pointer" }}>
                                            <NavigateNextIcon />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </Box>
            )
        } else return <div />
    }

    renderDivider = () => {
        return(
            <>
                <Box style={{padding: "0px 40px"}}>
                    <hr style={{ color: "#E2E8F0" }} />
                </Box>
            </>
        )
    }

    renderHelpSupportDialogue = () => {
        return(
            <>
                <CommonDialogue 
                    open={this.state.openHelpSupportDialogue} 
                    title={"Help & support"} 
                    body={
                        <Typography style={{fontWeight: 400, fontSize: "16px", fontFamily: "DM Sans"}}>
                            <Typography style={{paddingBottom: "16px"}}> If you have any questions or run into issues, our support team is ready to assist you.</Typography>
                            You can reach us at <span style={{fontWeight: 700, fontSize: "16px"}}>support@yourapp.com</span>  for any inquiries or assistance. We typically respond within 24 hours.
                        </Typography>
                    } 
                    submitBtnText={"Got it!"} 
                    handleCancel={this.toggleHelpSupportDialogue} 
                    handleSubmit={this.toggleHelpSupportDialogue}                    
                />
            </>
        )
    }

    renderChangeEmailDialogue = () => {
        return(
            <>
                <CustomDialogWithAction
                    data-test-id="change-email-address"
                    open={this.state.showChangeEmail}
                    handleClose={this.showChangeEmail}
                    hideCloseButton={true}
                    title={"Change email address"}
                    dialogContent={
                        <>

                            <Formik
                                initialValues={{
                                    email: "",
                                    confirmEmail: "",
                                }}
                                validationSchema={this.changeEmailValidationSchema}
                                onSubmit={(values, formikHelper) => this.sendOTPonChangeEmail(values,formikHelper)}
                                data-test-id="change-email-address-formik"
                            >
                                {({ isValid, dirty }) => (
                                    <Form data-test-id="change-email-address-form">
                                        <DialogContent style={{ width: "495px", paddingBottom: 0, boxSizing: "border-box", display: "flex", flexDirection: "column", gap: "14px" }} dividers>
                                            <CustomInput
                                                formik
                                                name="email"
                                                label="New email"
                                                placeholder="Enter new email"
                                            />
                                            <CustomInput
                                                formik
                                                name="confirmEmail"
                                                label="Confirm new email"
                                                placeholder="Confirm new email"
                                            />
                                            <br></br>
                                        </DialogContent>
                                <DialogActions style={{paddingTop: "8px"}}>
                                    <Box style={{display: "flex", width: "100%", gap: "16px"}}>
                                        <Box style={{width: "50%"}}>
                                                    <CommonButton
                                                        label="Cancel"
                                                        onClick={this.showChangeEmail}
                                                        dataTestId="cancel"
                                                        data-test-id="download-submit"
                                                        variant="secondary"
                                                    />
                                                </Box>

                                        <Box style={{width: "100%"}}>
                                                    <CommonButton
                                                        label="Receive verification code"
                                                        type="submit"
                                                        onClick={() => {}}
                                                        dataTestId="receive-verification-code"
                                                        data-test-id="receive-verification-code"
                                                        variant="primary"
                                                        isDisabled={!isValid || !dirty}
                                                    />
                                                </Box>
                                            </Box>


                                        </DialogActions>
                                    </Form>
                                )}
                            </Formik>
                        </>
                    }
                />
            </>
        )
    }

    renderVerifyEmailDialogue = () => {
        return(
            <>
                <CustomDialogWithAction
                    data-test-id="verify-otp-dialogue"
                    open={this.state.showVerifyEmail}
                    handleClose={this.showVerifyEmail}
                    title={"Verify your new email"}
                    dialogContent={
                        <>

                            <Formik
                                initialValues={{
                                    otpNumber: ""
                                }}
                                validationSchema={this.verifyEmailOTPValidationSchema}
                                onSubmit={this.verifyEmailOtp}
                                data-test-id="verify-otp-formik"
                            >
                            {({isValid, dirty, values, setFieldValue, errors}) => (
                                    <Form data-test-id="verify-otp-form">
                                        <DialogContent style={{ width: "495px", paddingBottom: 0, boxSizing: "border-box" }} dividers>

                                <Typography style={{fontWeight: 400, fontSize: "16px", fontFamily: "DM Sans"}}>
                                    <Typography style={{paddingBottom: "4px"}}>We've just sent a 5-digit code to an email</Typography>
                                    <span style={{fontWeight: 700, fontSize: "16px"}}>{this.state.changeEmail}</span> , enter it below
                                            </Typography>

                                            <OTPInput
                                                value={values.otpNumber}
                                                inputType="tel"
                                                onChange={(otp) => {setFieldValue('otpNumber', otp)}}
                                                containerStyle={{ outline: "none", display: "flex", gap: "16px", padding: "24px 0px" }}
                                                data-test-id="otpinput"
                                                numInputs={5}
                                                renderInput={(props) => <input {...props} />}
                                                inputStyle={{
                                                    width: '73px',
                                                    height: '56px',
                                                    padding: '10px 12px 10px 12px',
                                                    borderRadius: '8px',
                                                    border: errors?.otpNumber ? '1px solid red' : '1px solid rgba(205, 215, 230, 1)',
                                                    backgroundColor: 'rgba(255, 255, 255, 1)',
                                                }}
                                            />
                                {this.state.error && <span style={{fontSize: "12px", color: "#DC2626", fontFamily: "DM Sans"}}>{this.state.error}</span>}
                                        </DialogContent>
                                <DialogActions style={{paddingTop: "8px", paddingBottom: "8px"}}>
                                    <Box style={{display: "flex", flexDirection:"column", width: "100%", gap: "16px"}}>
                                        <Box style={{width: "100%"}}>
                                                    <CommonButton
                                                        label="Verify"
                                                        type="submit"
                                                        onClick={() => {}}
                                                        dataTestId="verify-otp"
                                                        data-test-id="verify-otp"
                                                        variant="primary"
                                                        isDisabled={!isValid || !dirty}
                                                    />
                                                </Box>

                                        <Box style={{width: "100%", display: "flex", justifyContent: "center"}}>
                                                    <Button
                                            style={{color: "#684EF3",textTransform: "none"}}
                                                        onClick={() => this.resendEmailOtp()}
                                                        data-test-id="resend-email"

                                                    >
                                                        Resend email
                                                    </Button>

                                                </Box>
                                            </Box>


                                        </DialogActions>
                                    </Form>
                                )}
                            </Formik>
                        </>
                    }
                />
            </>
        )
    }

    renderTermsConditionDialogue = () => {
        return (
            <>
                <UserTermsConditions navigation={undefined} id={""}
                    closeDialogue={this.toggleTermsConditions}
                />
            </>
        )
    }

    renderChangePasswordDialogue = () => {

        return (
            <>
                <CustomDialogWithAction
                    data-test-id="change-password-dialogue"
                    open={this.state.changePasswordDialogue}
                    handleClose={this.toggleChangePasswordDialogue}
                    hideCloseButton={true}
                    title={"Change password"}
                    dialogContent={
                        <>

                            <Formik
                                initialValues={{
                                    currentPassword: "",
                                    password: "",
                                    confirmPassword: ""
                                }}
                                validationSchema={this.changePasswordValidationSchema}
                                onSubmit={this.changePassword}
                                data-test-id="change-password-formik"
                            >
                                {({ isValid, dirty, values }) => (
                                    <Form data-test-id="change-password-form" style={{ overflowX: "hidden"}}>
                                        <DialogContent style={{ width: "495px", paddingBottom: 0, boxSizing: "border-box", overflowY: "auto" }} dividers>
                                            <Box style={{display: "flex", flexDirection: "column", gap: "14px"}}>
                                            <CustomPassword
                                                formik
                                                name="currentPassword"
                                                label="Current password"
                                                placeholder="Enter current password"
                                            />
                                            <CustomPassword
                                                formik
                                                name="password"
                                                label="New password"
                                                placeholder="Enter new password"
                                            />
                                            <CustomPassword
                                                formik
                                                name="confirmPassword"
                                                label="Confirm password"
                                                placeholder="Confirm new password"
                                            />

                                            <Box style={webStyles.paswordValidationWrapper}>
                                                <Typography style={webStyles.paswordValidationTitle}>
                                                    Your password must contain
                                                </Typography>
                                                <Typography style={webStyles.paswordValidation}>
                                                    <img src={values.password.match(/[A-Z]/) ? complete : pending}/> At least one capital letter
                                                </Typography>
                                                <Typography style={webStyles.paswordValidation}>
                                                    <img src={values.password.match(/[a-z]/) ? complete : pending}/> At least one lowercase letter
                                                </Typography>
                                                <Typography style={webStyles.paswordValidation}>
                                                    <img src={values.password.match(/[0-9]/) ? complete : pending}/> At least one number
                                                </Typography>
                                                <Typography style={webStyles.paswordValidation}>
                                                    <img src={values.password.match(/.{8,}/) ? complete : pending}/> Minimum character length is 8 characters
                                                </Typography>
                                            </Box>
                                            </Box>
                                            <br></br>
                                        </DialogContent>
                                        <DialogActions style={{ paddingTop: "8px" }}>
                                            <Box style={{ display: "flex", width: "100%", gap: "16px" }}>
                                                <Box style={{ width: "50%" }}>
                                                    <CommonButton
                                                        label="Cancel"
                                                        onClick={this.toggleChangePasswordDialogue}
                                                        dataTestId="cancel"
                                                        data-test-id="close-submit"
                                                        variant="secondary"
                                                    />
                                                </Box>

                                                <Box style={{ width: "100%" }}>
                                                    <CommonButton
                                                        label="Change password"
                                                        type="submit"
                                                        onClick={() => { }}
                                                        dataTestId="change-password-btn"
                                                        data-test-id="change-password-btn"
                                                        variant="primary"
                                                        isDisabled={!isValid || !dirty}
                                                    />
                                                </Box>
                                            </Box>


                                        </DialogActions>
                                    </Form>
                                )}
                            </Formik>
                        </>
                    }
                />
            </>
        )
    }

    render() {
        return (
            <div style={{ display: "flex", flexDirection: "row", backgroundColor: "#E2E8F0", height: "100vh" }}>
                      <div style={{ position: "sticky" }}>
                        <NavigationMenu navigation={this.props.navigation} id="dashboard" />
                      </div>
            <Box style={{ width: "100%", display: "flex", justifyContent: "center" }} >
                {this.renderPage()}

                <ConfirmationDialog
                    id="DeleteAccountDialog"
                    isDialogOpen={this.state.showModal}
                    onCloseDialog={this.handleBackToModal}
                    title={"Are you sure you want to delete an account?"}
                    description={"Please confirm you want to delete an account. This action will delete all your personal data."}
                    titleCancelButton={"Back to Profile Settings"}
                    titleOkButton={"Delete account and data"}
                    onOkCloseDialog={this.onOkDeleteDialog}
                    checkConfirmationTitle="I confirm I want to delete my account and data"
                />

                <ConfirmationDialog
                    id="ConfirmationDialog"
                    isDialogOpen={this.state.showLogoutModal}
                    onCloseDialog={this.handleLogoutModal}
                    title={"Are you sure you want to log out?"}
                    description={"Please confirm you want to log out."}
                    titleCancelButton={"Back to Profile Settings"}
                    titleOkButton={"Log out"}
                    onOkCloseDialog={this.onOkLogOutDialog}
                />

                {this.state.showEditModal &&
                    <UserProfileDialog
                        id="UserProfileDialog"
                        isDialogOpen={this.state.showEditModal}
                        onCloseDialog={this.handleEditShowProfile}
                        classes={this.props?.classes}
                        isEdit
                        onSuccessDialog={this.reloadProfile}
                    />
                }
                <Loader loading={this.state.loading} />

                <CommonSnackbar
                    open={this.state.isSnackbarOpen}
                    message={this.state.snackbarContent}
                    handleClose={this.handleClose}
                    severity={this.state.snackbarErrorSeverity ? "error" : "success"}
                />

                {this.renderHelpSupportDialogue()}
                {this.renderVerifyEmailDialogue()}
                {this.renderChangeEmailDialogue()}
                {this.renderChangePasswordDialogue()}

                {this.state.openTermsConditionDialogue && this.renderTermsConditionDialogue()}
            </Box>
            </div>
        );
    }
}

const webStyles = {
    emptyNoti: {
        margin: "0px 40px",
        paddingBottom: "40px"
    },
    containerPage: {
        width: "100%",
        flexDirection: "column",
        display: "flex",
        position: 'relative',
        overflowY: "auto",
        backgroundColor: "white"
    } as React.CSSProperties,
    viewNotiTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "32px 0",
        margin: "0 40px",
        borderBottom: "1px solid #E2E8F0"
    } as React.CSSProperties,
    viewNotiDetail: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "17px 0",
        margin: "0 40px",
        borderBottom: "1px solid #E2E8F0"
    } as React.CSSProperties,
    viewNotiList: {
        display: "flex",
        flexDirection: "column",
        margin: "0 40px",
        padding: "17px 0",
        borderBottom: "1px solid #E2E8F0",
    } as React.CSSProperties,
    viewHeaderNotiList: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    } as React.CSSProperties,
    itemNotiSetting: {

    } as React.CSSProperties,
    headerAppProfile: {
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: '24px',
        lineHeight: '33.6px',
        fontWeight: 400
    },
    notiIcon: {
        width: "44px",
        height: "44px",
    },
    viewHeaderApp: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "#f6f5f9",
        padding: "18px 40px",
        borderBottom: "1px solid #E2E8F0"
    },
    chosenPage: {
        backgroundColor: "#EAE6FF",
        border: "1px solid #D9D1EF",
        borderRadius: "12px",
        padding: "12px",
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        cursor: "pointer",
        fontWeight: 700
    } as React.CSSProperties,
    normalPage: {
        backgroundColor: "transparent",
        padding: "12px",
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 700,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: "8px"
    } as React.CSSProperties,
    viewLogOutButton: {
        width: "100%",
        display: "flex",
        gap: "8px",
        cursor: "pointer",
        padding: "12px"
    } as React.CSSProperties,
    viewLeftContent: {
        display: "flex",
        flexDirection: "column",
        width: "26%",
        background: "#f6f5f9",
        borderRight: "1px solid #E2E8F0",
        padding: "20px 28px",
        justifyContent: "space-between"
    } as React.CSSProperties,
    helpButton: {
        color: "#30353B",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400
    },
    headerNotiTitle: {
        color: "#30353B",
        fontFamily: "DM Sans",
        fontSize: '24px',
        lineHeight: '33.6px',
        fontWeight: 400
    },
    logOutButton: {
        color: "#DC2626",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400
    },
    titleField: {
        color: "#30353B",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 700,
    },
    descriptionField: {
        color: "#5E6671",
        fontFamily: "DM Sans",
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 400,
    },
    viewInfoName: {
        background: `url(${backgroundName}) no-repeat center center fixed`,
        backgroundColor: "#e2e8f0",
        padding: "12px",
        gap: "28px",
        borderTopLeftRadius: "150px",
        borderBottomLeftRadius: "150px",
        display: "flex",
        alignItems: "center"
    } as React.CSSProperties,
    viewMainInfo: {
        height: "100%",
        background: "#FFFFFF",
        padding: "32px 40px"
    },
    avatar: {
        height: "132px",
        width: "132px",
        borderRadius: "132px",
        border: "4px solid #fff",
    },
    editIcon: {
        height: "44px",
        width: "44px",
        cursor: "pointer"
    },
    nameText: {
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: '24px',
        lineHeight: '33.6px',
        fontWeight: 400,
        minHeight: "34px"
    },
    positionText: {
        color: "#5E6671",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        minHeight: "24px",
        marginBottom: "28px"
    },
    divider: {
        width: "100%",
        color: "#E2E8F0",
        marginTop: "24px",
        marginBottom: "24px",
        borderBottom: "1px solid #E2E8F0"
    },
    viewPicker: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    } as React.CSSProperties,
    inputView: {
        display: "flex",
        flexDirection: "column",
        gap: "2px",
        width: "50%"
    } as React.CSSProperties,
    dropDownTime: {
        position: "absolute",
        zIndex: 10,
        right: 0,
        top: "12px"
    } as React.CSSProperties,
    inputTime: {
        backgroundColor: "#fff",
        border: "1px solid #D6DEEA",
        padding: '12px',
        borderRadius: "8px",
        color: "#14101E",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        width: "100%",
        textAlign: "left"
    } as React.CSSProperties,
    viewTutorialSwitch: {
        display: "flex",
        backgroundColor: "#e2e8f0",
        marginBottom: "40px",
        borderRadius: "16px",
        padding: "12px"
    } as React.CSSProperties,
    textTutorial: {
        color: "#30353B",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 700,
    },
    textDescriptionTutorial: {
        color: "#5E6671",
        fontFamily: "DM Sans",
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 400,
    },
    dropdownView: {
        display: "flex",
        justifyContent: "space-between",
        width: "50%",
        alignItems: "center",
    } as React.CSSProperties,
    iconExpand: {
        color: "#fff",
        width: "16px",
        height: "16px",
    },
    viewDialogButton: {
        backgroundColor: "#edeaff",
        display: "flex",
        justifyContent: "flex-end",
        gap: "16px",
        zIndex: 10,
        width: "100%",
        paddingTop: "24.5px",
        paddingBottom: "34.5px",
    } as React.CSSProperties,
    buttonDiscardChange: {
        display: "flex",
        borderRadius: "8px",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        padding: "10px 16px",
        color: "#684EF3",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        backgroundColor: "#EDEAFF",
        border: "1px solid #684EF3",
        cursor: "pointer",
        width: "190px"
    } as React.CSSProperties,
    buttonUpdateChange: {
        borderRadius: "8px",
        color: "#fff",
        backgroundColor: "#684EF3",
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 400,
        textAlign: "center",
        marginRight: "32px",
        cursor: "pointer"
    } as React.CSSProperties,
    viewAlert: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "21px",
        padding: "8px",
        borderRadius: "16px",
        color: "#fff",
        backgroundColor: "#33BC55"
    },
    paswordValidationWrapper: {
        display: "flex",
        flexDirection: 'column',
        gap: "8px"
    } as React.CSSProperties,
    paswordValidationTitle: {
        fontFamily: "DM Sans",
        fontSize: "16px",
        fontWeight: 400,
        color: "#30353B"
    },
    paswordValidation: {
        display: "flex",
        gap: "6px",
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 400,
        color: "#30353B"
    },
}

const useStyles: Record<string, CSSProperties> = {
    inputText: {
        border: "1px solid #E2E8F0",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "12px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        "& .MuiInputAdornment-positionStart": {
            marginRight: "2px",
            "& .MuiTypography-colorTextSecondary": {
                color: "#94A3B8"
            }
        }
    },
    inputStyle: {
        fontWeight: 400,
        fontFamily: "DM Sans",
        fontSize: '16px',
        lineHeight: '24px',
        color: "#30353B",
    },
    customdropdown: {
        borderRadius: "26px",
        fontFamily: "DM Sans",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "18px",
        border: "1px solid #E2E8F0",
        color: "#fff",
        backgroundColor: "#684EF3 !important",
        width: "100px",
        textTransform: "uppercase",
        '& .MuiFilledInput-input': {
            padding: "6px 6px 6px 12px",
            height: "fit-content",
            backgroundColor: "#684EF3",
            borderRadius: "26px",
        },
        '&::before, &::after': {
            borderBottom: "none !important"
        },
        '&:hover': {
            textDecoration: "none !important",
            backgroundColor: "#684EF3 !important",
        },
        '&.MuiSelect-select:hover': {
            backgroundColor: "#684EF3 !important",
        }
    },
    dropdownitem: {
        fontFamily: "DM Sans",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "18px",
        cursor: "pointer",
        width: "100px"
    },
}
const StyledSelect = styled(Box)({
    "& .MuiFormControl-root": {
        height: "32px",
        minHeight: "32px",
        backgroundColor: "#F6F5F9"
    },
    "& .MuiSelect-select": {
        fontSize: "14px !important",
        fontFamily: "DM Sans"
    }
})
export default withStyles(useStyles)(UserProfileBasicBlockWeb)
// Customizable Area End
