import React from "react";
// Customizable Area Start
import SsoLoginController, {
  Props,
  configJSON,
} from "./SsoLoginController.web";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
  InputAdornment,
  styled,
  CircularProgress,
} from "@material-ui/core";
import {
  backgroundImage,
  logoText,
  imgVisbility,
  imgVisbilityOff,
} from "./assets";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/Check";
import {
  renderBaseonCondition,
  renderBaseonStringCondition,
} from "../../../blocks/utilities/src/commonfunctions";
import { colors } from "../../../blocks/utilities/src/Colors";
import CommonDialogue from "../../../components/src/common/CommonDialogue.web";
// Customizable Area End

// Customizable Area Start
const string = configJSON.filesTabString;
const theme = createTheme();
// Customizable Area End

export class SsoLogin extends SsoLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderCommonDialog = () => {
    const { commonDialogOpen, commonDialogErrorMsg } = this.state;
    return (
      <>
        <CommonDialogue
          open={commonDialogOpen}
          title={"Error"}
          body={commonDialogErrorMsg}
          submitBtnText={"Go To Login"}
          cancelBtnText={"Cancel"}
          handleSubmit={this.handleSubmitCommonDialog}
          handleCancel={this.handleCloseCommonDialog}
        />
      </>
    );
  };
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      email,
      emailError,
      password,
      passwordError,
      enablePasswordField,
      isButtonDisabled,
      isLoginProcess,
      commonDialogOpen
    } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <SsoWrapperBlock>
          <Box className="mainbox">
            <Box className="logowrap">
              <img className="logoImg" src={logoText} />
            </Box>
            <Box className="loginWrapper">
              <ThemeProvider theme={theme}>
                <Container maxWidth="sm" className="loginContainer">
                  <Box
                    className="loginBlock"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Box className="loginInnerBlock">
                      <Box>
                        <Typography className="inputLabel">Email</Typography>
                        <TextField
                          className="loginInput"
                          data-test-id="txtInputEmail"
                          placeholder="Enter your email"
                          name="email"
                          value={email}
                          onChange={this.handleInputChange}
                          variant="outlined"
                          fullWidth
                          required
                          error={emailError}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {renderBaseonCondition(
                                  emailError && Boolean(email),
                                  <></>,
                                  <CheckIcon
                                    style={{ color: "#684EF3", width: "45px" }}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                        />
                        {renderBaseonCondition(
                          emailError && Boolean(email),
                          <Box className="errorBox">
                            <Typography className="errormsg">
                              Please enter a valid email address
                            </Typography>
                          </Box>,
                          <></>
                        )}
                      </Box>
                      <Box>
                        <Typography className="inputLabel">Password</Typography>
                        <TextField
                          className="loginInput"
                          name="password"
                          placeholder="Enter your password"
                          data-test-id="txtInputPassword"
                          value={password}
                          type={renderBaseonStringCondition(
                            enablePasswordField,
                            "password",
                            "text"
                          )}
                          onChange={this.handleInputChange}
                          variant="outlined"
                          fullWidth
                          required
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-test-id="icnShowPassword"
                                  onClick={this.handleClickShowPassword}
                                >
                                  <img
                                    src={
                                      this.state.enablePasswordField
                                        ? imgVisbilityOff
                                        : imgVisbility
                                    }
                                    alt="toggle visibility"
                                    style={{ width: 24, height: 24 }}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {renderBaseonCondition(
                          passwordError && Boolean(password),
                          <Box className="errorBox">
                            <Typography className="errormsg">
                              Incorrect Password
                            </Typography>
                          </Box>,
                          <></>
                        )}
                      </Box>
                    </Box>
                    <Box className="buttonBox">
                      <Button
                        data-test-id="btnEmailLogIn"
                        variant="contained"
                        color="primary"
                        className="loginButton"
                        fullWidth
                        style={{
                          backgroundColor: renderBaseonStringCondition(
                            isButtonDisabled,
                            "#94A3B8",
                            "#684EF3"
                          ),
                        }}
                        onClick={this.handleLogin}
                        disabled={isButtonDisabled}
                      >
                        {renderBaseonCondition(
                          isLoginProcess,
                          <CircularProgress size={22} color="inherit" />,
                          <>Continue</>
                        )}
                      </Button>
                    </Box>
                    <Typography
                      className="backtologin"
                      onClick={this.handleNavigateBack}
                    >
                      Back to <span className="color-blue">Login</span>
                    </Typography>
                  </Box>
                </Container>
              </ThemeProvider>
            </Box>
          </Box>
        </SsoWrapperBlock>
        {commonDialogOpen && this.renderCommonDialog()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const SsoWrapperBlock = styled(Box)({
  "& .mainbox": {
    background: `url(${backgroundImage}) no-repeat center center`,
    backgroundSize: "cover",
    width: "100%",
  },
  "& .logowrap": { paddingTop: "48px", paddingLeft: "80px" },
  "& .logoImg": { width: "141.05px", height: "28px", gap: "2.8px " },
  "& .loginWrapper": {
    display: "flex",
    justifyContent: "center",
  },
  "& .loginContainer": { margin: "62px 0 100px 0" },
  "& .loginBlock": { height: "100%", width: "464px" },
  "& .inputLabel": {
    fontFamily: "DM Sans",
    fontWeight: 700,
    fontSize: "14px",
    color: "rgba(48, 53, 59, 1)",
  },
  "& .loginInput": {
    borderRadius: "8px",
    border: "1px  #E2E8F0",
    backgroundColor: "rgba(255, 255, 255, 1)",
  },
  "& .errorBox": {
    marginTop: "5px",
    color: "red",
    fontSize: "12px",
    fontFamily: "DM Sans",
    fontWeight: 400,
  },
  "& .errormsg": {
    fontFamily: "DM Sans",
    fontSize: "12px",
  },
  "& .buttonBox": { width: "100%", marginTop: "50px" },
  "& .loginButton": {
    color: "rgba(255, 255, 255, 1)",
    fontSize: "16px",
    fontWeight: 400,
    borderRadius: "8px",
    gap: "8px",
    padding: "16px",
    textTransform: "none",
  },
  "& .loginInnerBlock": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: 32,
  },
  "& .backtologin": {
    fontFamily: "Dm Sans !important",
    fontSize: "16px",
    fontWeight: 400,
    marginTop: "50px",
    cursor: "pointer",
  },
  "& .color-blue": {
    color: colors().buttonHover,
  },
});
export default SsoLogin;
// Customizable Area End
