import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import Cookies from "js-cookie";
import {
  makeApiMessage,
  navigateToScreen,
} from "../../../blocks/utilities/src/commonfunctions";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import { handleResponseMessage } from "../../../blocks/utilities/src/handle-response-message";
export const configJSON = require("./config");
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  email: string;
  emailError: boolean;
  passwordError: boolean;
  isButtonDisabled: boolean;
  enablePasswordField: boolean;
  isLoginProcess: boolean;
  cookieEmail: string;
  cookiePassword: string;
  commonDialogOpen: boolean;
  commonDialogErrorMsg: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class SsoLoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  loginApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    // Customizable Area End

    // Customizable Area Start
    this.state = {
      email: "",
      password: "",
      emailError: false,
      passwordError: false,
      isButtonDisabled: true,
      enablePasswordField: true,
      isLoginProcess: false,
      cookieEmail: "",
      cookiePassword: "",
      commonDialogOpen: false,
      commonDialogErrorMsg: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      this.handleLoginApiResponse(webApiRequestCallId, webResponseJson);
    }
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start

    // protect route
    this.getDataFromCookies();
    // Customizable Area End
  }

  // Customizable Area Start
  handleCloseCommonDialog = () => {
    this.setState({
      commonDialogOpen: false,
    });
  };

  handleSubmitCommonDialog = () => {
    const message = navigateToScreen("EmailAccountLoginBlock", this.props);
    this.send(message);
  };

  getDataFromCookies = async () => {
    // protect route
    const token = await getStorageData("authToken");
    if (token) {
      const message = navigateToScreen("Dashboard", this.props);
      this.send(message);
    } else {
      const message = navigateToScreen("SsoLogin", this.props);
      this.send(message);
    }

    // get data from cookies
    const cookieLoginData = Cookies.get("loginAccount");
    if (cookieLoginData) {
      const loginAccount = JSON.parse(cookieLoginData);
      if (loginAccount.email && loginAccount.password) {
        this.setState({
          cookieEmail: loginAccount.email,
          cookiePassword: loginAccount.password,
          email: loginAccount.email,
          password: loginAccount.password,
          isButtonDisabled: false,
        });
      }
    }
  };

  handleInputChange = (
    event: React.ChangeEvent<{ name: string; value: string }>
  ) => {
    const { name, value } = event.target;
    let { isButtonDisabled } = this.state;
    let { emailError } = this.state;
    let { passwordError } = this.state;

    switch (name) {
      case "email":
        if (value === "") {
          isButtonDisabled = true;
          emailError = true;
        } else if (
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
        ) {
          isButtonDisabled = false;
          emailError = false;
        }
        this.setState({ email: value, emailError, isButtonDisabled });
        break;
      case "password":
        if (value === "") {
          isButtonDisabled = true;
          passwordError = true;
        } else if (
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/.test(value)
        ) {
          isButtonDisabled = false;
          passwordError = false;
        }
        this.setState({ password: value, passwordError, isButtonDisabled });
        break;
    }
  };

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };

  validateCookieEmail = () => {
    const { cookieEmail, cookiePassword, email, password } = this.state;
    
    const ERROR_MESSAGES = {
      EMAIL: "You cannot change email while logged in with SSO",
      PASSWORD: "You cannot change password"
    };
  
    if (email !== cookieEmail) {
      this.setState({ 
        commonDialogOpen: true, 
        commonDialogErrorMsg: ERROR_MESSAGES.EMAIL 
      });
      return true;
    }
  
    if (cookiePassword !== password) {
      this.setState({ 
        commonDialogOpen: true, 
        commonDialogErrorMsg: ERROR_MESSAGES.PASSWORD 
      });
      return true;
    }
  
    return false;
  };

  handleLogin = async () => {
    if (this.validateCookieEmail()) return;

    const { email, password } = this.state;

    // Validate email
    const emailError =
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) ||
      email === "";

    // Validate password
    const passwordError =
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/.test(password) ||
      password === "";

    // Update state with error statuses
    this.setState({ emailError, passwordError });

    if (emailError || passwordError) {
      this.setState({ emailError: emailError, passwordError: passwordError });
    } else {
      const requestBody = {
        data: {
          type: "email_account",
          attributes: {
            email: this.state.email,
            password: this.state.password,
          },
        },
      };
      const apiUrl = configJSON.loginEndPoint;
      const requestMessage = await makeApiMessage({
        url: apiUrl,
        method: "POST",
        body: JSON.stringify(requestBody),
      });

      this.setState({ isLoginProcess: true });
      this.loginApiCallId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleNavigateBack = () => {
    const loginPage = navigateToScreen("EmailAccountLoginBlock", this.props);
    this.send(loginPage);
  };

  handleLoginApiResponse = (
    apiCallId: string,
    response: {
      error: unknown;
      errors: unknown;
      meta: {
        token: string;
        id: string;
      };
    }
  ) => {
    if (apiCallId === this.loginApiCallId) {
      handleResponseMessage({
        responseJson: response,
        errorJson: null,
        onSuccess: () => {
          this.setState({ isLoginProcess: false });
          const token = response.meta.token;
          const account_id = response.meta.id;
          setStorageData("authToken", token);
          setStorageData("account_id", account_id);

          // navigate to dashboard
          const dashboard = navigateToScreen("Dashboard", this.props);
          this.send(dashboard);
        },
        onFail: () => {
          this.setState({ isLoginProcess: false });
          const dashboard = navigateToScreen(
            "EmailAccountLoginBlock",
            this.props
          );
          this.send(dashboard);
        },
      });
    }
  };
  // Customizable Area End
}
