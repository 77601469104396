// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { makeApiMessage } from "../../../blocks/utilities/src/commonfunctions";

export const configJSON = require("./config");

export interface ITermsConditions {
  id: string;
  attributes: {
    created_at: string;
    description: string;
    accepted_by: [
      {
        account_id: string;
        accepted_on: string;
        email: string;
      }
    ];
  };
}
interface Section {
  id: string;
  header: string;
  content: string;
}

export interface Props {
  navigation: any;
  id: string;
  closeDialogue: Function;
}

interface S {
  token: string;
  termsCondsId: string;
  termsConds: ITermsConditions | null;
  termsConditions: any;
  name: string;
  reviseddate: any;
  pdfURL: string;
  isLoading: boolean;
  isOpen: boolean;
  isTearmsOpen: boolean;
  isPrivacyPolicyOpen: boolean;
  showIcon: boolean;
  showIconTearms: boolean;
  showIconPrivacyPolicy: boolean;
  isActive: boolean;
  sections: Section[];
  activeTab: "privacy" | "terms";
}

interface SS {
  id: string;
}

export default class UserTermsConditionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getPrivacyApiCallId: any;
  getTermsCondsCallId = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      token: "",
      termsCondsId: "",
      termsConds: null,
      termsConditions: [],
      name: "Terms and Conditions",
      pdfURL: "",
      isLoading: false,
      isOpen: false,
      isTearmsOpen: false,
      isPrivacyPolicyOpen: false,
      reviseddate: "",
      showIcon: true,
      showIconTearms: true,
      showIconPrivacyPolicy: true,
      isActive: false,
      sections: [],
      activeTab: "privacy",
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.handleGetPrivacy();
  }

  parseContent = () => {
    const { termsConditions } = this.state;
    const sections: Section[] = [];

    const regex = /<h3>(.*?)<\/h3>([\s\S]*?)(?=<h3>|$)/g;
    let match;
    let index = 0;

    while ((match = regex.exec(termsConditions)) !== null) {
      sections.push({
        id: `section-${index}`,
        header: match[1].trim(),
        content: match[2].trim(),
      });
      index++;
    }

    this.setState({ sections });
  };

  handleTearms = () => {
    if (this.state.isTearmsOpen === true) {
      this.setState({ isTearmsOpen: false, showIconTearms: true });
    } else {
      this.setState({
        isTearmsOpen: true,
        showIconTearms: false,
        activeTab: "terms",
        isPrivacyPolicyOpen: false,
        showIconPrivacyPolicy: true,
      });
    }
    this.getTearmsAndCondition();
  };

  getTearmsAndCondition = () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrivacyApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTearmsEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleScroll = (id: any) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  handlePrivacy = () => {
    const { isPrivacyPolicyOpen } = this.state;

    if (isPrivacyPolicyOpen) {
      this.setState({
        isPrivacyPolicyOpen: false,
        showIconPrivacyPolicy: true,
      });
    } else {
      this.setState({
        isPrivacyPolicyOpen: true,
        showIconPrivacyPolicy: false,
        activeTab: "privacy",
        isTearmsOpen: false,
        showIconTearms: true,
      });
    }

    this.handleGetPrivacy();
  };

  handleGetPrivacy = async () => {
    const apiUrl = configJSON.getPrivacyEndpoint;
    const requestMessage = await makeApiMessage({
      url: apiUrl,
      method: "GET",
    });

    this.getPrivacyApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getPrivacyApiCallId) {
        if (responseJson?.data) {
          this.setState({
            reviseddate: responseJson.data.attributes.created_at,
            pdfURL: responseJson.data.attributes.pdf_file.pdf_file_url,
            termsConditions: responseJson.data.attributes.pdf_file.description,
          });
          this.parseContent();
        }
      }
    }
    // Customizable Area End
  }
}
// Customizable Area End
